<template>
    <div style="position: relative;width: 100%;overflow: hidden;">
        <a-tooltip overlayClassName="ixunke-tooltip" :placement="textRight ? 'left' : 'right'" trigger="oncontextmenu"
            v-model="tipShow" :getPopupContainer="
                (triggerNode) => {
                    return triggerNode.parentNode || document.body
                }
            ">
            <template slot="title">
                <div class="ixunke-tooltip-live-action" @click="messageEvent('revoke')">撤回</div>
            </template>
            <div class="ixunke-message-product" :style="{ float: textRight ? 'right' : 'none', }">
                <template v-if="type == 'course'">
                    <!-- 课程 -->
                    <div class="ixunke-message-product-course-title">
                        {{ product.title }}
                    </div>
                    <img class="ixunke-message-product-course-img" :src="product.thumb" :alt="product.title">
                    <div class="ixunke-message-product-course-subtitle">
                        <ixunkeIcon type="ixunke-live-shouye" />&nbsp;讲师精选优质课程推荐
                    </div>
                </template>
                <template v-else-if="type == 'coupon'">
                    <!-- 优惠券 -->
                    <div class="ixunke-message-product-coupon">
                        <a-space>
                            <div class="ixunke-message-product-coupon-icon">
                                <ixunkeIcon type="ixunke-live-youhuiquan_coupon" />
                            </div>
                            <span style="color: #fff;font-size: 14px;">
                                {{ currency }}
                                <span style="font-size: 18px;font-weight: 600;">{{ product.amount }}</span>
                                优惠券
                            </span>
                        </a-space>
                    </div>
                    <div class="ixunke-message-product-course-subtitle">
                        <ixunkeIcon type="ixunke-live-shouye" />&nbsp;{{ product.note }}
                    </div>
                </template>
            </div>
        </a-tooltip>
    </div>
</template>
  
<script>
import moment from 'moment'
export default {
    name: 'messageProduct',
    props: ['payload', 'textRight'],
    data() {
        return {
            //价格单位
            // currency: this.$store.getters.payData.currency,
            currency:"￥", 
            tipShow: false,
        }
    },
    computed: {
        product() {
            return this.payload.product;
        },
        type() {
            return this.payload.product.productType || 'course';
        },
    },
    filters: {
        formatPrice(price, float) {
            price = price || 0;
            if (float) {
                return price * 100 % 100 || '00';
            } else {
                return Math.floor(price);
            }
        }
    },
    mounted() { },
    methods: {
        moment,
        messageEvent(action) {
            this.$emit('messageAction', action)
            this.tipShow = false
        },
    },
}
</script>
<style lang="css" scoped>
.ixunke-message-product {
    position: relative;
    width: 220px;
    height: auto;
    padding: 8px;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 8px;
}

.ixunke-message-product-course-title {
    display: inline-block;
    width: auto;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #9e9e9e;
}

.ixunke-message-product-course-img {
    display: inline-block;
    width: 100%;
    height: 135px;
    object-fit: contain;
}

.ixunke-message-product-course-subtitle {
    font-size: 12px;
    color: #9e9e9e;
}

.ixunke-message-product-coupon {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #f9643c;
    padding: 8px;
}

.ixunke-message-product-coupon-icon {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: #fff;
    text-align: center;
    line-height: 44px;
    font-size: 28px;
    color: #f9643c;
}
</style>
  
  