<template>
  <div v-if="products.length" class="product-list card">
    <div
      class="product"
      :class="'style-' + listThumbStyle"
      v-for="(item, index) in products"
      :key="index"
      @tap="selectCourse(item)"
    >
     
      <div class="product-contont-right">
        <div class="product-title">{{ item.title }}</div>
        <div class="product-desc">{{ item.description || item.title }}</div>
        <div class="product-price">
          <!-- <div class="true-price" v-html="formatThePrice(item.price)"></div> -->
          <div class="true-price">{{item.price||'免费'}} </div>
          <span class="product-member" v-if="item.showMember && item.sumMember">
            <span class="iconfont xk-group"> <svg t="1686900153418" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3520" width="200" height="200"><path d="M507.2 537.28c-108.544 0-196.544-89.6-196.544-200.064 0-110.528 88-200.064 196.544-200.064 108.544 0 196.544 89.6 196.544 200.064-0.064 110.528-88 200.064-196.544 200.064m134.4 36.608A274.176 274.176 0 0 0 775.68 337.28C775.68 186.368 655.424 64 507.2 64S238.656 186.368 238.656 337.28c0 102.72 55.68 192.192 138.048 238.848-135.424 54.784-231.232 189.44-231.232 346.816 0 20.48 16.32 37.12 36.48 37.12 20.096 0 36.48-16.64 36.48-37.12 0.192-164.992 131.648-298.688 293.888-298.688 160.32 0 290.688 130.752 293.76 293.184a36.8 36.8 0 0 0 36.096 42.624c20.096 0 36.48-16.64 36.48-37.12-0.128-159.616-98.624-295.808-237.056-349.056" p-id="3521" fill="#cdcdcd"></path></svg></span>
            <span>{{ item.sumMember }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default({
    props:['products'],
    data(){
        return{
            listThumbStyle:3
        }
       
    },

})
</script>
<style lang="scss" scoped>
.page-content{
	padding-top: 44px;
}
.page-content.notop{
	padding-top: 0px;
}
.product-filter{
	position: relative;
	background-color: #fff;
	height: 44px;
	color: #555;
	box-shadow: 0 1px 4px rgba(0,0,0,.06);
}
.product-filter-flex{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 44px;
	display: flex;
}
.product-filter-item{
	position: relative;
	flex: 1;
	text-align: center;
	font-size: 14px;
	color: #666;
	width: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.product-filter-item .tag{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: absolute;
	left: 50%;
	top: 8px;
	transform: translateX(-50%);
	display: block;
	width: 80%;
	max-width: 100px;
	padding: 0 6px;
	background-color: #f7f7f7;
	height: 30px;
	line-height: 30px;
	border-radius: 20px;
	font-size: 13px;
	color: #333;
	box-sizing: border-box;
	transition: all .3s;
}
.product-filter-item .tag.open{
	height: 40px;
	border-radius: 10px 10px 0 0;
}
.product-filter-item text{
	vertical-align: middle;
}
.product-filter-border{
	display: none;
	position: absolute;
	bottom: 6px;
	left: 50%;
	transform: translateX(-50%);
	width: 20px;
	height: 2px;
	background-color: #555;
	border-radius: 4px;
}
.iconfont svg{
    width:12px;
    height: 12px;
    margin-right: 4px;
	background: linear-gradient(180deg, #999, #999);
	-webkit-background-clip: text;
	color: transparent;
}
.product-filter-item .iconfont.desc{
	background: linear-gradient(180deg, #ccc, #ccc, #ccc, #000, #000, #000);
	-webkit-background-clip: text;
	color: transparent;
}
.product-filter-item .iconfont.asc{
	background: linear-gradient(180deg, #000, #000, #000, #ccc, #ccc, #ccc);
	-webkit-background-clip: text;
	color: transparent;
}
.product-filter-item.active{
	color: #222;
	/* font-weight: bold; */
}
.product-filter-item.active .product-filter-border{
	display: block;
}
.product-filter-type{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.product-filter-type .iconfont{
	font-size: 16px;
}
.product-filter-tags{
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	height: 1000px;
	background-color: rgba(0,0,0,.6);
	overflow: hidden;
}
.product-filter-tags-panel{
	animation: filterTags .3s;
}
.product-filter-tags-button{
	background-color: #fff;
	height: 46px;
	line-height: 46px;
	text-align: center;
	color: #333;
	font-size: 16px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
.product-filter-tags-content{
	max-height: 220px;
	background-color: #f7f7f7;
}
@keyframes filterTags {
	from {
		transform: translateY(-100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}
.product-filter-tags-list{
	padding: 10px 0;
	font-size: 0;
	line-height: 1;
}
.product-filter-tags-item{
	display: inline-block;
	vertical-align: top;
	width: 50%;
	padding: 10px 20px;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	color: #333;
	line-height: 1.7;
}

.product-list{
	padding: 20upx 32upx;
}

.product{
	position: relative;
	margin-bottom: 20upx;
	background-color: #fff;
	padding: 30upx;
	border-radius: 10upx;
	overflow: hidden;
	vertical-align: top;
	transition: all .3s;
}
.product-hidden{
	transform: scale(0);
}
.product-star{
	position: absolute;
	right: 5px;
	top: 10px;
}
.product-star .iconfont{
	font-size: 17px;
	color: #fea700;
}
.product-content-left{
	position: relative;
	width: 266upx;
	height: 150upx;
	overflow: hidden;
	border-radius: 10upx;
	margin-right: 30upx;
	background-color: #eee;
	background-image: url('https://cdn.ixunke.cn/v3/app/imgcdn/error.png');
	background-repeat: no-repeat;
	background-position: center;
}
.style-2 .product-content-left{
	width: 150upx;
}
image{
	width: 100%;
	height: 100%;
}
.product-tag{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 5px;
	pointer-events: none;
}
.product-tag-item{
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
	margin-bottom: 5px;
	padding: 2px 5px;
	border-radius: 3px;
	font-size: 10px;
	color: #fff;
	background: #e54d42;
	font-weight: bold;
}
.product-tag-item.tuan{
	color: #7D4E1B;
	background: linear-gradient(90deg, #ffde9d 0%, #feb94a 100%);
}
.product-tag-item.discount{
	color: #fff;
	background: linear-gradient(90deg, #e54d42 0%, #fe904a 100%);
}
.product-member{
	color: #b2b2b2;
	font-size: 20upx;
}
.product-member .iconfont{
	font-size: 24upx;
}
.true-price ~ .product-member,
.market-price ~ .product-member{
	margin-left: 5px;
}
.card .product-title{
	font-size: 32upx;
	line-height: 1.5;
	overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
}
.list .product-title{
	font-size: 30upx;
	line-height: 1.5;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.product-desc{
	margin-top: 10upx;
	color: #b2b2b2;
	font-size: 24upx;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.product-price{
	margin-top: 10upx;
	font-size: 34upx;
	color: #F4520F;
}
.product-price .free {
	color: #35B558;
}
.product-price .true-price{
	display: inline-block;
}
.product-price .market-price{
	display: inline-block;
	margin-left: 5px;
	color: #b2b2b2;
	font-size: 10px;
	text-decoration: line-through;
}

.product-list.card{
	padding: 30upx 32upx;
	font-size: 0;
}
.product-list.card .product{
	padding: 0;
	display: inline-block;
	width: calc(50% - 5px);
}
.product-list.card .product:nth-child(2n+1){
	margin-right: 10px;
}
.product-list.card .product-content-left{
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	border-radius: 0;
}
.product-list.card .style-2 .product-content-left{
	padding-bottom: 100%;
}
.product-list.card .product-content-left image{
	position: absolute;
}
.product-list.card .product-contont-right{
	padding: calc(56.25% + 20upx) 20upx 20upx;
}
.product-list.card .style-2 .product-contont-right{
	padding: calc(100% + 20upx) 20upx 20upx;
}
.product-list.card .style-3 .product-contont-right{
	padding: 20upx 20upx 20upx;
}


	.product-filter-item{
		font-size: 15px;
	}
	.product-filter-border{
		width: 20px;
	}
	.product-filter-type{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.product-filter-type .iconfont{
		font-size: 16px;
	}
	
	
	.product-list{
		padding: 10px 16px;
	}
	.product{
		margin-bottom: 10px;
		padding: 15px;
		border-radius: 5px;
	}
	.product-content-left{
		width: 133px;
		height: 75px;
		border-radius: 5px;
		margin-right: 15px;
	}
	.style-2 .product-content-left{
		width: 75px;
		height: 75px;
	}
	.product-member{
		font-size: 10px;
	}
	.product-member .iconfont{
		font-size: 12px;
	}
	.card .product-title{
		font-size: 16px;
	}
	.list .product-title{
		font-size: 15px;
	}
	.product-desc{
		margin-top: 5px;
		font-size: 12px;
	}
	.product-price{
		margin-top: 5px;
		font-size: 17px;
	}
	.product-list.card{
		padding: 15px 16px;
	}
	.product-list.card .product{
		width: calc(25% - 8px);
		margin-right: 10px;
	}
	.product-list.card .product:nth-child(4n){
		margin-right: 0;
	}
	.product-list.card .product-contont-right{
		padding: calc(56.25% + 10px) 10px 10px;
	}
	.product-list.card .style-2 .product-contont-right{
		padding: calc(100% + 10px) 10px 10px;
	}
	.product-list.card .style-3 .product-contont-right{
		padding: 10px 10px 10px;
        height: 94px;
	}



</style>