<template>
    <div class="message-image" :style="{ textAlign: textRight ? 'right' : '' }">
        <a-tooltip overlayClassName="ixunke-tooltip" :placement="textRight ? 'left' : 'right'" trigger="oncontextmenu"
            v-model="tipShow" :getPopupContainer="
                (triggerNode) => {
                    return triggerNode.parentNode || document.body
                }
            ">
            <template slot="title">
                <div @click="messageEvent('copy')">复制</div>
                <div @click="messageEvent('revoke')">撤回</div>
            </template>
            <img style="width: 96px;height: 74px;" src="https://cdn.ixunke.cn/v3/app/imgcdn/live/fudai.png" />
        </a-tooltip>
    </div>
</template>
  
<script>
import messagePreview from './message-preview.vue'

export default {
    props: ['payload', 'textRight'],
    components: {
        messagePreview,
    },
    data() {
        return {
            tipShow: false,
        }
    },
    methods: {
        messageEvent(action) {
            this.$emit('messageAction', action)
            this.tipShow = false
        },
    },
}
</script>
  
<style lang="css" scoped>
.message-image {
    position: relative;
    /* margin-top: 12px; */
}

img {
    display: inline-block;
    border: 1px solid #eee;
    background-color: #fff;
    border-radius: 4px;
    margin: 6px 20px;
}
</style>
  