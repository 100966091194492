<template>
    <div class="nav_box">
        <div class="tianchong" :id="textColor?'FFF':''">
            <img class="logo" src="../img/logo.png" alt="">
            迅课直播助手
        </div>
        <div class="title">
            <!-- {{ title }} -->
        </div>
  
        <div class="menu" >
            
            <img class="min" @click="MinWin()" alt="最小化"  src="../img/nav/min.svg">
            <img class="max" @click="Maxwindow()" src="../img/nav/max.svg" alt=""
            v-if="!maxed">
            <img class="unmax" @click="unmaximize()" src="../img/nav/unmax.svg"  v-else alt="">
            <img class="close" @click="CloseWin()" alt="关闭"  src="../img/nav/close.svg">
        </div>
    </div>
</template>

<script>
    const { ipcRenderer } =window.require("electron");

    export default {
        props: [
        "title",
        "textColor"
        ],
        data() {
            return {
                maxed:false,
            }
        },
        methods:{
            MinWin(){
                ipcRenderer.send('MinWin')
            },
            CloseWin(){
                this.$emit('closeLive')
                ipcRenderer.send('CloseWin')
            },
            Maxwindow(){
                ipcRenderer.send("Maximize");
                this.maxed = true;
            },
            unmaximize(){
                ipcRenderer.send("unmaximize");
                this.maxed = false;
            }
        }
    }
</script>

<style lang='scss' scoped>
  .nav_box{
    display: flex;
    -webkit-app-region: drag;
    width: 100%;
    height: 4vh;
  }
  #FFF{
    color: #a9a9a9;
  }
  .tianchong{
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    .logo{
        width: 17px;
        margin-right: 6px;
    }
  }
  .title{
    font-size: 18px;
    font-weight: 500;
    width: 86%;
  }
  .menu{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    width: 10%;
    -webkit-app-region: no-drag;
    .min,.close,.max,.unmax{
        margin-right: 12px;
    }
  
    img{
       width: 26px;
       height: 26px;
       padding: 6px;
    }
  }
</style>