<template>
    <a-tooltip overlayClassName="ixunke-tooltip" :placement="textRight ? 'left' : 'right'" trigger="oncontextmenu"
        v-model="tipShow" :getPopupContainer="
            (triggerNode) => {
                return triggerNode.parentNode || document.body
            }
        ">
        <template slot="title">
            <div class="ixunke-tooltip-live-action" @click="messageEvent('revoke')">撤回</div>
        </template>
        <div class="ixunke-message-questionnaire" :style="{ textAlign: textRight ? 'right' : '' }">
            <div class="ixunke-message-questionnaire-title">
                {{ questionnaireObj.title }}
            </div>
            <div class="ixunke-message-questionnaire-dc">
                感谢您能抽出几分钟时间来参加本次问卷调查，现在我们马上开始吧！
                <a-divider style="margin: 8px 0;" />
                <div class="ixunke-message-questionnaire-subtitle">
                    <ixunkeIcon type="icon-plugin-information-collection" />&nbsp;问卷
                </div>
            </div>
        </div>
    </a-tooltip>
</template>
  
<script>
export default {
    name: 'messageQuestionnaire',
    props: ['payload', 'type', 'textRight'],
    data() {
        return {
            tipShow: false,
        }
    },
    computed: {
        questionnaireObj() {
            // console.log(this.payload)
            if (Object.keys(this.payload).length) {
                if (this.type == "message") {
                    try {
                        this.payload.data.component = JSON.parse(this.payload.data.component)
                    } catch (e) {
                        //TODO handle the exception
                    }
                    return this.payload.data
                } else if (this.type == "modal") {
                    try {
                        this.payload.component = JSON.parse(this.payload.component)
                        this.payload.submit_data.content = JSON.parse(this.payload.submit_data.content)
                    } catch (e) {
                        //TODO handle the exception
                    }

                    if (this.payload.component && this.payload.component.length) {
                        this.payload.component.forEach((item, index) => {
                            if (Object.keys(this.payload.submit_data).length) {
                                if (this.payload.submit_data.content.length) {
                                    this.payload.submit_data.content.forEach((item1, index1) => {
                                        if (item.id == item1.id) {
                                            item.content = item1.content
                                        }
                                    })
                                } else {
                                    item.content = ""
                                }
                            } else {
                                item.content = ""
                            }
                        })
                    }
                    return this.payload
                } else if (this.type == "list") {
                    return this.payload
                }
            } else {
                return {}
            }
        },
    },
    mounted() { },
    methods: {
        messageEvent(action) {
            this.$emit('messageAction', action)
            this.tipShow = false
        },
    },
}
</script>
<style lang="css" scoped>
.ixunke-message-questionnaire {
    position: relative;
    width: 220px;
    height: auto;
    padding: 0;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 8px;
    overflow: hidden;
}

.ixunke-message-questionnaire-title {
    width: auto;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: #fff;
    background-color: #1890ff;
    padding: 0 8px;
}

.ixunke-message-questionnaire-dc {
    font-size: 12px;
    color: #9e9e9e;
    padding: 0 8px 8px;
    line-height: 20px;
}

.ixunke-message-questionnaire-subtitle {
    font-size: 12px;
    color: #9e9e9e;
}
</style>
  
  