var imDefaultHost = {
	host1: "https://im.ixunke.cn",
	host2: "https://im-send.ixunke.cn"
};

var imIsInUNI = true;
try {
	uni
} catch (e) {
	//TODO handle the exception
	imIsInUNI = false;
}

function clog(data, logLevel=1){
	if(logLevel > MYIM.logLevel) return;
	try{
		if (imIsInUNI && process.env.NODE_ENV != 'development') {
			console.log('【xkim】', data);
		}else{
			let str = `%cxkim%c ${data}`;
			let s1 = 'background:#0abf5b; padding:2px 6px; border-radius:3px; color: #fff;'
			console.log(str, s1, '');
		}
	}catch(e){
		//TODO handle the exception
		console.log('【xkim】', data);
	}
	
}

var MYIM = {
	"lastMsgId": '', //上一条消息的id
	"lastMsgList": [], //上一批消息的id
	"lastCouldFid": '', //上一个云id
	"cloudHost": "https://ixunke-im.cdn.ixunke.cn/im/message", //云消息域名前缀
	"retry": {}, //重试次数
	"HOST": imDefaultHost.host1,
	"TOKEN": "",
	"logLevel": 1,
	"TYPES": {
		"MSG_TEXT": "TIMTextElem",
		"MSG_IMAGE": "TIMImageElem",
		"MSG_SOUND": "TIMSoundElem",
		"MSG_AUDIO": "TIMSoundElem",
		"MSG_FACE": "TIMFaceElem",
		"MSG_GEO": "TIMLocationElem",
		"MSG_GRP_TIP": "TIMGroupTipElem",
		"MSG_GRP_SYS_NOTICE": "TIMGroupSystemNoticeElem",
		"MSG_CUSTOM": "TIMCustomElem",
		"MSG_PRIORITY_HIGH": "High",
		"MSG_PRIORITY_NORMAL": "Normal",
		"MSG_PRIORITY_LOW": "Low",
		"MSG_PRIORITY_LOWEST": "Lowest",
		"CONV_C2C": "C2C",
		"CONV_GROUP": "GROUP",
		"CONV_SYSTEM": "@TIM#SYSTEM",
		"GRP_PRIVATE": "Private",
		"GRP_PUBLIC": "Public",
		"GRP_CHATROOM": "ChatRoom",
		"GRP_AVCHATROOM": "AVChatRoom",
		"GRP_MBR_ROLE_OWNER": "Owner",
		"GRP_MBR_ROLE_ADMIN": "Admin",
		"GRP_MBR_ROLE_MEMBER": "Member",
		"GRP_TIP_MBR_JOIN": 1,
		"GRP_TIP_MBR_QUIT": 2,
		"GRP_TIP_MBR_KICKED_OUT": 3,
		"GRP_TIP_MBR_SET_ADMIN": 4,
		"GRP_TIP_MBR_CANCELED_ADMIN": 5,
		"MSG_REMIND_ACPT_AND_NOTE": "AcceptAndNotify",
		"MSG_REMIND_ACPT_NOT_NOTE": "AcceptNotNotify",
		"MSG_REMIND_DISCARD": "Discard",
		"GENDER_UNKNOWN": "Gender_Type_Unknown",
		"GENDER_FEMALE": "Gender_Type_Female",
		"GENDER_MALE": "Gender_Type_Male",
		"KICKED_OUT_MULT_ACCOUNT": "multipleAccount",
		"KICKED_OUT_MULT_DEVICE": "multipleDevice",
		"KICKED_OUT_USERSIG_EXPIRED": "userSigExpired",
		"ALLOW_TYPE_ALLOW_ANY": "AllowType_Type_AllowAny",
		"ALLOW_TYPE_NEED_CONFIRM": "AllowType_Type_NeedConfirm",
		"ALLOW_TYPE_DENY_ANY": "AllowType_Type_DenyAny",
		"FORBID_TYPE_NONE": "AdminForbid_Type_None",
		"FORBID_TYPE_SEND_OUT": "AdminForbid_Type_SendOut",
		"JOIN_OPTIONS_FREE_ACCESS": "FreeAccess",
		"JOIN_OPTIONS_NEED_PERMISSION": "NeedPermission",
		"JOIN_OPTIONS_DISABLE_APPLY": "DisableApply",
		"JOIN_STATUS_SUCCESS": "JoinedSuccess",
		"JOIN_STATUS_ALREADY_IN_GROUP": "AlreadyInGroup",
		"JOIN_STATUS_WAIT_APPROVAL": "WaitAdminApproval",
		"NET_STATE_CONNECTED": "connected",
		"NET_STATE_CONNECTING": "connecting",
		"NET_STATE_DISCONNECTED": "disconnected"
	},
	"EVENT": {
		"SDK_READY": "sdkStateReady",
		"SDK_NOT_READY": "sdkStateNotReady",
		"SDK_DESTROY": "sdkDestroy",
		"MESSAGE_RECEIVED": "onMessageReceived",
		"MESSAGE_REVOKED": "onMessageRevoked",
		"CONVERSATION_LIST_UPDATED": "onConversationListUpdated",
		"GROUP_LIST_UPDATED": "onGroupListUpdated",
		"GROUP_SYSTEM_NOTICE_RECEIVED": "receiveGroupSystemNotice",
		"BLACKLIST_UPDATED": "blacklistUpdated",
		"KICKED_OUT": "kickedOut",
		"ERROR": "error",
		"NET_STATE_CHANGE": "netStateChange"
	},
	"request": (options = {})=> {
		function otherRequest(options) {
			const xhr = new XMLHttpRequest();
			// 如果是get请求，并且携带data，需要把data转化格式后追加在url后边
			xhr.open(options.method, options.url, true);
			if (options.header) {
				Object.keys(options.header).forEach((key) => {
					xhr.setRequestHeader(key, options.header[key]);
				});
			}
			xhr.onload = function() {
				if (xhr.status >= 200 && xhr.status < 300) {
					if (options.success && typeof options.success === "function") {
						try {
							xhr.statusCode = xhr.status;
							xhr.data = JSON.parse(xhr.response);
						} catch (e) {
							//TODO handle the exception
						}
						options.success && options.success(xhr);
					}
				} else {
					if (options.fail && typeof options.fail === "function") {
						options.fail(new Error(xhr.statusText));
					}
				}
			};
			xhr.onerror = function() {
				if (options.fail && typeof options.fail === "function") {
					options.fail(new Error("Network Error"));
				}
			};
			if (options.method === "POST" && options.data) {
				const formData = new FormData();
				Object.keys(options.data).forEach((key) => {
					formData.append(key, options.data[key]);
				});
				xhr.send(formData);
			} else {
				xhr.send();
			}
		}

		if (imIsInUNI) {
			// 如果是在uni-app环境中 使用uni-app提供的api来请求
			uni.request(options);
		} else {
			otherRequest(options);
		}
	},
	"create": ({ SDKAppID, chatroomId, heartTime=1206, apiHOST }) => {
		clog(`当前版本：${MYIM.VERSION}`);
		
		MYIM.destroy();
		MYIM.heartTime = heartTime;

		function UUID(length) {
			let result = '';
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			const charactersLength = characters.length;
			for (let i = 0; i < length; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			return result;
		}

		// 接收云消息
		function sreceiveMsg() {
			MYIM.request({
				method: 'POST',
				url: MYIM.HOST + '/message/heart_beat',
				data: {
					authorization: MYIM.TOKEN
				},
				success: (res) => {
					if (res.data.errno == 0) {
						if(MYIM.heartTime != 5024){
							// 5024代表默认im域名全部失效 用的是项目域名+/public/im，不再改变
							let refreshInterval = res.data.data.manifest.refreshInterval || heartTime;
							if(refreshInterval != MYIM.heartTime){
								MYIM.heartTime = refreshInterval;
								if (MYIM.heartApiTimer) {
									clearInterval(MYIM.heartApiTimer)
									MYIM.heartApiTimer = null;
								}
								MYIM.heartApiTimer = setInterval(sreceiveMsg, MYIM.heartTime);
							}
						}
						let currentCouldUrl = res.data.data.manifest.currentMessageId;
						let lastCouldUrl = res.data.data.manifest.lastMessageId;
						
						if (!currentCouldUrl) return;
						let currentCouldId = currentCouldUrl.split('?')[0];
						
						if (!MYIM.lastCouldFid) {
							// 进入页面后第一次的心跳包，消息不回传到页面，避免和历史消息重复
							// 记录lastCouldFid，用于对比下次心跳包的cloudId
							MYIM.lastCouldFid = currentCouldId;
							// 记录MYIM.lastMsgId，记录最近一条消息的id，用于getMsg()
							MYIM.request({
								method: 'GET',
								url: `${MYIM.cloudHost}/${encodeURIComponent(chatroomId)}/${currentCouldUrl}.json`,
								success: (res) => {
									if (res.data && Array.isArray(res.data) && res.data.length) {
										MYIM.lastMsgId = res.data[res.data.length - 1].messageid;
									}
								}
							})
						}
						// 对比下次心跳包的cloudId是否一致
						if (currentCouldId !== MYIM.lastCouldFid) {
							// 这里要额外判断上一条
							if (lastCouldUrl) {
								let lastCouldId = lastCouldUrl.split('?')[0];
								if(lastCouldId !== MYIM.lastCouldFid){
									// 不一致，说明至少漏掉了一条，请求lastCouldUrl
									clog(`漏掉了：${lastCouldId}`, 2);
									getMsgByCloud(lastCouldUrl)
									
									// 判断是否漏掉更多
									let index1 = MYIM.lastCouldFid.split('_')[0] - 0;
									let index2 = lastCouldId.split('_')[0] - 0;
									index1 = Math.max(index1, (index2 - 10)); // index1最小不能比index2小10，也就是最多请求最近的10个文件，太早的没必要
									if (index2 - index1 > 1) {
										clog(`同时漏掉了前面的${index2 - index1 - 1}个文件`, 2);
										for (let i = index1 + 1; i < index2; i++) {
											MYIM.request({
												method: 'POST',
												url: MYIM.HOST + '/message/miss',
												data: {
													chatroomId: chatroomId,
													missId: i,
													authorization: MYIM.TOKEN
												},
												success: (res) => {
													if (res.data.errno == 0) {
														getMsgByCloud(res.data.data.chat_index);
													}
												}
											})
										}
									}
								}
							}
							// 不一致，请求currentCouldUrl
							getMsgByCloud(currentCouldUrl)
							// 更新lastCouldFid
							MYIM.lastCouldFid = currentCouldId;
						}
					} else if (res.data.errno == 10010) {
						clog('xkim系统token过期，正在重新登录', 1);
						fn.login({
							userID: MYIM.userId,
							userSig: MYIM.userSig
						})
					}
				}
			})
		}

		// 查询云消息
		function getMsgByCloud(cloudUrl) {
			if (MYIM.retry[cloudUrl] && MYIM.retry[cloudUrl] >= 2) {
				return;
			}
			MYIM.request({
				method: 'GET',
				url: `${MYIM.cloudHost}/${encodeURIComponent(chatroomId)}/${cloudUrl}.json`,
				success: (res) => {
					if (res.statusCode == 200) {
						if (res.data && Array.isArray(res.data) && res.data.length) {
							pushMsg(res.data)
						}
					} else if (res.statusCode == 404 || res.statusCode == 500) {
						setTimeout(() => {
							getMsgByCloud(cloudUrl);
							MYIM.retry[cloudUrl] = (MYIM.retry[cloudUrl] || 0) + 1;
						}, 1000)
					}
				}
			})
		}

		// 查询数据库消息
		function getMsg() {
			MYIM.request({
				method: 'POST',
				url: MYIM.HOST + '/message/history',
				data: {
					lastMessageId: MYIM.lastMsgId
				},
				header: {
					'Authorization': MYIM.TOKEN
				},
				success: (res) => {
					if (res.data && Array.isArray(res.data) && res.data.length) {
						pushMsg(res.data)
					}
				}
			})
		}

		// 触发onReceiveMessage
		function pushMsg(data) {
			let list = [];
			data.forEach((item) => {
				// 去重
				let nowTime = Date.now() / 1000;
				if (!MYIM.lastMsgList.includes(item.messageid)) {
					list.push({
						...item,
						to: chatroomId,
						ID: item.messageid,
						seq: item.messageid,
						from: item.userid,
						status: 'success',
						payload: {
							data: item.message
						},
						isQuestion: item.isquestion,
						pMsgId: item.pmsgid || '',
						nick: item.nickname
					})
				}
			})
			if (list.length) {
				// 触发onReceiveMessage
				MYIM['on' + MYIM.EVENT.MESSAGE_RECEIVED]({
					data: list
				});
				// 记录最近一条消息的id，用于getMsg()
				MYIM.lastMsgId = list[list.length - 1].ID;
				// 记录最近一批次消息的id数组，用于去重
				let lastMsgList = list.map(item => {
					return item.ID
				});
				lastMsgList = lastMsgList.concat(MYIM.lastMsgList);
				if (lastMsgList.length > 500) {
					lastMsgList = lastMsgList.slice(0, 100);
				}
				MYIM.lastMsgList = lastMsgList;
			}
		}

		var fn = {
			setLogLevel: (lev=1) => {
				MYIM.logLevel = lev;
				clog(`设置日志等级为：${lev}`);
			},
			registerPlugin: () => {},
			on: (eventName, myFunction) => {
				MYIM['on' + eventName] = myFunction;
			},
			off: () => {},
			login: ({
				userSig,
				userID
			}) => {
				MYIM.userSig = userSig;
				MYIM.userID = userID;
				
				if (imIsInUNI) {
					clog('运行在uni-app环境中', 2);
				} else {
					clog('运行在非uni-app环境中', 2);
				}
				
				return new Promise((resolve, reject) => {
					MYIM.request({
						method: 'POST',
						url: MYIM.HOST + '/user/get_token_by_sign',
						data: {
							appId: SDKAppID,
							sign: userSig
						},
						success: (res) => {
							if (!res.data || !res.data.data) {
								clog('登录im失败：response Error', 1);
								fn.loginAfterChangeHost({
									userSig,
									userID
								}, resolve)
								return;
							}
							if (res.data.errno == 0) {
								clog('登录im成功');
								MYIM.TOKEN = res.data.data.token;
								sreceiveMsg();
								if (MYIM.heartApiTimer) {
									clearInterval(MYIM.heartApiTimer)
									MYIM.heartApiTimer = null;
								}
								MYIM.heartApiTimer = setInterval(sreceiveMsg, MYIM.heartTime);
								MYIM['on' + MYIM.EVENT.SDK_READY]({
									name: MYIM.EVENT.SDK_READY
								});
								resolve()
							} else {
								// reject(res.data.errmsg)
							}
						},
						fail: (err) => {
							clog('登录im失败：request Error', 1);
							// reject('token接口fail')
							fn.loginAfterChangeHost({
								userSig,
								userID
							}, resolve)
						}
					})
				})
			},
			loginAfterChangeHost(loginOptions, resolve) {
				if (MYIM.HOST == imDefaultHost.host1) {
					clog('切换至备用域名，并尝试重新登录im', 2);
					MYIM.HOST = imDefaultHost.host2;
					fn.login(loginOptions).then(() => {
						resolve()
					})
				} else if ((MYIM.HOST == imDefaultHost.host2) && apiHOST) {
					clog('切换至租户域名，并尝试重新登录im', 2);
					MYIM.HOST = apiHOST + '/public/im';
					MYIM.heartTime = 5024;
					fn.login(loginOptions).then(() => {
						resolve()
					})
				}
			},
			logout: () => {
				return new Promise((resolve, reject) => {
					clog('退出im成功');
					MYIM.destroy();
					resolve()
				})
			},
			joinGroup: () => {
				return new Promise((resolve, reject) => {
					clog('加入聊天室成功');
					resolve({
						data: {
							status: MYIM.TYPES.JOIN_STATUS_SUCCESS
						}
					})
				})
			},
			quitGroup: () => {
				return new Promise((resolve, reject) => {
					clog('退出聊天室成功');
					resolve()
				})
			},
			updateMyProfile: () => {
				return new Promise((resolve, reject) => {
					resolve()
				})
			},
			createCustomMessage: (message) => {
				let nowTime = Date.now();
				let msgId = UUID(6) + nowTime;
				return {
					...message,
					type: MYIM.TYPES.MSG_CUSTOM,
					ID: msgId,
					seq: nowTime,
					from: MYIM.userID,
					status: 'success'
				};
			},
			createImageMessage: (message) => {
				/* 
					file参数：
					width: 图片宽度,
					height: 图片高度,
					imageUrl: 完整图片地址
				 */
				message.payload = {
					data: JSON.stringify({
						imageInfoArray: [message.payload.file]
					})
				}
				let nowTime = Date.now();
				let msgId = UUID(6) + nowTime;
				return {
					...message,
					type: MYIM.TYPES.MSG_IMAGE,
					ID: msgId,
					seq: nowTime,
					from: MYIM.userID,
					status: 'success'
				};
			},
			sendMessage: (message) => {
				let submitdata = {
					message: message.payload.data,
					priority: message.priority || '',
					type: message.type,
					isquestion: message.isQuestion,
					pmsgid: message.pMsgId || ''
				}
				return new Promise((resolve, reject) => {
					MYIM.request({
						method: 'POST',
						url: MYIM.HOST + '/message/send',
						data: submitdata,
						header: {
							'Authorization': MYIM.TOKEN
						},
						success: (res) => {
							if (res.data.errno == 0) {
								clog('发送消息成功', 0);
								// console.log(submitdata.message);
								if(MYIM.lastMsgId){
									getMsg();
								}
								resolve()
							} else if (res.data.errno == 10010) {
								clog('发送消息失败：token过期，尝试重新登录并再次发送', 2);
								// 登录态失效
								fn.login({
									userID: MYIM.userId,
									userSig: MYIM.userSig
								}).then(() => {
									fn.sendMessage(message)
								})
							} else {
								if (res.data.errno == 10011 && res.data.errmsg == '禁言状态') {
									// 被禁言 errno==10017 (抹平和tx的差异)
									clog('发送消息失败：code:10017，你已被禁言', 1);
									reject({
										code: 10017,
										message: '你已被禁言'
									})
								} else {
									clog(`发送消息失败：code:${res.data.errno}，${res.data.errmsg}`, 1);
									// 其他错误，暂时屏蔽
									// reject({
									// 	code: res.data.errno,
									// 	message: res.data.errmsg
									// })
								}
							}
						},
						fail: (err) => {
							clog('发送消息失败：request Error', 2);
							// reject({
							// 	code: '',
							// 	message: 'send接口fail'
							// })
						}
					})
				})
			},
		}

		return fn;
	},
	"destroy": () => {
		if (MYIM.heartApiTimer) {
			clearInterval(MYIM.heartApiTimer)
			MYIM.heartApiTimer = null;
		}
		MYIM.lastMsgId = ''; //上一条消息的id
		MYIM.lastMsgList = []; //上一批消息的id
		MYIM.lastCouldFid = ''; //上一个云id
		MYIM.retry = {}; //重试次数
		MYIM.TOKEN = ''; //清空token
	},
	"VERSION": "3.24.914"
}

export default MYIM;