<template>
  <div class="ibox">
    <p class="title">个人信息</p>
    <ul>
      <li>头像：<img :src="avatarUrl" alt="" /></li>
      <li>
        姓名：
        <div>{{ userInfo.nickname }}</div>
      </li>
      <li>
        手机号：
        <div>{{ userInfo.phone }}</div>
      </li>
      <li>
        ip：
        <div>{{ userInfo.ip }}</div>
      </li>
    </ul>
    <el-button  type="danger" @click="Logout">退出登录</el-button>
  </div>
</template>
<script>
const Store = window.require("electron-store");
const store = new Store();
export default {
  data() {
    return {
      avatarUrl: "",
      userInfo: null,
    };
  },

  created() {
    this.host = store.get("host");
    try {
      const avatar = store.get("userInfo").avatar;
    this.avatarUrl = this.host + avatar;
    this.userInfo = store.get("userInfo");

    } catch (error) {
      
    }
    
  },
  methods:{
    Logout(){
        console.log(1);
        store.delete('token')
        store.delete('userInfo')
        this.$router.replace({
            name:'Login'
        })
    }
  }
};
</script>
<style lang="scss"  scoped>
.ibox {
  color: #000;
  font-size: 14px;
  .title{
    font-size: 16px;
    margin-bottom: 14px;
  }
  li {
    height: 60px;
    display: flex;
    align-items: center;
    width: 210px;
    justify-content: space-between;
  }
}
</style>