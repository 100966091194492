<template>
  <div
    class="ixunke-chat-input-box"
    id="ixunkeChatInputBox"
    ref="ixunkeChatInputBox"
    :class="liveDetail?.isVertical==1?'verticaBottom':''"
  >
    <div class="ixunke-chat-input-menu" v-if="!liveDetail?.isVertical">
      <a-modal
        class="ixunke-component-middle-modal emoji-list"
        dialogClass="emoji-list"
        :dialogStyle="dialogStyle"
        v-model="emojiModal"
        :mask="false"
        title="表情"
        width="600px"
        :footer="null"
        :destroyOnClose="true"
      >
        <span
          class="emoji-item"
         
          v-for="item in emojiName"
          :key="item"
          @click="insertEmoji(item)"
        >
          <img
            class="emoji-item-img"
            :src="emojiUrl + emojiMap[item]"
            mode="aspectFill"
          />
        </span>
      </a-modal>
      
      
      <a-tooltip title="刷新">
        <img
          src="./img/ixunke-chat-shuaxin.png"
          alt=""
          class="ixunke-chat-input-menu-icon"
          @click="reshMessageEvent"
        />
      </a-tooltip>
      <a-tooltip title="表情">
        <img
          src="./img/weixiao.png"
          alt=""
          class="ixunke-chat-input-menu-icon"
          @click="showPicModalEvent"
        />
      </a-tooltip>
      <a-tooltip title="商品">
        <img
          src="./img/sp.png"
          alt=""
          class="ixunke-chat-input-menu-icon"
          v-show="liveDetail?.is_open_take_goods == 1"
        />
    
      </a-tooltip>
      <a-tooltip title="课程">
        <img src="./img/kc.png" alt="" @click="showKechengModal" class="ixunke-chat-input-menu-icon">

      </a-tooltip>
      <a-tooltip title="优惠券">
        <img src="./img/yhq.png" alt="" @click="showCouponModalEvent" v-show="liveDetail?.is_open_coupon == 1" class="ixunke-chat-input-menu-icon">
      </a-tooltip>
      <a-tooltip title="福袋">
        <img
          src="./img/fd.png"
          alt=""
          @click="showFudaiModalEvent"
          v-show="liveDetail?.is_open_fuku == 1"
          class="ixunke-chat-input-menu-icon"
        />
      </a-tooltip>
      <a-tooltip title="投票">
        <img
          src="./img/toupiao.png"
          alt=""
          @click="showTouPiao"
          v-show="liveDetail?.is_open_vote == 1"
          class="ixunke-chat-input-menu-icon"
        />
      </a-tooltip>
      <a-tooltip title="问卷">
        <img
          src="./img/wenjuan.png"
          alt=""
          @click="showWenJuan"
          v-show="liveDetail?.is_open_que == 1"
          class="ixunke-chat-input-menu-icon"
        />
      </a-tooltip>
      <a-tooltip title="商品">
        <img
          src="./img/cart-2.png"
          alt=""
          @click="showcommodityModal"
          v-show="liveDetail?.goods?.length>0"
          class="ixunke-chat-input-menu-icon"
        />
      </a-tooltip>
    
      <!-- <div class="lianmai">
        <div v-if="!lianmai.switch" @click="openLianmai()">开启连麦</div>
        <div v-else @click="closeLianmai()">关闭连麦</div>
      </div> -->
      <!-- <ixunkeIcon class="ixunke-chat-input-menu-icon" type="icon-client-copon" />
      <ixunkeIcon class="ixunke-chat-input-menu-icon" type="icon-client-major" />
      <span class="ixunke-chat-input-menu-btn" style="margin: 0 5px;">全体禁言</span>
      <span class="ixunke-chat-input-menu-btn">
        禁言人员
        <span class="ixunke-banned-num">67</span>
      </span>-->
    </div>
    <div  v-else style="height: 22px;display: block;"></div>
    <div class="quote-box" v-if="quoteObj">
      <span>引用&nbsp;</span>
      <span class="message-quote-user">{{ quoteObj.quoter }}</span>
      <span>：{{ quoteObj.quote }}</span>
      &nbsp;
      <a-icon
        type="close-circle"
        theme="filled"
        style="color: #f5222d; cursor: pointer"
        @click="clearQuoteObjEvent"
      />
    </div>
    <!-- <textarea
      class="ixunke-chat-input"
      rows="7"
      placeholder="消息内容"
      v-model="message"
    ></textarea> -->
    <div contenteditable="true"  id="emoji" ref="inputBox"
    :class="liveDetail?.isVertical==1?'vertica':''"  
    class="ixunke-chat-input"></div>
    <div class="ixunke-chat-send-btn" @click="sendMessageEvent">发送</div>

    <!-- 优惠券modal -->
    <a-modal
      class="ixunke-component-middle-modal"
      v-model="couponModal"
      title="优惠券发放"
      width="1020px"
      :footer="null"
      :destroyOnClose="true"
    >
      <a-layout class="ixunke-edit-page">
        <a-layout style="padding: 0">
          <a-layout-content
            class="ixunke-edit-body-content"
            style="background-color: #fff"
          >
            <div class="ixunke-container ixunke-container-flex">
              <coupon-item
                v-for="(item, index) in couponModalList"
                @sendCouponEvent="sendCouponEvent"
                :key="index"
                :item="item"
                :fromShare="true"
              ></coupon-item>
              <a-pagination
                :current="current1"
                :pageSize="15"
                show-quick-jumper
                :total="MaxCount"
                @change="onChange"
              />
            </div>
            <!-- <div class="ixunke-component-resouce-modal-foot-box" style="z-index: 2; text-align: right;">
              <a-space size="large">
                <span v-if="couponModalList.length">已选优惠券{{ couponModalList.length }}张</span>
                <a-button @click="() => couponModal = false">
                  取消
                </a-button>
                <a-button type="primary" @click="item">
                  发送
                </a-button>
              </a-space>
            </div> -->
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-modal>
    <!-- 福袋modal -->
    <a-modal
      class="ixunke-component-middle-modal"
      v-model="fudaiModal"
      title="福袋发放"
      width="1020px"
      :footer="null"
      :destroyOnClose="true"
    >
      <a-layout class="ixunke-edit-page">
        <a-layout style="padding: 0">
          <a-layout-content
            class="ixunke-edit-body-content"
            style="background-color: #fff"
          >
            <div class="ixunke-container ixunke-container-flex">
              <a-table
                :loading="tableLoading"
                :scroll="tableScroll"
                :columns="fudaiModalTitle"
                :dataSource="fudaiModalList"
                :pagination="false"
              >
                <template slot="join_type" slot-scope="text, record">
                  <div v-if="record.join_type == 1">在线</div>
                  <div v-else-if="record.join_type == 2">任意评论</div>
                  <div v-else-if="record.join_type == 3">指定评论</div>
                  <div v-else-if="record.join_type == 4">分享直播间</div>
                </template>
                <template slot="status" slot-scope="text, record">
                  <a-badge
                    status="default"
                    v-if="record.status == 0"
                    text="失效"
                  />
                  <a-badge
                    status="warning"
                    v-else-if="record.status == 1"
                    text="未发布"
                  />
                  <a-badge
                    status="processing"
                    v-else-if="record.status == 2"
                    text="参与中"
                  />
                  <a-badge
                    status="success"
                    v-else-if="record.status == 3"
                    text="已开奖"
                  />
                </template>
                <template slot="action" slot-scope="text, record">
                  <a
                    class="ixunke-table-action-btn"
                    href="javascript:;"
                    @click="getDetailfuku(record.id)"
                    :disabled="record.status == 3 ? false : true"
                    >中奖名单</a
                  >
                  <a-divider type="vertical" />
                  <a
                    class="ixunke-table-action-btn"
                    href="javascript:;"
                    :disabled="record.status == 1 ? false : true"
                    @click="sendFudaiEvent(record)"
                    >发布</a
                  >
                </template>
              </a-table>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-modal>
    <!-- 问卷modal -->
    <a-modal
      class="ixunke-component-middle-modal"
      :bodyStyle="{ background: '#f4f4f4' }"
      v-model="wenjuanModal"
      title="问卷"
      width="1020px"
      :footer="null"
    >
      <div class="questionnaire-box list">
        <template v-if="questionnaireObj?.length">
          <div
            class="questionnaire-item"
            v-for="item of questionnaireObj"
            @click="sendQuestionnaire(item)"
            :key="item.id"
          >
            <span
              class="vote-state-background"
              v-if="item.end_time == 1 || item.end_time > Date.now()"
              >{{ "进行中" }}</span
            >
            <span class="vote-state-end" v-else>{{ "已结束" }}</span>
            <span class="questiontitle">{{ item.title }}</span>

            <p class="end_time">{{ endTimeFilter(item.end_time) }}</p>
          </div>
        </template>
      </div>
    </a-modal>
    <!-- 课程modal -->
    <a-modal
      class="ixunke-component-middle-modal"
      :bodyStyle="{ background: '#f4f4f4' }"
      v-model="kechengModal"
      title="课程列表"
      :destroyOnClose="true"
      width="1020px"
      :footer="null"
    >
      <produs :products="CourseList"></produs>
      <a-pagination
        :current="current2"
        :pageSize="20"
        show-quick-jumper
        :total="kechengMaxCount"
        @change="kechengonChange"
      />
    </a-modal>
    <!-- 商品modal -->
    <a-modal
      class="ixunke-component-middle-modal"
      v-model="commodityModal"
      :destroyOnClose="true"
      width="1020px"
      :footer="null"
    >
      <livechat :goods="liveDetail?.goods" :roomId="liveDetail.id"></livechat>
    </a-modal>
    <!-- 福袋中奖列表modal -->
    <a-modal
      class="ixunke-component-middle-modal"
      v-model="bingoModal"
      :destroyOnClose="true"
      width="1120px"
      title="中奖名单"
      :bodyStyle="{ background: '#f4f4f4' }"
      :footer="null"
    >
      <div class="bingobox">
        <ul>
          <li class="bingolist" v-for="item in bingoList" :key="item.id">
            <div class="userInfo">
              <img :src="item.userInfo.avatar" alt="" />
              <span>{{ item.userInfo.nickname }}</span>
            </div>
            <div class="prize_content">
              <span>{{ item.prize_content.name }}</span>
              <span class="ext"
                >奖励 {{ item.prize_content.ext
                }}{{ prizeContentText(item.prize_content.type) }}</span
              >
            </div>
          </li>
        </ul>
        <a-empty v-if="bingoList.length == 0" description="暂无中奖人员" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { emojiMap, emojiName, emojiUrl,emojiReverseMap } from "@/assets/js/emojiMap.js";
import { ixunkeAxios } from "@/assets/js/axios.js";
import { emojis } from "./config";
import couponItem from "@/assets/common/coupon-item.vue";
import produs from "@/assets/common/products.vue";
import livechat from "@/assets/common/live-commerce.vue";
const Store = window.require("electron-store");
const store = new Store();
export default {
  name: "messageText",
  props: ["liveDetail", "quoteObj","f"],
  components: {
    // couponList,
    couponItem,
    produs,
    livechat,
  },
  inject: ['lianmai'],
  data() {
    return {
      emojiMap,
      emojiName,
      emojiUrl,

      emoji: {
        // isVisible: false,
        // path: emojiPath,
        // list: emojiList,
        // suffix: emojiSuffix,
      },
      chatMessageList: [],
      rangeOfInputBox:'',
      current1: 1,
      current2: 2,
      MaxCount: 0,
      dialogStyle:{
        postion:'fixed',
        top:'140px',
       
      },
      emojiModal:false,
      picModalEvent: false,
      picModal: false,
      message: "",
      lastSelectEmoji: 0,
      //modal动画
      tableLoading: {
        tip: "加载中...",
        size: "large",
        spinning: false,
      },
      tableScroll: { x: false, y: false },
      //优惠券modal
      couponModal: false,
      couponModalList: [],
      //福袋modal
      fudaiModal: false,
      // 问卷modal
      wenjuanModal: false,
      // 课程modal
      kechengModal: false,
      // 商品modal
      commodityModal: false,
      kechengMaxCount: 0,
      current2: 1,
      // 问卷list
      questionnaireObj: [],
      fudaiModalTitle: [
        {
          title: "福袋名称",
          dataIndex: "fuku_name",
        },
        {
          title: "参与方式",
          dataIndex: "join_type",
          scopedSlots: { customRender: "join_type" },
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      fudaiModalList: [],
      // 福袋中奖名单
      bingoList: [],
      // 福袋显示
      bingoModal: false,
      //课程列表
      CourseList: [],
      messageType: "vote",
    };
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (this.picModalEvent) {
        this.picModal = false;
        setTimeout(() => {
          this.picModalEvent = false;
        }, 300);
      }
    });
    // document.onselectionchange = () => {
    //   let selection = window.getSelection();
    //   console.log(selection);
    //   if (selection.rangeCount > 0) {
    //     const range = selection.getRangeAt(0);
    //     try {
          
       
    //     if (document.getElementById('emoji').contains(range.commonAncestorContainer)) {
    //       this.rangeOfInputBox = range;
    //     }
    //   } catch (error) {
          
    //     }
    //   }
    // };
    setTimeout(() => {
      this.reshMessageEvent()
    }, 3000);
    // console.log(this.liveDetail,'-----liveDetail----');
    if(!this.liveDetail.goods){
      this.liveDetail.goods = []
    }
  },
  methods: {

    openLianmai(){
      this.lianmai.switch = true
    },
    closeLianmai(){
      console.log(1111);
      this.lianmai.switch = false
    },
    handleInput(e){
      console.log(e)
    },
    handleBoxClick(event) {
      let target = event.target;
      console.log(target);
      this.setCaretForEmoji(target);
    },
    setCaretForEmoji(target) {
      if (target.tagName.toLowerCase() === 'img') {
        console.log(target);
        let range = document.createRange();
        range.setStartBefore(target);
        range.collapse(true);
        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
    toggleEmojiBox() {
      this.emoji.isVisible = !this.emoji.isVisible;
    },
    insertEmoji(name) {
      this.message += name;
      let emojiEl = document.createElement('img');
      emojiEl.src = `${emojiUrl}${this.emojiMap[name]}`
      console.log(emojiEl.src);
      if (!this.rangeOfInputBox) {
        this.rangeOfInputBox = document.createRange();
        this.rangeOfInputBox.selectNodeContents(this.$refs.inputBox);
      }

      if (this.rangeOfInputBox.collapsed) {
        this.rangeOfInputBox.insertNode(emojiEl);
      } else {
        this.rangeOfInputBox.deleteContents();
        this.rangeOfInputBox.insertNode(emojiEl);
      }
      this.rangeOfInputBox.collapse(false);
    },
    doSend() {
      let content = this.$refs.inputBox.innerHTML;

        if (content !== '') {
          let name = '我';
          let messageItem = {
            id: count++,
            name,
            content,
          };
          this.chatMessageList.push(messageItem);
          this.$refs.inputBox.innerHTML = '';
        }
    },
  
    
    
    emojis,
    moment,
    getDetailfuku(id) {
      console.log(id);
      ixunkeAxios({ id: id }, "get", "/api/v1/room/detail_fuku").then((res) => {
        // console.log(res);
        if (res.errno == 0) {
          this.bingoList = res.data.bingoList;
        }
        this.bingoModal = true;
      });
    },
    chooseEmoji(item) {
      console.log(item);
      // console.log(item);
      // console.log(emojiUrl+emojiMap[item]);
      // console.log();
      // let emoji = emojis.find(item=>{
      //   return `[${item.text}]` == newVal;
      // })
      // console.log(emoji);
      this.message += item;
    },
    selectQuestionnaireItem(item) {
    },
    showcommodityModal() {
      // console.log(this.liveDetail);
      this.commodityModal = true;
    },
    showKechengModal(pageNumber) {
      console.log(pageNumber);
      try {
        pageNumber = Number(pageNumber)
      } catch (error) {
        pageNumber = 1
      }
      ixunkeAxios(
        { page: pageNumber || 1, pageSize: 20, status: 1, order: "-recommend" },
        "get",
        "/api/course"
      ).then((res) => {
        if (res.errno == 0) {
          this.CourseList = res.data;
          this.kechengModal = true;
          this.kechengMaxCount = res.count;
        }
      });
    },
    stateFilter(endTime) {
      if (endTime == 1) {
        return "进行中";
      } else if (endTime < new Date().getTime()) {
        return "已结束";
      } else {
        return "进行中";
      }
    },
    async kechengonChange(pageNumber) {
      console.log("Page: ", pageNumber);
      await this.showKechengModal(pageNumber);
      this.current2 = pageNumber;
    },

    async onChange(pageNumber) {
      console.log("Page: ", pageNumber);
      await this.showCouponModalEvent(pageNumber);
      this.current1 = pageNumber;
    },
    // 获取问卷信息
    showWenJuan() {
      ixunkeAxios(
        { room_id: this.liveDetail.id, type: 2 },
        "get",
        "/api/v1/room/get_form"
      ).then((res) => {
        // console.log(res);
        if (res.errno == 0) {
          this.messageType = "questionnaire";
          this.questionnaireObj = res.data;
          this.wenjuanModal = true;
        }
      });
    },
    // 获取投票信息
    showTouPiao() {
      ixunkeAxios(
        { room_id: this.liveDetail.id, type: 1 },
        "get",
        "/api/v1/room/get_form"
      ).then((res) => {
        // console.log(res);
        this.messageType = "vote";
        this.questionnaireObj = res.data;
        this.wenjuanModal = true;
      });
    },
    //消息刷新
    reshMessageEvent() {
      console.log('消息刷新2');
      this.bus.$emit("reshMessageListEvent", "resh");
    },
    //显示表情modal
    showPicModalEvent() {
      // this.picModal = true;
      this.emojiModal = true
      // setTimeout(() => {
      //   this.picModalEvent = true;
      // }, 300);
    },

    //问卷消息
    sendQuestionnaire(item) {
      let data = {
        type: this.messageType,
        data: item,
      };
      console.log(data);
      // return
      this.$emit("sendQuestionnaire", data);
      this.wenjuanModal = false;
    },
    //文本消息
    sendMessageEvent() {
      let content = this.$refs.inputBox.innerHTML;
      // console.log(content);
      let replaced = content.replace(/<img src="https:\/\/imgcache\.qq\.com\/open\/qcloud\/tim\/assets\/emoji\//g, '["');
      replaced = replaced.replace(/">/g, '"]');
      replaced = replaced.replace(/<div>/g, '');
      replaced = replaced.replace(/<\/div>/g, '');
      replaced = replaced.replace(/&nbsp;/g, '');
      const regex = /(\[?"?emoji_\d+@2x\.png"?\]|[^\[\]]+)/g;
      const matches = [];
      let match;
      while ((match = regex.exec(replaced))) {
        matches.push(match[0].replace(/[\["\]]/g, ''));
      }
      let message=''
      matches.forEach(e=>{
        if(emojiReverseMap[e]){
          message+=emojiReverseMap[e]
        }else{
          message+=e
        }
      })
      // console.log(message);
      this.message = message
      this.emojiModal = false
      if (this.message === "" || this.message.trim().length === 0) {
        this.message = "";
        this.bus.$emit("chatTipEvent", {
          type: "error",
          content: "不能发送空消息哦！",
        });
        return;
      }
      // let test = this.message.replace(/↵/g, "<br>");
      let test  = this.message.replace(/<br>/g, "\n");

      let data = {
        type: "text",
        text: test,
      };
      this.$emit("sendMessageEvent", data);
      this.message = "";
      this.$refs.inputBox.innerHTML = '';

    },
    //选择表情
    sendEmojiEvent(item) {
      let lastSelectEmoji = this.lastSelectEmoji || 0;
      let nowTime = Date.now();
      if (nowTime - lastSelectEmoji > 3000) {
        this.lastSelectEmoji = nowTime;
        let data = {
          type: "text",
          text: item,
        };
        this.$emit("sendMessageEvent", data);
        this.showPicModalEvent();
      } else {
        let tip = "操作太频繁了哦";
        if (Math.random() > 0.5) {
          tip = "认真听课，避免刷屏";
        }
        this.bus.$emit("chatTipEvent", {
          type: "error",
          content: tip,
        });
      }
    },
    //选择本地图片
    sendPicEvent() {
      let imagePicker = document.getElementById("imagePicker");
      if (!imagePicker) {
        imagePicker = document.createElement("input");
        imagePicker.setAttribute("type", "file");
        imagePicker.setAttribute("accept", ".jpg, .jpeg, .png, .gif");
        imagePicker.style.display = "none";
        imagePicker.id = "imagePicker";
        document.body.appendChild(imagePicker);
        imagePicker.addEventListener("change", () => {
          this.$emit("sendMessageEvent", {
            type: "image",
            image: imagePicker,
          });
          this.picModal = false;
        });
      }
      imagePicker.click();
    },
    //清空引用
    clearQuoteObjEvent() {
      this.$emit("clearQuoteObj");
    },
    //分发优惠券---显示优惠券modal
    showCouponModalEvent(page) {
      this.couponModal = true;
      // this.couponModalList = []
      console.log(page);
      try {
        page = Number(page)
      } catch (error) {
        page = 1
      }
      let params = {
        state: 1,
        noExpire: true,
        getTakeState: 1,
        class: 0,
        page: page || 1,
        pageSize: 15,
      };
      ixunkeAxios(params, "get", "/api/coupon/get_coupon_list").then((res) => {
        if (res.errno == 0) {
          this.couponModalList = res.data;
          console.log(res.count);
          this.MaxCount = Math.ceil(res.count);
          console.log(this.MaxCount);
          console.log(this.couponModalList);
          // this.courseLayout.forEach(item=>{
          // 	if(item.type == 'coupon-list'){
          // 		let data = list.filter(coupon=>{
          // 			return item.idArr.indexOf(coupon.id) > -1;
          // 		})
          // 		console.log(data);
          // 		item.data = data;
          // 	}
          // })
        }
      });
    },
    //分发优惠券---选择优惠券
    couponModalListEvent(params) {
      this.couponModalList = params;
    },
    //分发优惠券---优惠券分发
    sendCouponEvent(item) {
      if (!this.couponModalList.length) {
        return this.$message.error("请选择优惠券！");
      }
      console.log(item,'------sendCouponEvent-----');
      let data = {
        product: {
          productType: "coupon",
          id: item.id,
          amount: item.amount,
          thresholdAmount: item.thresholdAmount,
          name: item.name,
          note: item.note,
        },
        type: "product",
      };
      this.$emit("sendMessageEvent", { type: "product", data: data });
      this.couponModal = false;
    },
    //分发福袋---显示福袋modal
    showFudaiModalEvent() {
      this.fudaiModal = true;
      this.tableLoading.tip = "加载中...";
      this.tableLoading.spinning = true;
      ixunkeAxios(
        { room_id: this.liveDetail.id },
        "get",
        "/api/v1/room/get_room_fuku"
      )
        .then((res) => {
          if (res.errno == 0) {
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].key = res.data[i].id;
            }
            this.fudaiModalList = res.data;
          } else {
            this.$error({
              centered: true,
              title: "福袋数据获取失败！",
              content: JSON.stringify(res.errmsg),
              okText: "知道了",
            });
          }
          this.tableLoading.spinning = false;
        })
        .catch((error) => {
          this.tableLoading.spinning = false;
          this.$error({
            centered: true,
            title: "福袋数据获取失败！",
            content: JSON.stringify(error),
            okText: "知道了",
          });
        });
    },

    
    //分发福袋---分发福袋
    sendFudaiEvent(params) {

      // msg = { type: "lianmai", data: {
      //   action:1,
      //   text:'121233'
      // } }
      this.tableLoading.tip = "发送中...";
      this.tableLoading.spinning = true;
      ixunkeAxios({ id: params.id }, "post", "/api/v1/room/send_fuku")
        .then((res) => {
          if (res.errno == 0) {
            this.$emit("sendMessageEvent", { type: "fudai", data: params });
            this.$message.success("已发布福袋");
            //消息刷新
            this.reshMessageEvent();
            this.fudaiModal = false;
          } else {
            this.$error({
              centered: true,
              title: "福袋发送失败！",
              content: JSON.stringify(res.errmsg),
              okText: "知道了",
            });
          }
          this.tableLoading.spinning = false;
        })
        .catch((error) => {
          this.tableLoading.spinning = false;
          this.$error({
            centered: true,
            title: "福袋发送失败！",
            content: JSON.stringify(error),
            okText: "知道了",
          });
        });
    },
    dateFormat(date, format) {
      if (!format) {
        format = "yyyy-MM-dd hh:mm";
      }
      if (date && date < 10000000000) {
        date = date * 1000;
      }
      date = new Date(date);
      var map = {
        M: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        m: date.getMinutes(),
        s: date.getSeconds(),
        q: Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds(),
      };
      format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
        var v = map[t];
        if (v !== undefined) {
          if (all.length > 1) {
            v = "0" + v;
            v = v.substr(v.length - 2);
          }
          return v;
        } else if (t === "y") {
          return (date.getFullYear() + "").substr(4 - all.length);
        }
        return all;
      });
      return format;
    },
    endTimeFilter(time) {
      if (time == 1) {
        return "永久有效";
      } else {
        return this.dateFormat(time, "yyyy-MM-dd hh:mm:ss");
      }
    },
    prizeContentText(type) {
      if (type == "goods") {
        return "商品";
      } else if (type == "course") {
        return "课程";
      } else if (type == "coupon") {
        return "优惠券";
      } else if (type == "point") {
        return "积分";
      } else if (type == "custom") {
        return "奖品";
      }
    },
  },
  watch: {
    message(newVal, oldVal) {
      // let emoji = emojis.find(item=>{
      //   return `[${item.text}]` == newVal;
      // })
      // console.log(newVal);
      // console.log(emoji);
    },
  },
};
</script>
<style lang="scss" scoped>
.lianmai{
  width: 64px;
  height: 30px;
 

  div{
    color:#fff;
    width: 100%;
    padding: 2px 0;
    border: 1px solid #C2C3C6;
    border-radius: 14px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
ul {
  display: flex;
  flex-wrap: wrap;
}
.bingolist {
  display: flex;
  align-items: center;

  margin: 7px 10px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  width: 306px;
  .userInfo {
    img {
      width: 32px;
    }
    span {
      margin-left: 8px;
      margin-bottom: 0;
    }
  }
  .prize_content {
    margin-left: 14px;
    .ext {
      color: #666;
      font-size: 12px;
      margin-left: 8px;
    }
  }
}
.vote-state-background {
  border-radius: 5px;
  background-color: rgb(243, 72, 69);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
}
.vote-state-end {
  border-radius: 5px;
  background-color: rgb(193, 193, 193);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
}
.questionnaire-box {
  display: flex;
}
.questionnaire-item {
  width: 180px;
  background: #fff;
  margin: 15px;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  .end_time {
    width: 100%;
    color: #999;
    font-weight: 500;
    font-size: 12px;
    margin: 10px 0 0 0;
  }
  .questiontitle {
    margin-left: 10px;
    font-weight: 700;
  }
}
::v-deep .ant-modal {
  // top: 50px !important;
}
::v-deep .ant-modal-content{
  top: -70px !important;
}
.emoji-list {
  position: fixed;
  bottom: 10vh;
  padding: 10px 0;
  font-size: 0;
  left: -20px !important;
  top: 140px !important;
  z-index: 99999;
  // text-align: center;
  // height: 400px;
  // overflow-y: scroll;
  .emoji-item {
    display: inline-block;
    padding: 5px;

    img {
      width: 22px;
      height: 22px;
    }
  }
}
::v-deep .ant-pagination {
  width: 100%;
}

.ixunke-container {
  display: flex;
  flex-wrap: wrap;
}
.ixunke-chat-input-box {
  position: absolute;
  width: 100%;
  height: 160px;
  bottom: 0;
  left: 0;
  padding: 8px 8px 0 8px;
  text-align: left; 
}
.verticaBottom{
  bottom: 5vh !important;
}
#emoji{
  font-size: 16px;
  ::v-deep img{
    width: 20px;
    height: 20px;

  }
}


.ixunke-chat-input-menu {
  position: relative;
  width: 100%;
  height: 22px;
  font-size: 0;
  display: flex;
}

.ixunke-chat-input-menu-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  margin-right: 6px;
  cursor: pointer;
  vertical-align: middle;
  color: #909399;
  margin-right: 10px;
}

.ixunke-chat-input-menu-btn {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 22px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  border: 1px solid #c2c3c6;
  border-radius: 14px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.ixunke-banned-num {
  display: inline-block;
  position: absolute;
  width: auto;
  height: 14px;
  background: #ed2929;
  border-radius: 6px 6px 6px 0px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #fff;
  top: -14px;
  right: 0;
  padding: 0 4px;
}

.ixunke-chat-input {
  display: block;
  position: relative;
  width: 100%;
  height: 121px;
  background: #474951;
  border: 1px solid #c6c6c6;
  opacity: 1;
  border-radius: 11px;
  margin-top: 5px;
  padding: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  outline: none;
  color: #B2B3B3;
}
.vertica{
  background: transparent !important;
  
}
.ixunke-chat-input-num {
  position: absolute;
  width: 28px;
  height: 14px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #686868;
  right: 15px;
  bottom: 38px;
}

.ixunke-chat-send-btn {
  position: absolute;
  width: 67px;
  height: 23px;
  background: #1890ff;
  color: #fff;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 23px;
  right: 15px;
  bottom: 12px;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
}

/* 表情modal */
.ixunke-chat-pic-modal {
  position: fixed;
  background: #fff;
  border: 1px solid #c6c6c6;
  bottom: 180px;
  text-align: center;
  padding: 12px 3px;
  z-index: 9999;
  border-radius: 4px;
}

.ixunke-emoji {
  display: inline-block;
  width: 42px;
  height: 42px;
  background-image: url(http://cdn.ixunke.cn/emoji/emoji-all.png);
  background-size: 100% auto;
  cursor: pointer;
  margin: 3px;
  vertical-align: top;
}

.ixunke-add-pic {
  display: inline-block;
  width: 42px;
  height: 42px;
  text-align: center;
  font-size: 32px;
  line-height: 42px;
  color: #9c27b0;
  cursor: pointer;
  margin: 3px;
  vertical-align: top;
}

.ixunke-chat-pic-modal-triangle {
  width: 0;
  height: 0;
  border-width: 12px 10px 0;
  border-style: solid;
  border-color: #fff transparent transparent;
  position: absolute;
  bottom: -13px;
  left: 22px;
}

.ixunke-chat-pic-modal-triangle-1 {
  width: 0;
  height: 0;
  border-width: 12px 10px 0;
  border-style: solid;
  border-color: #c6c6c6 transparent transparent;
  position: absolute;
  bottom: -11px;
  left: 22px;
}

.quote-box {
  padding: 0 12px;
  position: absolute;
  background: #9e9e9e2e;
  width: 232px;
  height: auto;
  line-height: 32px;
  left: 80px;
  bottom: 136px;
  border-radius: 4px;
}

.message-quote-user {
  color: #1890ff;
}
</style>
