<template>
  <!-- v-if="!isMaintain && upgradeFlag" -->
  <div class="upgrade-mask" v-if="upgradeFlag">
    <div class="upgrade-box">
      <div class="title-box">
        <span>客户端更新</span>
      </div>
      <p class="fontff">发现新版本 V{{ UpData.versionName }}</p>
      <p>---------------------------------------------</p>
      <div class="scroll-box">
        <div class="update-box">
          <p>更新</p>
          <span v-for="(i, index) in UpData.updateNote" :key="index">{{
            i
          }}</span>
        </div>
        <div class="fix-box">
          <p>修复</p>
          <span v-for="(i, index) in UpData.fixedNote" :key="index">{{
            i
          }}</span>
        </div>
      </div>
      <div class="downloading-box" v-if="downFlag">
        <div class="text">
          <span>下载中</span>
          <span>{{ receivedBytes2 }}</span>
        </div>
        <div class="progress">
          <el-progress color="#34c900" :text-inside="true" :stroke-width="12" :percentage="percentage"></el-progress>
        </div>
      </div>
      <div class="buttons" v-if="downFlag">
        <div></div>
        <div @click="startInstall" class="btn btn-ok" v-if="InstallFlag">
          立即安装
        </div>
      </div>
      <div class="buttons" v-if="!downFlag">
        <!-- <div
          @click="skipVersion"
          class="btn btn-cancel"
          v-if="UpData.updateType !== 'force'"
        >
          跳过此版本
        </div>
        <div v-else></div> -->
        <div></div>
        <div>
          <div @click="cancel" class="btn btn-cancel" v-show="UpData.updateType !== 'force'">
            以后再说
          </div>

          <div @click="startDownload" class="btn btn-ok">立即更新</div>
        </div>
      </div>
      <p class="updateFail" @click="goIxunkeUpdate">更新遇到问题，点击跳转到官网下载最新版本</p>
    </div>
  </div>
</template>
<script>
const { spawn } = window.require("child_process");
const { ipcRenderer } = window.require("electron");
const fs = window.require("fs");
const url = window.require("url");
const https = window.require("https");
const http = window.require("http");
const path = window.require("path");
const Store = window.require("electron-store");
const store = new Store();
const crypto = window.require('crypto');
import axios from 'axios'
const os = window.require('os');
const { shell } = window.require('electron')
class Downloader {
  constructor(callbacks) {
    this.targetDir = 'D:\\ixunkeupdate';
    this.fileName = 'IxunkeLive.exe';
    this.callbacks = callbacks;
    this.abortController = new AbortController();
  }

  cancel() {
    this.abortController.abort();
  }

  async download(url) {
    try {
      const filePath = path.join(this.targetDir, this.fileName);
      
      if (fs.existsSync(filePath)) {
        await fs.promises.unlink(filePath);
      }

      return new Promise((resolve, reject) => {
        const file = fs.createWriteStream(filePath);
        
        const request = https.get(url, {
          signal: this.abortController.signal,
          timeout: 30000,
          headers: {
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36'
          }
        }, (response) => {
          if (response.statusCode !== 200) {
            reject(new Error(`下载失败，状态码: ${response.statusCode}`));
            return;
          }

          const totalBytes = parseInt(response.headers['content-length'], 10);
          let downloadedBytes = 0;

          response.on('data', (chunk) => {
            downloadedBytes += chunk.length;
            const progress = (downloadedBytes / totalBytes) * 100;
            this.callbacks.onProgress(progress);
            file.write(chunk);
          });

          response.on('end', () => {
            file.end();
            this.callbacks.onComplete(filePath);
            resolve(filePath);
          });

        }).on('error', (error) => {
          fs.unlink(filePath, () => {
            reject(error);
          });
        });

        this.abortController.signal.addEventListener('abort', () => {
          request.destroy();
          fs.unlink(filePath, () => {
            reject(new Error('下载已取消'));
          });
        });

        file.on('error', (error) => {
          fs.unlink(filePath, () => {
            reject(error);
          });
        });
      });
    } catch (error) {
      this.callbacks.onError(error);
      throw error;
    }
  }

  async ensureDirectory() {
    try {
      if (!fs.existsSync(this.targetDir)) {
        await fs.promises.mkdir(this.targetDir, { recursive: true });
      }
    } catch (error) {
      throw new Error(`创建目录失败: ${error.message}`);
    }
  }
}
export default {
  name: "upgrade",
  props: {},
  data: function () {
    return {
      isMaintain: false,
      UpData: "", //更新数据
      upgradeFlag: false, //更新弹窗
      downFlag: false,
      receivedBytes: 0,
      receivedBytes2: "",
      percentage: 0,
      checkSum: 0,
      InstallFlag: false, //安装状态
      upgradeBuildID: "", //最新版本buildid
      OSVersion: "",  //windows操作系统版本
      isDownloading:false,
      targetDir: 'D:\\ixunkeupdate',
      fileName: 'IxunkeLive.exe'
    };
  },
  methods: {
    async startDownload() {
      console.log(1123)
      
      this.error = null;
      this.progress = 0;
      this.isDownloading = true;
      this.downloadComplete = false;
      this.fullPath = '';
      
      try {
        await this.checkDriveAccess();

        this.downloader = new Downloader({
          onProgress: this.updateProgress,
          onError: this.handleError,
          onComplete: this.handleComplete
        });

        // 替换为实际的下载URL
        const url = 'https://cdn.ixunke.cn/client/IxunkeLive.exe?time=1733997788';
        await this.downloader.download(url);
      } catch (error) {
        console.log(error)
      }
    },

    async checkDriveAccess() {
      try {
        if (!fs.existsSync('D:\\')) {
          throw new Error('D盘不存在');
        }
         // 检查目标目录是否存在，不存在则创建
        if (!fs.existsSync('D:\\ixunkeupdate')) {
          try {
            await fs.promises.mkdir('D:\\ixunkeupdate', { recursive: true });
            console.log('已创建目录: D:\\ixunkeupdate');
          } catch (error) {
            throw new Error(`无法创建目录: ${error.message}`);
          }
        }
        // 检查文件是否存在，存在则删除
        const exePath = path.join('D:\\ixunkeupdate', 'IxunkeLive.exe');
        if (fs.existsSync(exePath)) {
          try {
            await fs.promises.unlink(exePath);
            console.log('已删除旧文件:', exePath);
          } catch (error) {
            throw new Error(`无法删除旧文件: ${error.message}`);
          }
        }

        // 测试写入权限
        // const testFile = path.join(this.targetDir, 'test.txt');
        // await fs.promises.mkdir(this.targetDir, { recursive: true });
        // await fs.promises.writeFile(testFile, 'test');
        // await fs.promises.unlink(testFile);
      } catch (error) {
        throw new Error(`无法访问D盘或没有写入权限: ${error.message}`);
      }
    },

    cancelDownload() {
      if (this.downloader) {
        this.downloader.cancel();
      }
      this.isDownloading = false;
      this.progress = 0;
      this.downloadComplete = false;
    },

    updateProgress(progress) {
      this.percentage = Number((progress).toFixed(1))
      this.downFlag = true;
      if (progress == 100) {
        this.InstallFlag = true;
      }
    },

    handleError(error) {
      this.error = error.message;
      this.isDownloading = false;
      this.downloadComplete = false;
    },

    handleComplete(filePath) {
      this.isDownloading = false;
      this.progress = 100;
      this.downloadComplete = true;
      this.fullPath = filePath;
    },
    goIxunkeUpdate() {
      shell.openExternal('https://tiku.ixunke.com/manage/liveDownload')
        .then(() => console.log('打开链接成功'))
        .catch((err) => console.error('打开链接失败', err));
    },
    computeByte2(bytes) {
      let kb = bytes / 1024;
      let mb = (bytes / 1024 / 1024).toFixed(1);
      let gb = (bytes / 1024 / 1024 / 1024).toFixed(1);
      if (mb > 1024) {
        return gb + "GB";
      } else {
        return mb + "MB";
      }
    },
    async stopMediamtxServer() {
      console.log('结束本地流媒体服务');
      if (this.mediamtxServer) {
        const { exec } = window.require('child_process');

        // 获取 mediamtx 进程的 PID
        const pid = store.get('mediamtxServerPid')

        // 使用 taskkill 命令强制杀死进程及其子进程
        exec(`taskkill /PID ${pid} /T /F`, (err, stdout, stderr) => {
          if (err) {
            console.error(`Error killing mediamtx process with PID ${pid}: ${err.message}`);
          } else {
            store.delete('mediamtxServerPid')
            console.log(`结束了进程 pid: ${pid}`);
          }
        });

        this.mediamtxServer = null;
      }
    },
    async startInstall() {
      this.$message.success('安装中...')
      // await this.stopMediamtxServer()

      // ipcRenderer.send('startInstall')

      try {
        this.isInstalling = true;
        
        // 停止媒体服务进程
        // await this.stopMediaServerProcess();
        
        // 打开安装程序
        const filePath = path.join(this.targetDir, this.fileName);
        await shell.openPath(filePath);
        
        // 2秒后关闭当前窗口
        setTimeout(() => {
          ipcRenderer.send('destroyAllWin')
        }, 2000);
        
      } catch (error) {
        this.error = `安装过程出错: ${error.message}`;
        this.isInstalling = false;
      }
    },
    //检查md5
    async checkMD5(filePath, serverMd5) {
      try {
        await this.stopMediamtxServer()

      } catch (error) {

      }
      try {
        let md5sum = crypto.createHash("md5");
        let md5stream = fs.createReadStream(filePath);
        md5stream.on("data", function (chunk) {
          md5sum.update(chunk);
        });
        md5stream.on("end", function () {
          md5stream?.destroy();
          md5stream?.close();
          var str = md5sum.digest("hex").toUpperCase();
          str.trim();
          console.log(
            "文件:" + filePath + ",MD5签名为:" + str,
            "servermd5:" + serverMd5
          );
          const upMd5 = serverMd5 ? serverMd5.toUpperCase() : "";
          if (str !== upMd5) {
            console.log("md5不相等");
          } else {
            console.log("md5相等");
            spawn("explorer.exe", [
              "C:\\Program Files\\IxunkeLive.exe",
            ]);
            setTimeout(() => {
              ipcRenderer.send("destroyAllWin");

            }, 3000);
          }
        });
        md5stream.on("finish", (src) => {
          md5stream.unpipe();
          console.log("==md5stream===finish==.");
        });
        md5stream.on("close", () => {
          console.log("========md5stream close=======");
        });
        md5stream.on("error", (err) => {
          console.log("========md5stream error=======");
          reject({ error: "error", msg: err });
        });
      } catch (error) {
        console.log(error);
      }

    },
    //跳过此版本
    skipVersion() {
      window.store.set("skipBuildId", this.upgradeBuildID);
      this.upgradeFlag = false;
    },
    cancel() {
      this.upgradeFlag = false;
      sessionStorage.setItem('skip', true)
    },
    mandatoryUpdate(x) {
      window.store.delete("skipBuildId");
      this.getUpdateData(x);
    },
    async getUpdateData(flag) {
      console.log('getUpdateData');
      if (this.OSVersion < 10) {
        this.$notify({
          title: 'Win7/8长期技术支持版本',
          dangerouslyUseHTMLString: true,
          message: `<div style="color:red; display: inline-block;">现版本Win7/8可继续正常使用</div>，如需使用最新版本，请更新系统至Win10/11`,
          type: 'warning',
          duration: 0
        });
        return
        return
      }
      try {
        await axios.get('https://cloud.ixunke.com/api/config/live_push_client')
          .then(response => {
            // console.log(response.data)
            this.UpData = response.data
          })
          .catch(error => {
            console.log(error)
          })
        let LocalBuildID = store.get("buildID");

        let CloudBuildID = Number(this.UpData.buildId);

        if (LocalBuildID < CloudBuildID) {
          this.upgradeFlag = true;
          this.upgradeBuildID = CloudBuildID;
        } else {
          if (flag == true) {
            this.$notify({
              title: '恭喜',
              message: '您的客户端已经是最新版本！',
              type: 'success'
            });
          }
          console.log("不需要升级");

        }
        // console.log(this.UpData);
      } catch (error) {
        console.log("获取升级接口出错", error);
      }
    },
    async confirm() {

      const options = url.parse(this.UpData.downloadUrl);

      console.log(options, "-----------------------");
      let receivedBytes = 0;
      const md5 = this.UpData.md5;
      ipcRenderer.send('downloadURL', {
        url: this.UpData.downloadUrl,
      })
      return

    },
    checkFileMD5(filePath, md5) {
      const _this = this;
      this.checkMD5(filePath, md5)
    },
  },
  created() {
    const _this = this
    this.platform = store.get('platform');

    var OSVersion = os.release();
    function getNumberBeforeDecimal(str) {
      const match = str.match(/^\d+/);
      return match ? match[0] : null;
    }
    this.OSVersion = Number(getNumberBeforeDecimal(OSVersion))
    console.log(this.OSVersion, 'this.OSVersion');

    ipcRenderer.on('downloadNewVersionProgress', (event, data) => {
      _this.percentage = Number((data * 100).toFixed(1))
      _this.downFlag = true;
      console.log(data, '----------downloadNewVersionProgress----------');
      if (data == 1) {
        _this.InstallFlag = true;
      }
    })
    if (!sessionStorage.getItem('skip') && this.platform == 'win32') {
      console.log('页面主动检测升级');
      if (this.OSVersion < 10) {
        console.log('WIN7/8 不需要升级');
        return
      }

      this.getUpdateData();
      return
    }

    if (this.platform == 'darwin') {
      ipcRenderer.send("checkForUpdates");
    }

    // {
    //   buildId: 10101, // * buildID
    //   downloadUrl:
    //     "https://stl3d2.oss-cn-chengdu.aliyuncs.com/test/Ixunke-live.exe", //  *  zip下载链接
    //   versionName: "1.01.01", // * 版本号
    //   fixedNote: ["修复1", "修复2"], //修复文案
    //   updateNote: ["新增1", "新增2"], //更新文案，
    //   checkSum: 95119687, // * 字节大小
    //   md5: "0C9D747541555C0B114BDD5885EAFC68", // * md5校验码
    //   updateType: "force", // * force强制（默认） optional可选升级
    // };
  },
};
</script>
<style lang="scss" scoped>
.updateFail {
  color: red;
  cursor: pointer;
}

.progress {}

.upgrade-mask {
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 999;
  left: 0;
  top: 0;
  overflow: auto;
  background: #00000080;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);

  .upgrade-box {
    width: 400px;
    background: #2d2d2d;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 6px;
    position: relative;
    left: 38%;
    top: 22%;

    .title-box {
      color: #fff;
    }

    .scroll-box {
      overflow-y: scroll;
      height: 250px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 16px;
        // background-color:#8a8888;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #8a8888;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }
    }

    .update-box,
    .fix-box {
      height: 100px;

      span {
        display: block;
      }
    }

    // .scroll-box::-webkit-scrollbar{
    //   display: none; /* Chrome Safari */
    // }
    .buttons {
      padding: 20px 0 10px 0;
      text-align: center;
      display: flex;
      justify-content: space-between;

      .btn {
        // width: 60px;
        // height: 32px;
        line-height: 32px;
        display: inline-block;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 4px;
        padding: 2px 8px;
        font-size: 14px;
      }

      .btn-cancel {
        color: #ffffff;
        // border: 1px solid #ffffff;
        background: #4f4f4f;
      }

      .btn-ok {
        background-color: #34c900;
        color: #fff;
        margin-left: 12px;
      }

      .btn-lock {
        color: #fff;
        margin-left: 12px;
        background-color: #5f6063;
      }
    }

    .downloading-box {
      .text {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>