import {
	emojiMap,
	emojiUrl
} from './emojiMap'
/** 传入messageBody（群系统消息SystemMessage，群提示消息GroupTip除外）
 * payload = {
 *  msgType: 'TIMTextElem',
 *  msgContent: {
 *    text: 'AAA[龇牙]AAA[龇牙]AAA[龇牙AAA]'
 *  }
 *}
 **/
export function decodeText(payload) {
	let renderDom = []
	// 文本消息
	let temp = payload.text
	let left = -1
	let right = -1
	while (temp !== '') {
		left = temp.indexOf('[')
		right = temp.indexOf(']')
		switch (left) {
			case 0:
				if (right === -1) {
					renderDom.push({
						name: 'text',
						text: temp
					})
					temp = ''
				} else {
					let _emoji = temp.slice(0, right + 1)
					if (emojiMap[_emoji]) {
						renderDom.push({
							name: 'img',
							src: emojiUrl + emojiMap[_emoji]
						})
						temp = temp.substring(right + 1)
					} else {
						renderDom.push({
							name: 'text',
							text: '['
						})
						temp = temp.slice(1)
					}
				}
				break
			case -1:
				renderDom.push({
					name: 'text',
					text: temp
				})
				temp = ''
				break
			default:
				renderDom.push({
					name: 'text',
					text: temp.slice(0, left)
				})
				temp = temp.substring(left)
				break
		}
	}

	renderDom.forEach(item => {
		if(item.name == 'text'){
			item.data = replaceTxtLink(item.text);
		}
	})
	return renderDom;
}

function replaceTxtLink(txt) {
	let linkArr = [];
	let reg = /(http:\/\/|https:\/\/|[A-Za-z0-9]+[\-]?[A-Za-z0-9]+\.|[A-Za-z0-9]+\.)((\w|=|\?|\.|\/|&|-)*)/g;
	let textStr = txt.replace(reg, function(item) {
		linkArr.push(item);
		return `#2#${item}#2#`;
	});
	let textArr = textStr.split('#2#');
	let result = [];
	textArr.forEach(item => {
		if(item){
			if(linkArr.indexOf(item) > -1){
				result.push({
					type: 'link',
					data: item
				})
			}else{
				result.push({
					type: 'text',
					data: item
				})
			}
		}
	})
	return result;
}
