import Vue from 'vue'
import router from './router';
import App from './App.vue';
// import VueRouter from 'vue-router';
import store from './store'
import './assets/font/iconfont.css'
const Store = window.require("electron-store");
const llstore = new Store();

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from "element-ui";
// Vue.use(Message);
Vue.prototype.$message = Message;
//ant
import ant from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'
Vue.use(ant)
Vue.use(ElementUI);
import '@/utils/dialogdrag.js'//全局引入v-dialogDrag自定义指令

Vue.prototype.$EventBus = new Vue()

import VueStorage from 'vue-ls'
Vue.use(VueStorage, {
  namespace: 'pro__',
  name: 'ls',
  storage: 'local'
})
//自定义图标  
import { Icon } from 'ant-design-vue';
const ixunkeIcon = Icon.createFromIconfontCN({
  //scriptUrl: "//at.alicdn.com/t/font_2223429_61dv34mt7mo.js"
  scriptUrl: 'icon1.js'
})
Icon.createFromIconfontCN({
  //scriptUrl: "//at.alicdn.com/t/font_2223429_fdwewa2xex6.js"
  scriptUrl: 'icon2.js'
})
Icon.createFromIconfontCN({
  //scriptUrl: "//at.alicdn.com/t/font_2603116_vf63it6pqih.js"
  scriptUrl: 'icon3.js'
})
Vue.component('ixunkeIcon', ixunkeIcon);
//本地存储
// import bootstrap from './core/bootstrap'

//引入样式文件
import './assets/css/style.css'

//bus实例
Vue.prototype.bus = new Vue()

Vue.config.productionTip = true;
// 实例化一个 Vue
let vueApp = new Vue({
  components: { App },
  router,
  store,
  template: '<App/>',
  render: h => h(App),

}).$mount('#app');

// vueApp.$mount("#trtc-electron-demo");

window.$app = vueApp;
const { webFrame } = window.require('electron');
var homeWinFactor = 1;
try {
  homeWinFactor= llstore.get('homeWinFactor')
} catch (error) {
  homeWinFactor = 1
}
console.log(homeWinFactor);
webFrame.setZoomFactor(homeWinFactor)
// window.addEventListener('popstate', () => {
//   console.demoWarn('popstate: ', window.location.pathname);
// });
