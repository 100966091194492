import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { TOKEN } from './../store/mutation-types'
// 创建 axios 实例
const service = axios.create({
    baseURL: '', // api base_url
    timeout: 60000 // 请求超时时间
})

const err = (error) => {
    if (error.response) {
        const data = error.response.data
        const token = Vue.ls.get(TOKEN)
        if (error.response.status === 403) {
            notification.error({
                message: 'Forbidden',
                description: data.message
            })
        }
        if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            notification.error({
                message: 'Unauthorized',
                description: 'Authorization verification failed'
            })
            if (token) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    const token = Vue.ls.get(TOKEN)
    // if (token) {
    //   config.headers['Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    // }
    return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
    const setCookie = response.headers['set-cookie'];
        if (setCookie) {
        setCookie.forEach(cookie => {
            document.cookie = cookie;
        });
    }
    return response.data
}, err)

export {
    service as axios
}