const getters = {
    host: state => state.config.host,
    identification: state => state.user.identification,
    roomId: state => state.config.roomId,
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    status: state => state.config.status,
    definition: state => state.config.definition,
    window: state => state.config.window,
    voice: state => state.config.voice,
    camera: state => state.config.camera,
    cameraId: state => state.config.cameraId,
    mic: state => state.config.mic,
    screen: state => state.config.screen,
    canvas: state => state.config.canvas,
}

export default getters