<template>
  <div>
    <div class="main-page-layout-content main-page-layout--spilt fancy-scrollbar">
      <div class="main-layout-sub-menu" style="padding-right: 10px;">
        <!-- <ul> -->
        <!-- <li
            :class="detect == 0 ? 'is-active' : ''"
            class="main-layout-sub-menu-item"
            @click="videoCheck()"
          >
            <span>视频</span>
          </li>
          <li
            :class="detect == 1 ? 'is-active' : ''"
            class="main-layout-sub-menu-item"
            @click="audioCheck()"
          >
            <span>音频</span>
          </li>

          <li
            :class="detect == 2 ? 'is-active' : ''"
            class="main-layout-sub-menu-item"
            @click="SelectDevice()"
          >
            <span>选择设备</span>
          </li> -->
        <div class="step_box">
          <el-steps direction="vertical" :active="detect">
            <el-step></el-step>
            <el-step></el-step>
            <el-step></el-step>
            <el-step></el-step>
          </el-steps>
          <div class="icon_box">
            <div><img src="../assets/img/device/1-1.png" alt=""></div>
            <div>
              <img v-if="detect >= 2" src="../assets/img/device/2-1.png" alt="">
              <img v-else src="../assets/img/device/2.png" alt="">
            </div>
            <div>
              <img v-if="detect >= 3" src="../assets/img/device/3-1.png" alt="">
              <img v-else src="../assets/img/device/3.png" alt="">
            </div>
            <div>
              <img v-if="detect >= 4" src="../assets/img/device/4-1.png" alt="">
              <img v-else src="../assets/img/device/4.png" alt="">
            </div>
          </div>
        </div>

        <!-- </ul> -->
      </div>

      <div class="main-page-layout-inner fancy-scrollbar">
        <div v-if="detect == 1" class="device-check-layout-container">
          <div v-if="detect == 1" class="camera-check-container">
            <p class="ask">您是否可以看到自己的摄像头画面</p>
            <div class="select_box">
              <div class="text">摄像头</div>
              <el-select v-model="VideoValue" placeholder="请选择" class="VideoSelect" @change="VideoChange">
                <el-option v-for="item in VideoOptions" :key="item.deviceId" :label="item.label" :value="item.deviceId">
                </el-option>
              </el-select>
            </div>


            <div class="camera-check-info">
              <div style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #414351;
                ">
                <video v-if="VideoOptions.length > 0" width="100%" style="object-fit: contain;" ref="cameraStream"
                  id="cameraStream" autoplay :class="CameraMirrorMode ? 'tr180' : ''"></video>
                <div style="font-size: 16px;letter-spacing:1px;" v-else>
                  未检测到摄像头
                </div>
              </div>
              <el-checkbox class="CameraMirrorMode" v-model="CameraMirrorMode">镜像模式</el-checkbox>

            </div>
            <!-- v-if="VideoOptions.length==0" -->
            <span class="notuse" @click="notuseCamera()" v-if="VideoOptions.length == 0">不使用摄像头开启音频直播</span>
            <div class="camera-check-btn">
              <el-button type="button" class="ant-btn ant-btn-default" style="margin-right: 14px"
                @click="abnormal('camera')">
                <span>无法看到</span></el-button><button type="button" class="ant-btn ant-btn-primary"
                @click="audioCheck()">
                <span style="margin-right: 14px">能看到</span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="detect == 2" class="device-check-layout-container">
          <p class="ask">对着麦克风讲话，您是否能看到音量条波动</p>
          <div class="select_box">
            <div class="text">麦克风</div>
            <el-select width: v-model="AudioValue" placeholder="请选择" @change="AudioChange" class="AudioSelect">
              <el-option v-for="item in AudioOptions" :key="item.deviceId" :label="item.label" :value="item.deviceId">
              </el-option>
            </el-select>
          </div>
          <div class="speaker-audio-wrapper">
            <div class="speaker-audio-volume" ref="audioVolume" style="width: 0%"></div>
            <div class="speaker-audio-mask"></div>
          </div>
          <!-- <p class="Speaker">扬声器</p>
            <div class="ant-select ant-select-single ant-select-show-arrow Speaker-box"  >
              <div class="ant-select-selector AudioSelect">
                <el-select style="width: 100%" v-model="AudioOutputValue">
                  <el-option
                    v-for="item in audiooutput"
                    :key="item.deviceId" <div>
                <img v-if="detect>=3" src="../assets/img/device/3-1.png" alt="">
                <img v-else src="../assets/img/device/3.png" alt="">
              </div>
                    :label="item.label"
                    :value="item.deviceId"
                  >
                  </el-option>
                </el-select>
               
              </div>
              <el-button type="primary" @click="testSpeaker">测试扬声器</el-button>
            </div> -->
          <div class="speaker-check-btn-container">
            <button style="margin-right: 14px" type="button" @click="abnormal()" class="ant-btn ant-btn-default">
              <span>无法看到</span>
            </button>
            <button type="button" @click="SelectDevice()" class="ant-btn ant-btn-primary">
              <span>能看到 </span>
            </button>
          </div>
        </div>
        <div v-if="detect == 3" class="device-check-layout-container">
          <p class="ask">点击测试扬声器后，您是否可以听到声音？</p>
          <div class="select_box">
            <div class="ant-select ant-select-single ant-select-show-arrow Speaker-box">
              <div class="text">扬声器</div>
              <div class="ant-select-selector AudioSelect">
                <el-select style="width: 100%" v-model="AudioOutputValue">
                  <el-option v-for="item in audiooutput" :key="item.deviceId" :label="item.label"
                    :value="item.deviceId">
                  </el-option>
                </el-select>

              </div>
              <div style="width: 100%;margin-top: 40px;">
                <el-button type="primary" @click="testSpeaker">测试扬声器</el-button>

              </div>
            </div>
          </div>
          <div class="speaker-check-btn-container" style="margin-top: 166px;margin-left: 15px;">
            <button style="margin-right: 14px" type="button" @click="abnormal('audio')" class="ant-btn ant-btn-default">
              <span>无法听到</span>
            </button>
            <button type="button" @click="ConfirmAudioOutput()" class="ant-btn ant-btn-primary">
              <span>能听到 </span>
            </button>
          </div>
        </div>
        <div v-if="detect == 4" class="device-check-layout-container">
          <div class="result">
            <div class="li">
              <span>检测项目</span>

              <span>结果</span>


            </div>
            <div class="li">
              <span>摄像头</span>
              <div class="flex" v-if="cameraStatus">
                <span class="green"></span>
                <span>正常</span>
              </div>
              <div class="flex" v-else>
                <span class="red"></span>
                <span>异常</span>
                <span class="troubleshoot" @click="showTips('camera')">排查问题</span>

              </div>
            </div>
            <div class="li">
              <span>麦克风</span>
              <div class="flex" v-if="audioStatus">
                <span class="green"></span>
                <span>正常</span>
              </div>
              <div class="flex" v-else>
                <span class="red"></span>
                <span>异常</span>
                <span class="troubleshoot" @click="showTips('camera')">排查问题</span>
              </div>
            </div>
            <div class="li">
              <span>扬声器</span>
              <div class="flex" v-if="speakerStatus">
                <span class="green"></span>
                <span>正常</span>
              </div>
              <div class="flex" v-else>
                <span class="red"></span>
                <span>异常</span>
                <span class="troubleshoot" @click="showTips('audio')">排查问题</span>
              </div>
            </div>
          </div>

          <div class="speaker-check-btn-container">
            <el-button @click="retest()"> 重新检测</el-button>


            <el-button type="primary" @click="hidden()">
              <span>检测完成</span>
            </el-button>
          </div>
        </div>
      </div>


      <audio src="../assets/js/test.wav" ref="speakerAudio">
        您的浏览器暂不支持音频播放
      </audio>
    </div>
  </div>
</template>
<script>
const Store = window.require("electron-store");
const store = new Store();
export default {
  name: "device",
  props: {},
  data() {
    return {
      DefaultvideoDevices: "",
      DefaultaudioDevices: "",
      videoDevices: [],
      audioDevices: [],
      detect: 1,
      _audioStream: "",
      _cameraStream: "",
      volumeInterval: "",
      VideoOptions: [],
      VideoValue: "",
      AudioOptions: [],
      AudioValue: "",

      audiooutput: [], //麦克风列表
      AudioOutputValue: "",

      audioStatus: true,
      cameraStatus: true,
      speakerStatus: true,

      CameraMirrorMode: false,
    };
  },
  mounted() { },
  methods: {
    ConfirmAudioOutput() {
      this.detect = 4;
    },
    async testSpeaker() {
      // this.$refs.audioVolume.style.width = 0;
      clearInterval(this.volumeInterval);
      this.volumeInterval = null;
      let speakerAudio = this.$refs.speakerAudio;
      speakerAudio.setSinkId(this.AudioOutputValue);
      speakerAudio.play();
    },
    hidden() {
      clearInterval(this.volumeInterval);
      this.volumeInterval = null;
      store.set('CameraMirrorMode', this.CameraMirrorMode)
      store.set("device", { 0: this.VideoValue, 1: this.AudioValue })
      // localStorage.setItem(
      //   "device",
      //   JSON.stringify({ 0: this.VideoValue, 1: this.AudioValue })
      // );
      try {
        this._audioStream.getTracks().forEach(track => {
          track.stop()
          console.log();
        })
      } catch (error) {

      }

      this.$emit("hidden", "123");
      //
    },
    retest() {
      this.audioStatus = true;
      this.cameraStatus = true;
      this.speakerStatus = true;
      this.detect = 1;
    },
    notuseCamera() {
      this.cameraStatus = false;
      this.detect = 2;
      this.VideoValue = "";
    },
    VideoChange(e) {
      console.log(e);
      this.getCamera(e);
    },
    abnormal(type) {
      if (type == "camera") {
        console.log('camera');
        this.cameraStatus = false;
        this.detect = 2;
      } else if (type == "audio") {
        this.speakerStatus = false;
        this.detect = 4;
      } else {
        this.audioStatus = false;
        this.detect = 3;
      }
    },
    showTips(type) {
      if (type == "camera") {
        this.$message.error("请确认摄像头没有被其它软件，或重新插入尝试");
      } else if (type == "audio") {
        this.$message.error("请确认扬声器声音是否开启，或重新尝试");
      } else {
        this.$message.error("请请确认麦克风已正确连接并开启，或重新插入尝试");
      }
    },
    async getCamera(id) {
      this._cameraStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: id,
          facingMode: "environment",
          width: {
            min: 1280,
            ideal: 1920,
            max: 1920
          },
          height: {
            min: 720,
            ideal: 1080,
            max: 1080
          }
        },
      });
      try {
        this.$refs.cameraStream.srcObject = this._cameraStream;
        this.$refs.cameraStream.play();
      } catch (error) {
        console.log(error);
      }

    },
    SelectDevice() {
      clearInterval(this.volumeInterval);
      this.volumeInterval = null;
      this.detect = 3;
    },
    videoCheck() {
      clearInterval(this.volumeInterval);
      this.volumeInterval = null;
      this.detect = 1;
      try {
        this.VideoChange(this.VideoOptions[0].deviceId);
      } catch (error) {
        console.log(error);
      }
    },
    async AudioChange(id) {
      clearInterval(this.volumeInterval);
      this.volumeInterval = null;
      try {
        this._cameraStream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.log(error);
      }
      this.detect = 2;
      const audioCtx = new AudioContext();
      const analyserNode = audioCtx.createAnalyser();
      analyserNode.fftSize = 2048;
      this._audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: id },
      });
      // console.log(id,'麦克风设备id');
      const sourceNode = audioCtx.createMediaStreamSource(this._audioStream);
      sourceNode.connect(analyserNode);

      this.volumeInterval = setInterval(() => {
        // 获取频谱数据
        const bufferLength = analyserNode.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyserNode.getByteFrequencyData(dataArray);
        // 计算声贝值
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i];
        }
        this.volume = sum / bufferLength;
        // console.log( this.volume/50);
        try {
          this.$refs.audioVolume.style.width = (this.volume / 50) * 100 + "%";
        } catch (error) {
          clearInterval(this.volumeInterval);
        }
      }, 300);
    },
    async audioCheck() {
      try {
        this.AudioChange(this.AudioOptions[0].deviceId);
      } catch (error) {
        console.log(error);
      }
      this.detect = 2;
    },
  },
  beforeDestroy() {

    clearInterval(this.volumeInterval);
  },
  async created() {
    clearInterval(this.volumeInterval);
    this.getCamera();
    await navigator.mediaDevices.enumerateDevices().then((devices) => {
      // console.log(devices,'-----------------------');
      this.VideoOptions = devices.filter(
        (device) => device.kind === "videoinput"
      );
      if (this.VideoOptions.length > 0) {
        this.DefaultvideoDevices = this.VideoOptions[0].deviceId;
        this.VideoValue = this.VideoOptions[0].deviceId;
      }
     
      // 麦克风
      this.audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      // console.log(this.audioDevices);
      // console.log('初筛的麦克风');
      this.audioDevices = Object.values(
        this.audioDevices.reduce((acc, obj) => {
          if (!acc[obj.groupId]) {
            acc[obj.groupId] = obj;
          }
          return acc;
        }, {})
      );
      this.AudioOptions = this.audioDevices;
      store.set('AudioDeviceNumber', this.AudioOptions.length)
      this.AudioValue = this.AudioOptions[0].deviceId;

      this.audiooutput = devices.filter(
        (device) => device.kind === "audiooutput"
      );
      // console.log(devices);
      // console.log(this.audiooutput);
      this.audiooutput = Object.values(
        this.audiooutput.reduce((acc, obj) => {
          if (!acc[obj.groupId]) {
            acc[obj.groupId] = obj;
          }
          return acc;
        }, {})
      );
      this.AudioOutputValue = this.audiooutput[0].deviceId;

      // console.log(this.audiooutput,'扬声器列表');
      //   console.log(this.audioDevices);
    });

    this.CameraMirrorMode = store.get('CameraMirrorMode')||false
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.main-page-layout-inner {
  margin-top: 10px;
}

.step_box {
  height: 380px;
  position: relative;
  margin-left: 20px;

  .icon_box {
    position: absolute;
    left: 48px;
    top: 0;
    z-index: 2;
    height: 380px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}

::v-deep .el-step__head.is-process {
  color: #999;
  border-color: #999;
}

::v-deep .el-step__head.is-wait {
  color: #999;
  border-color: #999;
}

::v-deep .el-step__title.is-process {
  color: #999;
}

::v-deep .el-step__title.is-wait {
  color: #999;
}

.main-layout-sub-menu,
.device-check-layout-container {
  background: #414351;
}

.result {
  width: 300px;
  // height: 260px;
  font-size: 14px;
  color: #d6d6d6;

  .li {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flex {
      display: flex;
      align-items: center;
    }

    .troubleshoot {
      font-size: 11px;
      color: #0076ff;
      margin-left: 4px;
    }

    .green {
      background: green;
      margin-right: 6px;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      display: block;
    }

    .red {
      background: red;
      margin-right: 6px;
      border-radius: 50%;
      width: 6px;
      height: 6px;
      display: block;
    }
  }
}

.VideoSelect,
.AudioSelect {
  display: flex;
  width: 200px;

  font-size: 12px;

  ::v-deep .el-input {
    font-size: 12px;
  }
}

.speaker-check-btn-container {
  margin-top: 72px;
  width: 210px !important;

  button {
    width: 98px;
    height: 40px;

    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.Speaker-box {
  display: flex !important;
  height: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.speaker-audio-wrapper {
  position: relative;
  width: 360px;
  height: 20px;
  margin: 60px 1px 28px;
  background-color: #f4f6f9;
}

.speaker-audio-volume {
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: #0076FF;
}

.speaker-audio-mask {
  position: absolute;
  width: 100%;
  height: 20px;
  background: repeating-linear-gradient(to right,
      transparent,
      transparent 29px,
      white 1px,
      white 30px);
}

.Speaker {
  width: 100%;
  height: 30px;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #d6d6d6;
}

.main-page-layout--spilt {
  display: flex;
}

.main-page-layout-content {
  flex: 1;
}

.fancy-scrollbar {
  overscroll-behavior: contain;
  overflow: auto;
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: auto;
}

.main-layout-sub-menu {
  width: 130px;
  // border-right: solid 1px #dbe1ea;
  // background-color: #fff;
}

.is-active {
  background: #0076FF;
  color: #ffffff !important;
  border-radius: 16px;
}

.main-layout-sub-menu-item {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 9px 0px 9px 0;
  align-items: center;
  color: #5d6066;
  font-size: 14px;
}

.main-layout-sub-menu-item-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.main-layout-sub-menu-item-icon {
  svg {
    width: 22px;
    height: 22px;
    vertical-align: text-top;
  }
}

.btn-default {
  background: #2e3037;
  border: none;
}

.device-check-layout-container {
  display: flex;
  justify-content: center;
  //   height: 100%;
  overflow: hidden;
  padding: 0 24px;
  border-radius: 6px;
  // background-color: #fff;

}

::v-deep .ant-btn-primary,
.el-button--primary {
  background-color: #0076FF;
  border-color: #0076FF;
}

.camera-check-container,
.device-check-layout-container {
  min-width: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin-left: 20px;

  .ask {
    color: #D6D6D6;
    font-size: 16px;
  }

  .camera-check-btn {
    display: block;
    width: 210px;

    button {
      width: 98px;
      height: 40px;

      span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }
  }

  .select_box {
    display: flex;

    align-items: center;
    margin: 30px 0;

    .text {
      color: #D6D6D6;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  p {
    margin-bottom: 8px;
    color: #383b42;
    font-size: 14px;
    text-align: left;
  }

  .notuse {
    // position: absolute;
    width: 100%;
    font-size: 16px;
    color: #06f;
    margin-bottom: 20px;
  }
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 360px;
  margin-bottom: 15px;
  color: #5d6066;
}

.camera-check-info {
  width: 230px;
  height: 136px;
  margin-bottom: 44px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fafafa;

  .CameraMirrorMode {
    position: absolute;
    color: #fff;
    margin-top: 8px;
    margin-left: -20px;
  }

  .tr180 {
    transform: rotateY(180deg);
  }
}

.el-dialog__title {
  color: #ccc;
}

::v-deep .el-step__line {
  top: 6px !important;
  height: 100%;
}

::v-deep .el-step__head.is-finish{

    .el-step__icon.is-text{
      background: #0076FF;
      border:none;
    }
}
::v-deep .el-step__icon{
  width: 12px;
  height: 12px;
  .el-step__icon-inner{
  display: none;
}
}

</style>