import Vue from 'vue'
import { loginEvent, ixunkeAxios } from './../../assets/js/axios'
import { HOST, TOKEN, USER_INFO, IDENTIFICATION } from './../mutation-types'
const Store = window.require("electron-store");
const store = new Store();
const user = {
    state: {
        token: '',
        userInfo: {},
        identification: '',
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_IDENTIFICATION: (state, identification) => {
            console.log(identification)
            state.identification = identification
        },
    },
    actions: {
        //登录
        async login({ dispatch, commit }, allData) {
            let result = await loginEvent(allData.subData, allData.axiosUrl)
            if (result.errno === 0) {
                Vue.ls.set(TOKEN, result.data.token)
                store.set('token',result.data.token)
                commit('SET_TOKEN', result.data.token)
                Vue.ls.set(USER_INFO, result.data)
                commit('SET_USER_INFO', result.data)
                commit('SET_USER_INFO', result.data)
                // dispatch('getIdentification')
            }

            return result
        },
        //获取用户信息
        async getUserInfo({ commit }) {
            var host = store.get('host')
            let userInfo = await ixunkeAxios({}, 'get', host + '/api/member')
            if (userInfo.errno === 0) {
                Vue.ls.set(USER_INFO, userInfo.data)
                commit('SET_USER_INFO', userInfo.data)
                dispatch('getIdentification')
            }
            return userInfo
        },
        //获取租户标识
        // async getIdentification({ commit }) {
        //     var host = store.get('host')
        //     console.log(host,'-----------getIdentification-------------');
        //     let configData = await ixunkeAxios({}, 'get', host + '/api/config/mini_config')
        //     let configArr = configData.data
        //     console.log(configArr)
        //     for (let i = 0; i < configArr.length; i++) {
        //         if (configArr[i].id == 100 && configArr[i].context && configArr[i].context.name) {
        //             console.log(configArr[i].context.name)
        //             Vue.ls.set(IDENTIFICATION, configArr[i].context.name)
        //             commit('SET_IDENTIFICATION', configArr[i].context.name)
        //         }
        //     }
        // },
        //登出
        async logout({ commit }) {
            let result = { errno: 0, errmsg: '' }
            commit('SET_TOKEN', '')
            commit('SET_USER_INFO', {})
            Vue.ls.remove(TOKEN)
            Vue.ls.remove(USER_INFO)
            return result
        },
    }
}

export default user