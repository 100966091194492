import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login'
import Test from '../views/test'
import course from '../views/course'
import pulish from '../views/Home.vue'
Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/pulish',
    name: 'pulish',
    component: pulish
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import( '../views/course/index.vue')
  },
  {
    path: '/im',
    name: 'im',
    component: () => import( '../views/im/index.vue')
  },
  {
    path: '/tips',
    name: 'tips',
    component: () => import( '../views/tips/index.vue')
  },
  {
    path: '/audience',
    name: 'Audience',
    component: () => import( '../views/audience/index.vue')
  },
  {
    path: '/vertica',
    name: 'Vertica',
    component: () => import( '../views/VerticalHome/index.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import( '../views/VerticalHome/test.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
