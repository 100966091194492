<template>
  <div class="ixunke-message-text" :style="{ textAlign: !unknow && textRight ? 'right' : 'left' }">
    <template v-if="isQuestion && payload.isQuestion">
      <span class="ixunke-chat-item-info">
        <a-icon type="question-circle" class="ixunke-chat-item-info-icon" />
        <span v-for="(item, index) in textArr" :key="index" v-html="item.text"></span>
      </span>
    </template>
    <template v-else>
      <a-tooltip
        overlayClassName="ixunke-tooltip"
        :placement="textRight ? 'left' : 'right'"
        trigger="oncontextmenu"
        v-model="tipShow"
        :getPopupContainer="
          (triggerNode) => {
            return triggerNode.parentNode || document.body
          }
        "
      >
        <template slot="title">
          <div class="tip-action-box" @click="messageEvent('copy')">复制</div>
          <div class="tip-action-box" v-if="payload.isQuestion" @click="messageEvent('quote')">引用</div>
          <div class="tip-action-box" @click="messageEvent('revoke')">撤回</div>
        </template>
        <div style="text-align: center" v-if="unknow">
          <span class="ixunke-notice">未知类型消息</span>
        </div>
        <template v-else>
          <img
            :src="emoji"
            :alt="payload.text"
            v-if="emoji"
            class="ixunke-chat-item-info-emoji"
            @click="previewModalEvent('image', emoji)"
          />
          <!-- @click="previewModalEvent('text', JSON.stringify(textArr))" -->
          <span
            class="ixunke-chat-item-info"
            :class="textRight ? 'ixunke-chat-item-info-right' : ''"
            
            v-else
          >
            <a-icon type="question-circle" class="ixunke-chat-item-info-icon" v-if="payload.isQuestion" />
            <span v-for="(item, index) in textArr" :key="index">
              <img style="width: 24px;height: 24px;"  :src="item.src" v-if="item.type == 'img'" alt="">
              <span v-else v-html="item.text" class="132"></span> 
            </span>
          </span>
          <br v-if="payload && payload.quote" />
          <span class="message-quote" v-if="payload && payload.quote">
            <span>引用&nbsp;</span>
            <span class="message-quote-user">{{ payload.quoter }}</span>
            <span>：{{ payload.quote }}</span>
          </span>
        </template>
      </a-tooltip>
    </template>
    <messagePreview :previewModal.sync="previewModal" :previewType="previewType" :previewContent="previewContent" />
  </div>
</template>

<script>
// import moment from 'moment'
import  emojis  from './emoji'
import {
		decodeText
	} from './decodeText.js'
import messagePreview from './message-preview.vue'
export default {
  name: 'messageText',
  props: ['payload', 'unknow', 'textRight', 'isQuestion'],
  components: {
    messagePreview,
  },
  data() {
    return {
      tipShow: false,
      previewModal: false,
      previewType: '',
      previewContent: '',
    }
  },
  computed: {
    emoji(){
      if(!this.payload){
        return "";
      }
      let emoji = emojis.find(item=>{
        return `[${item.text}]` == this.payload.text;
      })
      return emoji;
    },
    textArr() {
      if (!this.payload) {
        return []
      }
      let links = []
      let text = JSON.stringify(this.payload.text)
      text = text.replace(/\\n/g, '<br>');
      try {
        text = JSON.parse(text)
      } catch (error) {
        text = text
      }
      
      // return
      let regLink = /(http:\/\/|https:\/\/|[A-Za-z0-9]+[\-]?[A-Za-z0-9]+\.|[A-Za-z0-9]+\.)((\w|=|\?|\.|\/|&|-)*)/g
      let textStr = text.replace(regLink, function (item) {
        links.push(item)
        return `#2#${item}#2#`
      })
      let textArr = textStr.split('#2#')
      let result = []
      textArr.forEach(item=>{
					if(item){
						if(links.includes(item)){
							result.push({
								text: item,
								type: 'link'
							})
						}else{
							let arr = decodeText({
								text: item
							});
							arr.forEach(i=>{
								if(i.name == 'img'){
									result.push({
										src: i.src,
										type: 'img'
									})
								}else if(i.name == 'text'){
									result.push({
										text: i.text.trim(),
										type: 'txt'
									})
								}
							})
						}
					}
				})
        return result;
    },
  },
  mounted() {
  },
  methods: {
    // emojis,
    messageEvent(action) {
      this.$emit('messageAction', action)
      this.tipShow = false
    },
    previewModalEvent(type, content) {
      this.previewModal = true
      this.previewType = type
      this.previewContent = content
    },
  },
}
</script>
<style lang="css" scoped>
.ixunke-message-text {
  position: relative;
  width: 100%;
  height: auto;
}

.ixunke-notice {
  display: inline-block;
  width: auto;
  height: 21px;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #dfdfdf;
  padding: 0 10px;
  background: #6a6d75;
  border-radius: 13px;
}

.ixunke-chat-item-info-emoji {
  position: relative;
  width: 60px;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.ixunke-chat-item-info {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background: #2E76EE;
  border-radius: 0px 6px 6px;
  margin: 6px 20px 0;
  padding: 7px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #fff;
  word-break: break-all;
  text-align: justify;
}
.ixunke-chat-item-info-right {
  border-radius: 6px 0px 6px 6px;
  background: #1890ff;
  /* background: rgba(0, 0, 0, .2); */
  color: #fff;
}
.ixunke-chat-item-info-icon {
  color: #ff5f71;
  font-size: 14px;
  margin-right: 6px;
}
.tip-action-box {
  line-height: 32px;
  padding: 0 6px;
}
.tip-action-box:hover {
  background: #1890ff;
  color: #fff;
}
.message-quote {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background: #9e9e9e2e;
  border-radius: 0px;
  margin: 6px 20px 0;
  padding: 7px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #9e9e9e;
  word-break: break-all;
  text-align: justify;
  border-radius: 4px;
}
.message-quote-user {
  color: #1890ff;
}
</style>

