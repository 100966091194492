<template>
  <div>
    <el-dialog
     
      :visible.sync="QuitVisible"
      width="550px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      id="centerClass"
    >
      <svg
        t="1687858265627"
        class="closeIcon"
        @click="goBackHome"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1880"
        width="200"
        height="200"
      >
        <path
          d="M512 456.310154L94.247385 38.557538a39.542154 39.542154 0 0 0-55.689847 0 39.266462 39.266462 0 0 0 0 55.689847L456.310154 512 38.557538 929.752615a39.542154 39.542154 0 0 0 0 55.689847 39.266462 39.266462 0 0 0 55.689847 0L512 567.689846l417.752615 417.752616c15.163077 15.163077 40.290462 15.36 55.689847 0a39.266462 39.266462 0 0 0 0-55.689847L567.689846 512 985.442462 94.247385a39.542154 39.542154 0 0 0 0-55.689847 39.266462 39.266462 0 0 0-55.689847 0L512 456.310154z"
          fill="#999999"
          p-id="1881"
        ></path>
      </svg>
      <device @hidden="handleClose"></device>
    </el-dialog>
  </div>
</template>
<script>
import device from "./device.vue";

export default {
  data() {
    return {
      QuitVisible: true,

      imglist: [
        require("../assets/img/jc1.png"),
        require("../assets/img/jc2.png"),
        require("../assets/img/jc3.png"),
      ],
    };
  },
  components: { device },

  methods: {
    handleClose() {
      this.QuitVisible = false;
      this.$emit("hidden");
    },
    goBackHome() {
      this.$router.push({
        name: "course",
      });
    },
  },
};
</script>
<style lang="scss" scoped>

  ::v-deep .el-dialog {
    background: #414351;
    border-radius: 20px;
    .el-dialog__header{
          padding:0;
      }
      .el-dialog__body{
        height: 440px;
        border-radius: 20px;
        
      }
  }

.closeIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>