<template>
  <div id="app">

    <router-view />
  </div>
</template>

<script>
const { webFrame } = window.require('electron');

export default {
  data() {
    return {
      ipcRenderer: null
    };
  },
  created() {
    if (!window.require) {
      console.error('Electron integration is not available');
      return;
    }

    this.ipcRenderer = window.require('electron').ipcRenderer;

    // 添加事件监听器
    this.ipcRenderer.on('changeWindow', this.changeWindow);
  },
  beforeDestroy() {
    
  },
  methods: {
    changeWindow(event, message) {
      console.log('Message from main process:', message);
      let homeWinFactor = 1;
      try {
        homeWinFactor = Number(message)
      } catch (error) {
        
      }
      
      webFrame.setZoomFactor(homeWinFactor)

    }
  }
}
</script>
<!-- "requestedExecutionLevel": "requireAdministrator", -->
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
