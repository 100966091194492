//1、单词类型 wordStyle()
//3、素材---系统图片标签 systemImageTagsArr()
//4、素材---系统音频标签 systemImageTagsArr()
//5、素材---系统视频标签 systemVideoTagsArr()
//6、支付方式 payType()

//8、信息采集--模板组件 collectInfoComponentArr()
//9、emojis---表情 emojis(text)


//1、单词类型
export function wordStyle() {
    return [{
        label: '形容词',
        value: 'adj',
    },
    {
        label: '副词',
        value: 'adv',
    },
    {
        label: '冠词',
        value: 'art',
    },
    {
        label: '连词',
        value: 'conj',
    },
    {
        label: '连词',
        value: 'conj',
    },
    {
        label: '感叹词',
        value: 'int',
    },
    {
        label: '名词',
        value: 'n',
    },
    {
        label: '数词',
        value: 'num',
    },
    {
        label: '介词',
        value: 'prep',
    },
    {
        label: '代词',
        value: 'pron',
    },
    {
        label: '动词',
        value: 'v',
    },
    {
        label: '不及物动词',
        value: 'vi',
    },
    {
        label: '及物动词',
        value: 'vt',
    },
    ]
}
// <!-- prep=介系词；前置词，preposition的缩写

// pron=代名词，pronoun的缩写

// n=名词，noun的缩写

// u=不可数名词，uncountable noun的缩写

// c=可数名词，countable noun的缩写网上学英语比较靠谱的推荐：www.ecgraduate.com 联美英语网。

// v=动词，兼指及物动词和不及物动词，verb的缩写

// vi=不及物动词，intransitive verb的缩写

// vt=及物动词，transitive verb的缩写

// conj=连接词，conjunction的缩写

// adj= ，adjective的缩写

// adv=副词，adverb的缩写

// art=冠词，article的缩写

// num=数词，numeral的缩写

// int=感叹词，interjection的缩写-->

//3、素材---系统图片标签
export function systemImageTagsArr() {
    return [{
        id: 'logoImg',
        name: '图标',
    },
    {
        id: 'coverImg',
        name: '封面',
    },
    {
        id: 'shareImg',
        name: '邀请卡',
    },
    {
        id: 'textImg',
        name: '富文本',
    },
    {
        id: 'camp_img',
        name: '日签图',
    },
    {
        id: 'otherImg',
        name: '其他',
    }
    ]
}

//4、素材---系统音频标签
export function systemAudioTagsArr() {
    return [{
        id: 'Q_BankAudio',
        name: '题目音频',
    },
    {
        id: 'courseAudio',
        name: '课程音频',
    },
    {
        id: 'newsAudio',
        name: '资讯音频',
    },
    {
        id: 'wordAudio',
        name: '词库音频',
    },
    {
        id: 'otherAudio',
        name: '其他音频',
    }
    ]
}

//5、素材---系统视频标签
export function systemVideoTagsArr() {
    return [{
        id: 'Q_BankVideo',
        name: '题目视频',
        type: '系统标签',
    },
    {
        id: 'courseVideo',
        name: '课程视频',
        type: '系统标签',
    },
    {
        id: 'newsVideo',
        name: '资讯视频',
        type: '系统标签',
    },
    {
        id: 'otherVideo',
        name: '其他视频',
        type: '系统标签',
    }
    ]
}

//3、素材---系统资料标签
export function systemFileTagsArr() {
  return [{
      id: 'courseFile',
      name: '课程资料',
  },
  {
      id: 'filepacket',
      name: '资料包',
  },
  {
      id: 'otherFile',
      name: '其他资料',
  }
  ]
}

//6、支付方式
export function payType() {
    return [{
        value: 'WX_APP',
        name: '微信APP',
    }, {
        value: 'ALI_APP',
        name: '支付宝APP',
    }, {
        value: 'virtualCurrency',
        name: '账户余额',
    }, {
        value: 'point',
        name: '积分兑换',
    }, {
        value: 'WX_TRANSFER',
        name: '微信企业转账',
    }, {
        value: 'WX_JSAPI',
        name: '微信公众号',
    }, {
        value: 'WX_MINI',
        name: '微信小程序',
    }, {
        value: 'WX_NATIVE',
        name: '微信NATIVE',
    }, {
        value: 'WX_WAP',
        name: '微信WAP',
    }, {
        value: 'ALIPAY_LOGONID',
        name: '支付宝企业转账',
    }, {
        value: 'ALI_WEB',
        name: '支付宝WEB',
    }, {
        value: 'ALI_WAP',
        name: '支付宝WAP',
    }, {
        value: 'QQ_MINI',
        name: 'QQ小程序',
    }, {
        value: 'IAP',
        name: '苹果IAP',
    },]
}

//8、信息采集--模板组件
export function collectInfoComponentArr() {
    return [{
        title: '姓名',
        type: 'trueName',
        icon: 'user',
    },
    {
        title: '性别',
        type: 'gender',
        icon: 'team',
    },
    {
        title: '生日',
        type: 'birthday',
        icon: 'calendar',
    },
    {
        title: '兴趣',
        type: 'interest',
        icon: 'heart',
    },
    {
        title: '课程',
        type: 'appointmentCourse',
        icon: 'edit',
    },
    {
        title: '语言',
        type: 'language',
        icon: 'message',
    },
    {
        title: '职位',
        type: 'position',
        icon: 'coffee',
    },
    {
        title: '行业',
        type: 'industry',
        icon: 'appstore',
    },
    {
        title: '公司',
        type: 'company',
        icon: 'bank',
    },
    {
        title: '地址',
        type: 'address',
        icon: 'environment',
    },
    {
        title: '国家',
        type: 'country',
        icon: 'global',
    },
    {
        title: '邮箱',
        type: 'email',
        icon: 'mail',
    },
    {
        title: '手机号',
        type: 'phone',
        icon: 'mobile',
    },
    {
        title: '微信号',
        type: 'weixin',
        icon: 'wechat',
    },
    {
        title: '选择器',
        type: 'select',
        icon: 'global',
    },
    {
        title: '身份证号',
        type: 'IDCard',
        icon: 'idcard',
    },
    {
        title: '文本描述',
        type: 'explain',
        icon: 'profile',
    },
    {
        title: '自定义',
        type: 'custom',
        icon: 'edit',
    },
    ]
}
//9、emojis
export function emojis(text) {
    let gifUrlprefix = 'http://cdn.ixunke.cn/emoji/';
    let emojisArr = [{
        text: '赞',
        index: 0,
        gif: gifUrlprefix + 'zan.gif'
    },
    {
        text: '好',
        index: 1,
        gif: gifUrlprefix + 'hao.gif'
    },
    {
        text: 'OK',
        index: 2,
        gif: gifUrlprefix + 'ok.gif'
    },
    {
        text: '666',
        index: 3,
        gif: gifUrlprefix + '666.gif'
    },
    {
        text: '棒',
        index: 4,
        gif: gifUrlprefix + 'bang.gif'
    },
    {
        text: '谢谢',
        index: 5,
        gif: gifUrlprefix + 'xiexie.gif'
    },
    {
        text: '比心',
        index: 6,
        gif: gifUrlprefix + 'bixin.gif'
    },
    {
        text: '拜拜',
        index: 7,
        gif: gifUrlprefix + 'bye.gif'
    },
    {
        text: '辛苦了',
        index: 8,
        gif: gifUrlprefix + 'xinkule.gif'
    },
    {
        text: '老师好',
        index: 9,
        gif: gifUrlprefix + 'laoshihao.gif'
    },
    {
        text: '努力中',
        index: 10,
        gif: gifUrlprefix + 'nulizhong.gif'
    },
    {
        text: '好好学习',
        index: 11,
        gif: gifUrlprefix + 'haohaoxuexi.gif'
    },
    {
        text: 'A',
        index: 12,
        gif: gifUrlprefix + 'A.gif'
    },
    {
        text: 'B',
        index: 13,
        gif: gifUrlprefix + 'B.gif'
    },
    {
        text: 'C',
        index: 14,
        gif: gifUrlprefix + 'C.gif'
    },
    {
        text: 'D',
        index: 15,
        gif: gifUrlprefix + 'D.gif'
    },
    {
        text: 'E',
        index: 16,
        gif: gifUrlprefix + 'E.gif'
    },
    {
        text: '0',
        index: 17,
        gif: gifUrlprefix + '0.gif'
    },
    {
        text: '1',
        index: 18,
        gif: gifUrlprefix + '1.gif'
    },
    {
        text: '2',
        index: 19,
        gif: gifUrlprefix + '2.gif'
    },
    {
        text: '3',
        index: 20,
        gif: gifUrlprefix + '3.gif'
    },
    {
        text: '4',
        index: 21,
        gif: gifUrlprefix + '4.gif'
    },
    {
        text: '5',
        index: 22,
        gif: gifUrlprefix + '5.gif'
    }
    ]
    let emojiImage = emojisArr.find(item => {
        return `[${item.text}]` == text;
    })
    if (emojiImage) {
        return emojiImage.gif;
    } else {
        return false
    }
}

//订单---所有订单类型
export function orderTypeEvent(type, note) {
    if (note == 'q_bank') {
        note = 'questions'
    }
    let orderTypeArr = [
        {
            name: '题库',
            note: 'questions',
            color: '#0066FF',
        },
        {
            name: '课程',
            note: 'course',
            color: '#00CCCC',
        },
        {
            name: '直播',
            note: 'room',
            color: '#00CC99',
        },
        {
            name: '班级',
            note: 'resource',
            color: '#FF66FF',
        },
        {
            name: 'VIP',
            note: 'vip',
            color: '#FF9649',
        },
        {
            name: '打卡',
            note: 'camp',
            color: '#CC99FF',
        },
        {
            name: '砍价',
            note: 'cut',
            color: '#C992FF',
        },
        {
            name: '购物车',
            note: 'cart',
            color: '#6E73FF',
        },
        {
            name: '资料包',
            note: 'filepacket',
            color: '#E06EFE',
        },
        {
            name: '落地页',
            note: 'landingpage',
            color: '#FF6666',
        }, {
            name: '优惠券',
            note: 'coupon',
            color: '#0099FF',
        },
        {
            name: '实物商品',
            note: 'goods',
            color: '#FF6666',
        },
        {
            name: '解析视频',
            note: 'analysisvideo',
            color: '#00CCFF',
        },
        {
            name: '自选套餐',
            note: 'resource_sku',
            color: '#99CC33',
        },
    ]
    if (type == 'allData') {
        return orderTypeArr
    } else {
        for (let i = 0; i < orderTypeArr.length; i++) {
            if (note == orderTypeArr[i].note) {
                return orderTypeArr[i][type]
            }
        }
    }
}
