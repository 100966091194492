<template>
  <div class="box" v-show="MacUpdateState">
    <div class="update-box">
      <svg
        t="1702350460447"
        @click="cancel"
        class="close"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1468"
        width="200"
        height="200"
      >
        <path
          d="M512 456.310154L94.247385 38.557538a39.542154 39.542154 0 0 0-55.689847 0 39.266462 39.266462 0 0 0 0 55.689847L456.310154 512 38.557538 929.752615a39.542154 39.542154 0 0 0 0 55.689847 39.266462 39.266462 0 0 0 55.689847 0L512 567.689846l417.752615 417.752616c15.163077 15.163077 40.290462 15.36 55.689847 0a39.266462 39.266462 0 0 0 0-55.689847L567.689846 512 985.442462 94.247385a39.542154 39.542154 0 0 0 0-55.689847 39.266462 39.266462 0 0 0-55.689847 0L512 456.310154z"
          fill="#999999"
          p-id="1469"
        ></path>
      </svg>
      <div class="name">
        <img class="logo" src="../assets/img/logo.png" alt="" />
        <p>迅课直播助手</p>
      </div>
      <div v-if="!lastVersion">
        <p class="version">New Version {{ updateData.version }}</p>
        <div class="fix-box">
          <span v-for="(i, index) in updateData.updateNote" :key="index"> - {{ i }} </span>
        </div>
        <div class="menu" v-if="!download">
          <div class="next" @click="cancel()">下次再说</div>
          <div class="Update" @click="Update()">立即更新</div>
        </div>
        <div v-else style="margin-top: 20px">
          <span style="font-size: 18px">正在下载{{ percentage.toFixed(2) }}%</span>
          <div
            class="Update"
            style="width: 170px; margin-top: 14px"
            v-if="percentage == 100"
            @click.stop="restart()"
          >
            立即重启
          </div>
        </div>

        <p class="updateFail" @click="goIxunkeUpdate">更新遇到问题，点击跳转到官网下载最新版本</p>

      </div>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
const { ipcRenderer } = window.require("electron");
const Store = window.require("electron-store");
const store = new Store();
const { shell } = window.require('electron')

export default {
  name: "macUpdate",
  data() {
    return {
      updateData: null,
      MacUpdateState: false,
      lastVersion: true,
      download: false, //正在下载
      percentage: 0, //下载进度
      flag:true,
    };
  },
  methods: {
    goIxunkeUpdate() {
      shell.openExternal('https://tiku.ixunke.com/manage/liveDownload')
        .then(() => console.log('打开链接成功'))
        .catch((err) => console.error('打开链接失败', err));
    },
    cancel() {
      this.MacUpdateState = false;
    },
    Update() {
      // ipcRenderer.send('updateNow')
      ipcRenderer.send("comfirmUpdate");
    },
    restart() {
      const NOTIFICATION_TITLE = "正在更新迅课直播助手";
      const NOTIFICATION_BODY = "更新完成后将自动打开应用";

      new Notification(NOTIFICATION_TITLE, { body: NOTIFICATION_BODY });

      ipcRenderer.send("updateNow");
    },
  },
  mounted() {
    ipcRenderer.on("updateAvailable", async (event, arg) => {
      console.log(arg);
      if (arg?.update) {
        console.log("有更新");
        this.lastVersion = false;
        this.MacUpdateState = true;
        console.log(this.MacUpdateState);
        this.updateData = arg;
      }
    });
    ipcRenderer.on("downloadProgress", (event, arg) => {
      this.download = true;

      this.percentage = arg.percent;
      console.log(this.percentage);
    });
    ipcRenderer.on("updateDownloaded", (event, arg) => {
      console.log("下载完成了");
      this.download = true;
      this.percentage = 100;
    });
    ipcRenderer.on("printUpdaterMessage", (event, arg) => {
      console.log(arg);
      if (arg == "无新版本") {
        if(this.flag){
          this.$notify({
            title: "",
            message: "您的客户端已经是最新版本！",
            type: "success",
          });
        }
        this.flag = true;
      }

      // console.log(arg);
    });
    ipcRenderer.on("update-downloaded", (event, arg) => {
      console.log("下载完成");

      console.log("开始安装");
      ipcRenderer.send("updateNow");
    });
    ipcRenderer.send("checkForUpdates");
    this.flag = false;

  },
};
</script>
<style lang="scss" scoped>
.updateFail{
    color: red;
    cursor: pointer;
    margin-top: 14px;
    font-size: 12px;
  }
.box {
  position: fixed;
  left: 0%;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.fix-box {
  display: flex;
  flex-wrap: wrap;
  span {
    margin: 2px 0;
  }
}
.update-box {
  position: fixed;
  left: 40%;
  z-index: 999;
  top: 30vh;
  width: 300px;

  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  .close {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 6%;
    top: 7%;
  }
  .name {
    display: flex;
    font-size: 22px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    .logo {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
    p {
      margin: 0 10px;
    }
  }
  .version {
    font-size: 16px;
    padding-top: 14px;
    font-weight: 800;
    text-align: left;
  }
  .menu {
    display: flex;
    margin-top: 40px;
    .next {
      font-size: 14px;
      width: 100px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      border: 1px solid #dad6d6;
    }
  }
  .Update {
    background: #006af9;
    color: #fff;
    font-size: 14px;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
