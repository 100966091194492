import Vue from 'vue'
import store from '@/store/index.js'

export const formatPhone = (phone) => {
	if (phone) {
		return phone.substr(0, 3) + '******' + phone.substr(-2, 2)
	} else {
		return ''
	}
}

export const formatCount = (count) => {
	count = parseInt(count);
	if (!isNaN(count)) {
		if (count > 999 && count < 9999) {
			// return '999+';
			return count;
		} else if (count > 9999 && count < 99999) {
			return '9999+';
		} else if (count > 99999) {
			return '10w+';
		} else {
			return count;
		}
	} else {
		return '';
	}
}

export const toChinesNum = (num) => {
    let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; 
    let unit = ["", "十", "百", "千", "万"];
    num = parseInt(num);
    let getWan = (temp) => {
    　　let strArr = temp.toString().split("").reverse();
    　　let newNum = "";
    　　for (var i = 0; i < strArr.length; i++) {
      　　newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
    　　}
     　 return newNum;
   }
   let overWan = Math.floor(num / 10000);
   let noWan = num % 10000;
   if (noWan.toString().length < 4) {noWan = "0" + noWan;}
   return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}

export const formartPercent = (done, all, point) => {
	let p = point || 0;
	if (all) {
		return Math.round(done / all * 100 * Math.pow(10, p)) / Math.pow(10, p);
	} else {
		return 0;
	}
}

export const formatPrice = (price, iosPaySwitch) => {
	let BI = store.state.adminConfig.currency || '';
	// console.log(iosPaySwitch)
	if (typeof price == 'number') {
		price = Math.round(price * 100) / 100;
	}
	// #ifdef MP
	if (!iosPaySwitch && (uni.getSystemInfoSync().platform == 'ios' || uni.getSystemInfoSync().platform == 'devtools')) {
		if (price == 'icon') {
			return 'noPrice';
		} else {
			return '专享';
		}
	} else {
		if (price == 'icon') {
			return '';
		} else {
			return BI + price;
		}
	}
	// #endif
	// #ifdef H5
	if (price == 'icon') {
		return ''
	} else {
		return BI + price;
	}
	// #endif

	// #ifdef APP-PLUS
	if (price == 'icon') {
		return ''
	} else {
		if (Vue.prototype.$readLocalConfig) {
			return '订阅';
		} else {
			return BI + price;
		}
	}
	// #endif
}


export const formatByte = function(bytes) {
	if (isNaN(bytes)) {
		return '';
	}
	var symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	var exp = Math.floor(Math.log(bytes) / Math.log(2));
	if (exp < 1) {
		exp = 0;
	}
	var i = Math.floor(exp / 10);
	bytes = bytes / Math.pow(2, 10 * i);

	if (bytes.toString().length > bytes.toFixed(2).toString().length) {
		bytes = bytes.toFixed(2);
	}
	return bytes + ' ' + symbols[i];
};

export const formatRichText = function(html, style) {
	if (!html) {
		return '';
	}
	let fontSize = 15,
		pBottom = 20,
		imgBlock = false;
	let sizeArr = ['12px', '13px', '16px', '18px', '24px', '32px', '48px'];
	if (style) {
		if (style.fontSize) {
			fontSize = style.fontSize;
		}
		if (style.pBottom) {
			pBottom = style.pBottom;
		}else if (style.pBottom === 0) {
			pBottom = 0;
		}
		if (style.imgBlock) {
			imgBlock = true;
		}
		// if(style.prop){
		// 	// 其他属性未自定义
		// }
	}
	html = html.replace(/section/g, "p");
	html = html.replace(/div/g, "p");
	html = html.replace(/<p[^>]*>/gi, function(match, capture) {
		// console.log(match);
		if(match.indexOf('style=')>-1){
			return match;
		}else{
			return "<p style='font-size:" + fontSize + "px;margin-bottom:" + pBottom + "px;'>";
		}
	})
	html = html.replace(/<table>/g,
		"<table cellspacing='0' style='border:1px solid #ccc;width:100%;margin-bottom:20px;'>");
	html = html.replace(/<td>/g, "<td style='border:1px solid #ccc'>");
	html = html.replace(/<td rowspan/g, "<td style='border:1px solid #ccc' rowspan");
	
	html = html.replace(/<font[^>]*>/gi, function(match, capture) {
		let style = '';
		let colorReg = /color[:=]([\w\W]+)[;"']/
		let colorMatch = match.match(colorReg);
		if (colorMatch && colorMatch[1]) {
			style += ";color:" + colorMatch[1];
		}

		let sizeReg = /size=["']([\d]+)[;"']/
		let sizeMatch = match.match(sizeReg);
		if(sizeMatch && sizeMatch[1]){
			let size = sizeMatch[1]-1;
			let fontSize = sizeArr[size];
			style += ";font-size:" + fontSize;
		}

		if (style) {
			return '<font style="' + style + '" >';
		}
		return '<font>';
	})
	
	html = html.replace(/<font/g, "<span");
	html = html.replace(/font>/g, "span>");
	
	let imgStyle = 'max-width:100%;height:auto;vertical-align:top;margin-left:auto;margin-right:auto;';
	imgStyle = `style=${imgStyle}`;
	html = html.replace(/<img[^>]*>/gi, function(match, capture) {
		if (match.indexOf('style') == -1) {
			return match.replace('<img', '<img '+imgStyle) // 增加style
		} else {
			return match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig, imgStyle) // 替换style
		}
	})
	return html;
};

// 秒 转 mm:ss格式
export const formatTime = function(time) {
	var minute = parseInt(time / 60);
	if (minute < 10) {
		minute = '0' + minute;
	}
	var secound = parseInt(time % 60);
	if (secound < 10) {
		secound = '0' + secound;
	}
	return minute + ':' + secound;
}

// 格式化时间戳
export const dateFormat = function(date, format) {
	if (!format) {
		format = 'yyyy-MM-dd hh:mm'
	}
	if(date && date < 10000000000){
		date = date * 1000;
	}
	date = new Date(date);
	var map = {
		"M": date.getMonth() + 1,
		"d": date.getDate(),
		"h": date.getHours(),
		"m": date.getMinutes(),
		"s": date.getSeconds(),
		"q": Math.floor((date.getMonth() + 3) / 3),
		"S": date.getMilliseconds()
	};
	format = format.replace(/([yMdhmsqS])+/g, function(all, t) {
		var v = map[t];
		if (v !== undefined) {
			if (all.length > 1) {
				v = '0' + v;
				v = v.substr(v.length - 2);
			}
			return v;
		} else if (t === 'y') {
			return (date.getFullYear() + '').substr(4 - all.length);
		}
		return all;
	});
	return format;
}


export const dateFormat2 = function(timeStamp) {
	var publishTime = timeStamp / 1000,
		d_seconds,
		d_minutes,
		d_hours,
		d_days,
		timeNow = parseInt(new Date().getTime() / 1000),
		d,
		date = new Date(publishTime * 1000),
		Y = date.getFullYear(),
		M = date.getMonth() + 1,
		D = date.getDate(),
		H = date.getHours(),
		m = date.getMinutes(),
		s = date.getSeconds();
	//小于10的在前面补0
	if (M < 10) {
		M = "0" + M;
	}
	if (D < 10) {
		D = "0" + D;
	}
	if (H < 10) {
		H = "0" + H;
	}
	if (m < 10) {
		m = "0" + m;
	}
	if (s < 10) {
		s = "0" + s;
	}

	d = timeNow - publishTime;
	d_days = parseInt(d / 86400);
	d_hours = parseInt(d / 3600);
	d_minutes = parseInt(d / 60);
	d_seconds = parseInt(d);

	if (d_days > 0 && d_days < 30) {
		return d_days + "天前";
	} else if (d_days <= 0 && d_hours > 0) {
		return d_hours + "小时前";
	} else if (d_hours <= 0 && d_minutes > 0) {
		return d_minutes + "分钟前";
	} else if (d_seconds < 60) {
		if (d_seconds <= 10) {
			return "刚刚";
		} else {
			return d_seconds + "秒前";
		}
	} else if (d_days >= 30 && d_days < 365) {
		return Math.round(d_days / 30) + "个月前";
	} else if (d_days >= 365) {
		return Math.round(d_days / 365) + "年前";
	}
};


// 试卷答题状态
export const formatPaperState = (checkStatus) => {
	let n = checkStatus + '';
	switch (n) {
		case "-3":
			return '已结束';
			break;
		case "-2":
			return '未开始';
			break;
		case "-1":
			return '已结束';
			break;
		case "0":
			return '未做';
			break;
		case "1":
			return '暂存';
			break;
		case "2":
			return '已做';
			break;
		default:
			return '';
			break;
	}
}
