<template>
  <div class="ixunke-page" style="flex-wrap: wrap;">
    <NAV @closeLive="closeLive"></NAV>
    <a-spin :spinning="courseLoading" :tip="courseLoadText" size="large" class="ixunke-loading"></a-spin>

    <div class="info_box">
      <!-- <div class="avatar" @click="InfoClick()">
        <img :src="avatarUrl" alt="" />
      </div> -->
      <div class="Home_Navigation">
        <button class="Navigation_title" title="直播" @click="SearchType = 'room'">
          <svg v-if="SearchType !== 'room'" xmlns="http://www.w3.org/2000/svg" width="43" height="44.991"
            viewBox="0 0 43 44.991">
            <g id="直播_1_" data-name="直播 (1)" transform="translate(-61.217 -40.33)">
              <path id="路径_2" data-name="路径 2"
                d="M90.891,48.294h5.761a7.565,7.565,0,0,1,7.565,7.565v21.9a7.565,7.565,0,0,1-7.565,7.565H68.782a7.565,7.565,0,0,1-7.565-7.565v-21.9a7.565,7.565,0,0,1,7.565-7.565h5.55l-2.976-5.626a1.592,1.592,0,1,1,2.815-1.489l3.764,7.115h9.352l3.764-7.115a1.593,1.593,0,0,1,2.817,1.489ZM68.782,51.479a4.38,4.38,0,0,0-4.38,4.38v21.9a4.38,4.38,0,0,0,4.38,4.38h27.87a4.38,4.38,0,0,0,4.38-4.38v-21.9a4.38,4.38,0,0,0-4.38-4.38Z"
                transform="translate(0 0)" fill="#999" />
              <path id="路径_3" data-name="路径 3"
                d="M353.391,413.69V426.9a2.588,2.588,0,0,0,3.92,2.22l11-6.6a2.588,2.588,0,0,0,0-4.439l-11-6.6a2.588,2.588,0,0,0-3.92,2.22Zm12.432,6.6-9.247,5.548v-11.1Z"
                transform="translate(-278.238 -353.087)" fill="#999" />
            </g>
          </svg>

          <svg v-else xmlns="http://www.w3.org/2000/svg" width="43" height="44.991" viewBox="0 0 43 44.991">
            <g id="直播_1_" data-name="直播 (1)" transform="translate(-61.217 -40.33)">
              <path id="路径_2" data-name="路径 2"
                d="M90.891,48.294h5.761a7.565,7.565,0,0,1,7.565,7.565v21.9a7.565,7.565,0,0,1-7.565,7.565H68.782a7.565,7.565,0,0,1-7.565-7.565v-21.9a7.565,7.565,0,0,1,7.565-7.565h5.55l-2.976-5.626a1.592,1.592,0,1,1,2.815-1.489l3.764,7.115h9.352l3.764-7.115a1.593,1.593,0,0,1,2.817,1.489ZM68.782,51.479a4.38,4.38,0,0,0-4.38,4.38v21.9a4.38,4.38,0,0,0,4.38,4.38h27.87a4.38,4.38,0,0,0,4.38-4.38v-21.9a4.38,4.38,0,0,0-4.38-4.38Z"
                transform="translate(0 0)" fill="#0076ff" />
              <path id="路径_3" data-name="路径 3"
                d="M353.391,413.69V426.9a2.588,2.588,0,0,0,3.92,2.22l11-6.6a2.588,2.588,0,0,0,0-4.439l-11-6.6a2.588,2.588,0,0,0-3.92,2.22Zm12.432,6.6-9.247,5.548v-11.1Z"
                transform="translate(-278.238 -353.087)" fill="#0076ff" />
            </g>
          </svg>

          <p class="iconName">直播</p>
        </button>

        <button class="Navigation_title" title="课程" @click="SearchType = 'lesson'">
          <svg v-if="SearchType !== 'lesson'" xmlns="http://www.w3.org/2000/svg" width="43" height="42.198"
            viewBox="0 0 43 42.198">
            <g id="互动教材" transform="translate(0)">
              <path id="路径_4" data-name="路径 4" d="M38.73,172.21h30.4v2.972H38.73Z"
                transform="translate(-32.429 -132.984)" fill="#999" />
              <path id="路径_5" data-name="路径 5"
                d="M78.346,68.881H58.892a.637.637,0,0,0-.676.676v2.162a.637.637,0,0,0,.676.674H78.346a.637.637,0,0,0,.674-.674V69.557a.637.637,0,0,0-.674-.676Zm.134-10H59.026a.637.637,0,0,0-.676.676v2.162a.637.637,0,0,0,.676.674H78.48a.637.637,0,0,0,.676-.674V59.56a.637.637,0,0,0-.686-.676Z"
                transform="translate(-47.176 -47.214)" fill="#999" />
              <path id="路径_6" data-name="路径 6"
                d="M48.085,10.9H20.555a7.745,7.745,0,0,0-7.735,7.735V40.012a7.745,7.745,0,0,0,7.735,7.735H48.085a7.745,7.745,0,0,0,7.735-7.735V18.635A7.745,7.745,0,0,0,48.085,10.9ZM36.733,43.509l-3.686,1.945a.647.647,0,0,1-.958-.54L32.048,41.1a.647.647,0,0,1,.944-.559l3.718,1.86a.61.61,0,0,1,.024,1.111Zm15.925-3.492a4.579,4.579,0,0,1-4.574,4.574H40.938a6.843,6.843,0,0,0-6.619-6.916A6.844,6.844,0,0,0,27.7,44.587H20.555a4.579,4.579,0,0,1-4.574-4.574V18.635a4.579,4.579,0,0,1,4.574-4.574H48.085a4.579,4.579,0,0,1,4.574,4.574Z"
                transform="translate(-12.82 -10.9)" fill="#999" />
            </g>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="43" height="42.198" viewBox="0 0 43 42.198">
            <g id="互动教材" transform="translate(0)">
              <path id="路径_4" data-name="路径 4" d="M38.73,172.21h30.4v2.972H38.73Z"
                transform="translate(-32.429 -132.984)" fill="#0076ff" />
              <path id="路径_5" data-name="路径 5"
                d="M78.346,68.881H58.892a.637.637,0,0,0-.676.676v2.162a.637.637,0,0,0,.676.674H78.346a.637.637,0,0,0,.674-.674V69.557a.637.637,0,0,0-.674-.676Zm.134-10H59.026a.637.637,0,0,0-.676.676v2.162a.637.637,0,0,0,.676.674H78.48a.637.637,0,0,0,.676-.674V59.56a.637.637,0,0,0-.686-.676Z"
                transform="translate(-47.176 -47.214)" fill="#0076ff" />
              <path id="路径_6" data-name="路径 6"
                d="M48.085,10.9H20.555a7.745,7.745,0,0,0-7.735,7.735V40.012a7.745,7.745,0,0,0,7.735,7.735H48.085a7.745,7.745,0,0,0,7.735-7.735V18.635A7.745,7.745,0,0,0,48.085,10.9ZM36.733,43.509l-3.686,1.945a.647.647,0,0,1-.958-.54L32.048,41.1a.647.647,0,0,1,.944-.559l3.718,1.86a.61.61,0,0,1,.024,1.111Zm15.925-3.492a4.579,4.579,0,0,1-4.574,4.574H40.938a6.843,6.843,0,0,0-6.619-6.916A6.844,6.844,0,0,0,27.7,44.587H20.555a4.579,4.579,0,0,1-4.574-4.574V18.635a4.579,4.579,0,0,1,4.574-4.574H48.085a4.579,4.579,0,0,1,4.574,4.574Z"
                transform="translate(-12.82 -10.9)" fill="#0076ff" />
            </g>
          </svg>
          <p class="iconName">课程</p>
        </button>
        <button class="Navigation_title deviceChecK" title="设置">
          <svg class="" id="settingIcon" v-on:mouseover="handleMouseOver" v-on:mouseleave="handleMouseLeave(3000)"
            t="1689146279428" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1897"
            width="200" height="200">
            <path
              d="M944.48 552.458667l-182.357333 330.666666a73.792 73.792 0 0 1-64.565334 38.325334h-362.133333a73.792 73.792 0 0 1-64.565333-38.325334l-182.357334-330.666666a75.338667 75.338667 0 0 1 0-72.682667l182.357334-330.666667a73.792 73.792 0 0 1 64.565333-38.325333h362.133333a73.792 73.792 0 0 1 64.565334 38.325333l182.357333 330.666667a75.338667 75.338667 0 0 1 0 72.682667z m-55.989333-31.146667a10.773333 10.773333 0 0 0 0-10.378667l-182.037334-330.666666a10.517333 10.517333 0 0 0-9.205333-5.482667H335.733333a10.517333 10.517333 0 0 0-9.205333 5.482667l-182.037333 330.666666a10.773333 10.773333 0 0 0 0 10.378667l182.037333 330.666667a10.517333 10.517333 0 0 0 9.205333 5.472h361.514667a10.517333 10.517333 0 0 0 9.205333-5.472l182.037334-330.666667zM513.738667 682.666667c-94.261333 0-170.666667-76.405333-170.666667-170.666667s76.405333-170.666667 170.666667-170.666667c94.250667 0 170.666667 76.405333 170.666666 170.666667s-76.416 170.666667-170.666666 170.666667z m0-64c58.912 0 106.666667-47.754667 106.666666-106.666667s-47.754667-106.666667-106.666666-106.666667-106.666667 47.754667-106.666667 106.666667 47.754667 106.666667 106.666667 106.666667z"
              fill="#bfbfbf" p-id="1898"></path>
          </svg>
          <p class="iconName">设置</p>
        </button>
        <div class="settings_box" v-if="showSetting" v-on:mouseover="handleMouseOver"
          v-on:mouseleave="handleMouseLeave(1000)">
          <!-- <p @click="InfoClick()">个人中心</p> -->
          <p @click="checkUpdate()">检测更新</p>
          <p @click="Logout()">退出登录</p>
        </div>
      </div>
    </div>
    <div style="width: 92vw">

      <div class="ixunke-course-header-box">
        <!-- <div class="ixunke-course-header-title" @click="test">
          {{ SearchType == "room" ? "直播列表" : "课时列表" }}
        </div> -->
        <div class="LiveStatus">
          <span class="">课程状态</span>
          <el-select @change="LiveStatusChange" v-model="value">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="ixunke-right-header-search-box">
          <ixunkeIcon class="ixunke-right-header-search-icon" type="icon-dlient-search"
            @click="getCourseListEvent(SearchType)" />
          <input class="ixunke-right-header-search-input" type="text" placeholder="请输入关键词搜索课时" v-model="keyword"
            v-on:keyup.enter.stop="Search" />
        </div>
        <ixunkeIcon class="ixunke-right-header-refresh-icon" type="icon-client-refresh"
          @click="getCourseListEvent(SearchType)" />
      </div>

      <div class="ixunke-course-content-box yhidden" v-if="SearchType == 'lesson'">
        <span class="ixunke-course-content-item" v-for="(lesson, index) in courseData" :key="index"
          @click="ShowTip(lesson)">
          <div class="ixunke-course-content-item-img-box">
            <img class="ixunke-course-content-item-img" :src="lesson.course && lesson.course.originPicture
              ? lesson.course.originPicture
              : 'https://cdn.ixunke.cn/static/teacher-live/course-default.png'
              " :alt="lesson.title" />
          </div>
          <div class="ixunke-course-content-item-text-box">
            <div class="ixunke-course-content-item-text-title">
              <a-tooltip placement="top">
                <template slot="title">
                  <span v-if="lesson.title">课时：{{ lesson.title }}</span>
                </template>
                <span v-if="lesson.title">{{ lesson.title }}</span>
              </a-tooltip>
            </div>
            <div class="ixunke-course-content-item-text-title">
              <a-tooltip placement="top">
                <template slot="title">
                  <span v-if="lesson.course && lesson.course.title">课程：《{{ lesson.course.title }}》</span>
                </template>
                <span v-if="lesson.course && lesson.course.title">课程：《{{ lesson.course.title }}》</span>
              </a-tooltip>
            </div>
            <div class="ixunke-course-time">
              开播时间：{{
                moment(lesson.startTime).format
                  ("YYYY-MM-DD HH:mm:ss")
              }}
            </div>
            <div class="ixunke-course-info" v-if="lesson.liveEnd !== 1">
              <span class="ixunke-course-icon" />
              <span class="ixunke-course-category">{{ lesson.category }}</span>
              <span class="ixunke-course-go-on-btn" @click="goLivePageEvent(lesson, 'lesson')">开始直播
              </span>
              <!-- v-if="item.liveEnd!==1" -->
            </div>
          </div>
        </span>
        <el-empty v-if="courseData.length == 0" description="暂无直播课程，请联系管理员添加直播权限。" :image-size="200"></el-empty>
      </div>
      <div class="ixunke-course-content-box yhidden" v-if="SearchType == 'room'">
        <div class="ixunke-course-content-item" v-for="(item, index) in courseData" :key="index" @click="ShowTip(item)">
          <div class="ixunke-course-content-item-img-box">
            <img class="ixunke-course-content-item-img" v-on:error="handleImageError" :src="item.originPicture
              ? item.originPicture
              : 'https://cdn.ixunke.cn/static/teacher-live/course-default.png'
              " :alt="item.name" />
          </div>
          <div class="ixunke-course-content-item-text-box">
            <div style="color: #333333">
              <div class="lessonName">
                <!-- <p style="width: 100%; margin: 0;padding: 0;text-align: left;" v-if="item.liveEnd==1">状态：已结束</p> -->
                <!-- <p style="width: 100%; margin: 0;padding: 0;text-align: left;" v-else>状态：{{ item.liveStatus==0?' 未直播':'直播中' }}</p> -->
                <span v-if="item.name" style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">{{ item.name }}</span>
              </div>
            </div>

            <div class="ixunke-course-time">
              开播时间：{{
                moment(item.startTime).format("YYYY-MM-DD HH:mm:ss")
              }}
            </div>
            <div class="ixunke-course-info" v-if="item.liveEnd !== 1">
              <span class="ixunke-course-icon" />
              <span class="ixunke-course-category">{{ item.category }}</span>
              <span class="ixunke-course-go-on-btn" v-if="item.liveEnd !== 1"
                @click="goLivePageEvent(item, 'live')">开始直播
              </span>
              <!--  -->
              <span type="info" class="ixunke-course-go-on-btn" id="LiveEnd" v-else>直播已结束
              </span>
            </div>
          </div>
        </div>
        <el-empty v-if="courseData.length == 0" description="暂无直播课程，请联系管理员添加直播权限。" :image-size="200"></el-empty>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-count="totalPages"
        @next-click="nextClick"
        @prev-click="prevClick"
        @current-change="currentChange"
        :current-page="page">
      </el-pagination>
      <div class="ixunke-course-content-box yhidden" v-if="SearchType == 'device'"></div>
      <div class="ixunke-course-content-box yhidden" v-if="SearchType == 'userInfo'">
        <userInfo></userInfo>
      </div>
    </div>

    <div class="quit-box" v-if="QuitVisible">
      <p>是否退出直播客户端?</p>
      <span style="position: absolute; right: 14px; top: 6px; font-size: 16px" @click="QuitVisible = false">x</span>
      <div class="fnc-box">
        <span class="quit" @click="quit">退出</span>
        <span class="min" @click="min">最小化</span>
      </div>
    </div>
    <update ref="update" v-if="platform == 'win32'"></update>
    <macUpdate ref="macUpdate" v-if="platform == 'darwin'"></macUpdate>
  </div>
</template>

<script>
const { ipcRenderer } = window.require("electron");
import { ixunkeAxios } from "@/assets/js/axios.js";
import moment from "moment";
import device from "../../components/device.vue";
import userInfo from "../../components/userInfo.vue";
const Store = window.require("electron-store");
const store = new Store();
import NAV from '../../assets/common/nav.vue';
import _ from "lodash";
import macUpdate from "../../components/mac-update.vue";
import update from "../../components/update.vue";
export default {
  components: { device, userInfo, update, NAV, macUpdate },
  name: "courseListPage",
  data() {
    return {
      //加载动画
      courseLoading: false,
      courseLoadText: "加载中...",
      //
      host: "",
      courseData: [],
      tmpcourseData: [],
      keyword: "",
      SearchType: "room",
      avatarUrl: "",

      timestamp: "", //当前时间戳
      zeroTimestamp: "", //0点时间戳
      twentyFourTimestamp: "", //24点时间戳
      TodayLiveList: [],
      description: null,
      options: [
        {
          value: 0,
          label: "未开始",
        },
        {
          value: 1,
          label: "已结束",
        },
      ],
      value: 0,
      QuitVisible: false,

      showSetting: false, // 显示设置

      timeoutId: "",
      platform: "win32",
      page:1,
      pageSize:12,
      liveEnd:0,
      totalPages:0,
    };
  },
  mounted() {

    let PushType = store.get('PushType');
    console.log(PushType, '----PushType---');
    if (PushType === undefined) {
      console.log('第一次进入，不存在PushType');
      store.set('PushType', 0)
    }
    ipcRenderer.on("Quit", () => {
      console.log('首页退出');
      this.QuitVisible = true;
    });
    ipcRenderer.send("windowSize", "course");
    setTimeout(() => {
      this.killXunkeLivePusher()
    }, 5000);
    //获取课程列表数据
    this.getCourseListEvent(this.SearchType);
    if (this.platform == 'win32') {
      try {
        // this.startMediamtxServer();
      } catch (error) {
        // this.$message.error('启动本地流媒体服务失败，请联系it人员')
      }
    }

  },
  created() {
    // this.$router.push({
    //   name:"About"
    // })
    const os = window.require('os');
    store.set('OSVersion', os.release())
    document.title = "​迅课直播助手";
    // 获取当前时间（北京时间）
    store.set("GUIRouter", "home");
    this.platform = store.get('platform') || 'win32'
    const now = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Shanghai" })
    );
    // 获取当前时间的时间戳
    this.timestamp = now.getTime();
    // 获取当天0点的毫秒时间戳
    this.zeroTimestamp = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();

    // 获取当天24点的毫秒时间戳
    this.twentyFourTimestamp =
      new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() -
      1;

    // console.log('当天0点的毫秒时间戳：', zeroTimestamp);
    // console.log('当天24点的毫秒时间戳：', twentyFourTimestamp);
    this.host = store.get("host");
    try {
      const avatar = store.get("userInfo").avatar;
      this.avatarUrl = this.host + avatar;
    } catch (error) {

    }


    // this.updateData = {
    //   buildId: 20101, // * buildID
    //   downloadUrl:
    //     "https://walter-file.oss-cn-beijing.aliyuncs.com/ixunke/IxunkeLive.exe", //  *  zip下载链接
    //   versionName: "1.01.01", // * 版本号
    //   fixedNote: ["修复聊天室概率登录失败bug", "修复聊天室概率无法发送消息bug",], //修复文案
    //   updateNote: ["更新课件转码功能，速度更快", ], //更新文案，
    //   checkSum: 89967840, // * 字节大小
    //   md5: "DC1D026E653809EB71F050D5E1DD3A32", // * md5校验码
    //   updateType: "force", // * force强制（默认） optional可选升级
    // };
  },
  methods: {
    killXunkeLivePusher() {
      const { exec } = window.require('child_process');
      // 先查找进程
      exec('tasklist /FI "IMAGENAME eq xunkeLivePusher.exe"', (error, stdout) => {
        if (error) {
          console.error('查询进程失败:', error);
          return;
        }

        // 检查输出中是否包含进程名
        if (stdout.includes('xunkeLivePusher.exe')) {
          // 结束进程
          exec('taskkill /F /IM xunkeLivePusher.exe', (error, stdout) => {
            if (error) {
              console.error('结束进程失败:', error);
              return;
            }
            console.log('进程已终止:', stdout);
          });
        } else {
          console.log('未找到xunkeLivePusher进程');
        }
      });
    },
    async checkAndStopMediaServerService() {
      const { exec } = window.require('child_process');

      // 查询名为"MediaServer"的服务
      exec('sc query "MediaServer"', (err, stdout, stderr) => {
        if (err) {
          console.error(`Error querying MediaServer service: ${err.message}`);
          return;
        }

        // 检查服务状态
        if (stdout.includes('RUNNING')) {
          console.log('MediaServer service is running. Stopping the service...');

          // 停止MediaServer服务
          exec('sc stop "MediaServer"', (stopErr, stopStdout, stopStderr) => {
            if (stopErr) {
              console.error(`Error stopping MediaServer service: ${stopErr.message}`);
              return;
            }
            console.log('结束MediaServer！！！');
          });
        } else {
          console.log('MediaServer service is not running.');
        }
      });
    },
    async stopMediamtxServer() {
      console.log('结束本地流媒体服务');
      if (this.mediamtxServer) {
        const { exec } = window.require('child_process');

        // 获取 mediamtx 进程的 PID
        const pid = store.get('mediamtxServerPid')

        // 使用 taskkill 命令强制杀死进程及其子进程
        exec(`taskkill /PID ${pid} /T /F`, (err, stdout, stderr) => {
          if (err) {
            console.error(`Error killing mediamtx process with PID ${pid}: ${err.message}`);
          } else {
            store.delete('mediamtxServerPid')
            console.log(`结束了进程 pid: ${pid}`);
          }
        });

        this.mediamtxServer = null;
      }
    },
    async startMediamtxServer() {
      let mediamtxServerPid = store.get('mediamtxServerPid')
      if (mediamtxServerPid) {
        console.log(mediamtxServerPid);
        console.log('存在进程，异常！！！！！！');
        // await this.stopMediamtxServer()
        return
      }
      // await this.checkAndStopMediaServerService()
      const { spawn, exec } = window.require('child_process');
      const path = window.require('path');

      // 设置 mediamtx.exe 的路径
      // const mediamtxPath = path.join('D:', '360Downloads', 'mediamtx_v1.8.4_windows_amd64', 'mediamtx.exe'); // 修改为实际路径
      const mediamtxPath = path.join('C:', 'Program Files', 'Ixunke', 'live', 'resources', 'xunkeMedia.exe');

      const startMediamtx = () => {
        this.mediamtxServer = spawn(`"${mediamtxPath}"`, { shell: true });
        console.log(this.mediamtxServer, '------this.mediamtxServer------');
        // 变量用于存储子进程的输出数据
        let stdoutData = '';
        let stderrData = '';

        // 处理标准输出
        this.mediamtxServer.stdout.on('data', (data) => {
          // stdoutData += data.toString();
          // console.log(`stdout: ${data}`);
        });

        // 处理标准错误输出
        this.mediamtxServer.stderr.on('data', (data) => {
          stderrData += data.toString();
          console.error(`stderr: ${data}`);
        });

        // 处理子进程错误事件
        this.mediamtxServer.on('error', (err) => {
          console.error(`Failed to start subprocess: ${err.message}`);
        });

        // 处理子进程关闭事件
        this.mediamtxServer.on('close', (code) => {
          console.log(`mediamtx process exited with code ${code}`);
          if (code === 0) {
            console.log('Subprocess started successfully.');
          } else {
            console.log('Subprocess failed to start.');
          }
          console.log(`Final stdout: ${stdoutData}`);
          console.error(`Final stderr: ${stderrData}`);
        });
      };

      startMediamtx();
      const pid = this.mediamtxServer.pid;
      console.log(pid, '-------------pid----------');

      store.set('mediamtxServerPid', pid)
    },
    closeLive() {
      this.QuitVisible = true;
    },
    test() {
      this.$router.push({
        name: "test",

      });
    },
    moment,
    min() {
      this.QuitVisible = false;
      ipcRenderer.send("minimize");
    },
    checkUpdate() {

      if (this.platform == 'win32') {

        this.showSetting = false;
        let OSVersion = store.get('OSVersion') || '10.0';
        function getNumberBeforeDecimal(str) {
          const match = str.match(/^\d+/);
          return match ? match[0] : null;
        }
        OSVersion = Number(getNumberBeforeDecimal(OSVersion))
        if (OSVersion < 10) {
          this.$notify({
            title: 'Win7/8长期技术支持版本',
            dangerouslyUseHTMLString: true,
            message: `<div style="color:red; display: inline-block;">现版本Win7/8可继续正常使用</div>，如需使用最新版本，请更新系统至Win10/11`,
            type: 'warning',
            duration: 0
          });
          return
        }
        this.$refs.update.getUpdateData(true);
      } else {
        ipcRenderer.send("checkForUpdates");
      }
    },
    async Logout() {
      console.log(1);
      store.delete("token");
      store.delete("userInfo");
      try {
        window.TIM.destroy();
      } catch (error) { }
      if (this.platform == 'win32') {
        try {
          await this.stopMediamtxServer()
        } catch (error) {

        }
      }

      this.$router.replace({
        name: "Login",
      });
    },
    async quit() {
      try {
        await this.stopMediamtxServer()
      } catch (error) {

      }
      this.QuitVisible = false;
      ipcRenderer.send("destroyAllWin");
    },
    handleMouseLeave(time) {
      this.timeoutId = setTimeout(() => {
        this.showSetting = false;
      }, time);
    },
    handleMouseOver() {
      clearTimeout(this.timeoutId);
      this.showSetting = true;
    },
    handleClose() {
      this.QuitVisible = false;
    },
    handleImageError(event) {
      // console.log(" 图片加载错误");
      event.target.src = "https://cdn.ixunke.cn/client/img/course-default.png";
    },

    ShowTip(item) {
      if (item.liveEnd == 1) {
        this.$message.warning("直播已结束");
        return;
      }
    },
    LiveStatusChange(e) {
      this.page = 1;
      this.liveEnd = e;
      this.getCourseListEvent(this.SearchType);
    },
    InfoClick() {

      this.SearchType = "userInfo";
      // console.log(store.get());
      this.showSetting = false;
    },
    Search() {
      this.page = 1;
      this.getCourseListEvent(this.SearchType);
    },
    currentChange(page) {
      this.page = page;
      this.getCourseListEvent(this.SearchType);
    },
    nextClick() {
      this.page++;
      this.getCourseListEvent(this.SearchType);
    },
    prevClick() {
      this.page--;
      this.getCourseListEvent(this.SearchType);
    },
    //获取课程列表数据
    getCourseListEvent(type) {
      // console.log(this.host);
      let subData = {
        type: type,
        page: this.page,
        pageSize: this.pageSize,
        liveEnd: this.liveEnd,
        // type: "room",
      };
      if (this.keyword) {
        subData.keyword = this.keyword;
      }
      this.courseLoadText = "加载中...";
      this.courseLoading = true;

      ixunkeAxios(subData, "get", `/api/v1/lesson/live_mission_schedule2`).then(
        (res) => {
          this.userLoading = false;
          this.TodayLiveList = [];
          if (res.errno == 0) {
            this.courseData = res.data;
            this.calculateData = res.data;
            // console.info(this.courseData);
            this.count = res.count;
            this.totalPages = res.totalPages;
            console.log(this.totalPages, '-------------this.totalPages----------');
            this.courseData.forEach((e) => {
              if (e.endTime >= this.timestamp)
                if (e.startTime >= this.zeroTimestamp) {
                  this.TodayLiveList.push(e);
                  // this.$message.success(`今日有${this.TodayLiveList.length}场直播`)
                }
            });
            if (this.TodayLiveList.length !== 0) {
              if (type !== "lesson") {
                this.$notify({
                  title: "直播提示",
                  message: `您今日有${this.TodayLiveList.length}场直播`,
                  duration: 3000,
                  position: "bottom-right",
                  offset: 100,
                });
              } else {
                this.$notify({
                  title: "直播提示",
                  message: `您今日有${this.TodayLiveList.length}节课程`,
                  duration: 3000,
                  position: "bottom-right",
                  offset: 100,
                });
              }
            }
          } else {
            this.$error({
              centered: true,
              title: "课时列表获取失败！",
              content: JSON.stringify(res.errmsg),
              okText: "知道了",
            });
            console.log("课时列表获取失败！");
            store.delete("token");
            store.delete("userInfo");

            this.$router.replace({
              name: "Login",
            });
          }
          setTimeout(() => {
            this.courseLoading = false;
          }, 300);
        }
      );
    },
    //点击继续直播
    goLivePageEvent(params, type) {
      // console.log(type);
      // ipcRenderer.send("test", "test");
      // return
      // ipcRenderer.send("createLiveWindow", `?courseId=${params.courseId}&title=${params.title || params.name}&lesson=${params?.course?.title || params.name}&lessonId=${params.id || params.roomId}&liveType=${type}`);

      // return
      // console.log(params?.isVertical);
      if (params?.isVertical == 1) {
        this.$router.replace({
          name: "Vertica",
          query: {
            courseId: params.courseId,
            title: params.title || params.name,
            lesson: params?.course?.title || params.name,
            lessonId: params.id || params.roomId,
            liveType: type,
          },
        });
      }
      else {


        this.$router.replace({
          name: "pulish",
          query: {
            courseId: params.courseId,
            title: params.title || params.name,
            lesson: params?.course?.title || params.name,
            lessonId: params.id || params.roomId,
            liveType: type,
          },
        });
      }
    },
  },
  watch: {
    SearchType(newVal, oldVal) {
      // console.log(newVal);
      if (newVal == "device" || newVal == "userInfo") {
        return;
      }
      // this.value = 0;
      this.SearchType = newVal;
      this.getCourseListEvent(newVal);
      // console.log(newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep body {
  overflow: hidden;
}

::v-deep .redcolor {
  color: red !important;
  display: inline-block;
}

.yhidden {
  height: 75vh !important;
  overflow-y: scroll;
}

// .mask {
//   width: 100vw;
//   height: 100vh;
//   position: absolute;
//   left: 0;
//   top: 0;
//   background: rgb(0, 0, 0);
//   opacity: 0.3;
//   z-index: 999;
// }
.settings_box {
  position: absolute;
  z-index: 5;
  left: 100px;
  bottom: 10px;
  background: #0a1933;
  color: #fff;
  padding: 16px 12px;
  border-radius: 4px;
  font-size: 12px;
}

#settingIcon {
  width: 30px;
  height: 30px;
}

.quit-box {
  position: absolute;
  left: 40vw;
  top: 42vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 10px;
  width: 400px;
  height: 150px;
  background: #414351;
  // border: 0.5px solid #dcdcdc;
  box-shadow: 0px 6px 30px 5px rgb(0 0 0 / 5%),
    0px 16px 24px 2px rgb(0 0 0 / 4%), 0px 8px 10px -5px rgb(0 0 0 / 8%);
  border-radius: 8px;
  color: #000;
  z-index: 1000;
  color: #ffffff;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .fnc-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .min {
    /* Gray 2 */
    padding: 10px 20px;

    background: #0076ff;
    border-radius: 3px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 30px;
  }

  .quit {
    padding: 10px 20px;
    background: #2e3037;
    color: #999999;
    border-radius: 3px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

// ::v-deep .el-alert__title{
//   letter-spacing: 2px;
//   font-size: 16px;
// }
// .el-alert--success{
//   text-align: left;
// }
.LiveStatus {
  position: absolute;
  left: 1vw;

  // top: 4.75vh;
  span {
    margin-right: 10px;
  }

  .el-select {
    width: 108px;
  }
}

#LiveEnd {
  background: #241818;
}

.lessonName {
  display: flex;
  flex-wrap: wrap;
  height: 46px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.deviceChecK {
  position: absolute;
  bottom: 0;
}

.Navigation_title {
  background: #0a1933;
  border: none;
  margin-bottom: 20px;

  .iconName {
    color: #b7b7b7;
  }

  img {
    user-select: none;
    pointer-events: none;
  }
}

.info_box {
  width: 6vw;
  height: 96vh;
  background: #0a1933;

  .avatar {
    width: 3vw;
    height: 3vw;
    margin-top: 44px;
    margin-left: 1.5vw;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .Home_Navigation {
    display: flex;
    margin-top: 100px;
    margin-left: 23px;
    flex-wrap: wrap;
    width: 3vw;

    svg {
      width: 2.2vw;
    }

    img {
      width: 3vw;
      object-fit: cover;
      margin-bottom: 50px;
    }
  }
}

.ixunke-right-header-search-box {
  position: absolute;
  width: 297px;
  height: 34px;
  background: #ffffff;
  border-radius: 22px;
  // bottom: 13px;
  right: 64px;
  padding: 0 9px 0 31px;
  display: flex;
  align-items: center;
}

.ixunke-right-header-search-icon {
  display: block;
  // position: absolute;
  width: 13px;
  height: 13px;
  font-size: 13px;
  top: 11px;
  left: 9px;
  cursor: pointer;
  z-index: 101;
}

.ixunke-right-header-search-input {
  position: relative;
  display: block;
  width: 100%;
  height: 17px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-indent: 6px;
  color: #201919;
  border: none;
  outline: none;
  z-index: 100;
}

.ixunke-right-header-refresh-icon {
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  font-size: 22px;
  // bottom: 21px;
  right: 25px;
  cursor: pointer;
}

.ixunke-course-title {
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ixunke-course-time {
  position: relative;
  width: 100%;
  height: 17px;
  margin-top: 10px;
  font-size: 10px;
  line-height: 17px;
  text-align: justify;

  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.ixunke-course-info {
  position: relative;
  width: 100%;
  height: 33px;
  margin-top: 10px;
  font-size: 0;
}

.ixunke-course-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 50%;
  vertical-align: middle;
}

.ixunke-course-category {
  display: inline-block;
  width: 87px;
  height: 20px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #666666;
  vertical-align: middle;
}

.ixunke-course-go-on-btn {
  width: 70px;
  height: 30px;
  background: #0076ff;
  border-radius: 9px;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.el-empty {
  position: absolute;
  left: 41%;
  top: 16%;
}
</style>
