<!-- v-show="messageArr.length"  -->
<template>
  <div class="ixunke-chat-box">
    <template >
      <div class="ixunke-chat-content" ref="ixunkeChatContent2" id="ixunkeChatContent1" v-if="isQuestion">
        <template v-for="(item, index) in messageArr">
          <div class="ixunke-chat-item-box 1" :key="index" v-if="item.isQuestion">
            <div class="ixunke-chat-item-title">
              
              <span class="ixunke-user-avatar">
                <img :src="item.avatar" :alt="item.nick" />
               
              </span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ item.nick }}</span>
                </template>
                <span class="ixunke-user-name 1">{{ item.nick.replace(/^([\w\W])[\w\W]*([\w\W])$/, '$1****$2') }}</span>
              </a-tooltip>
              <span class="ixunke-user-time">{{ moment(item.time * 1000).format('YYYY/MM/DD HH:mm') }}</span>
            </div>
            <messageText v-if="item.type == 'text' && item.isQuestion" :payload="item.payload" :isQuestion="isQuestion" />
          </div>
        </template>
      </div>
      <div class="ixunke-chat-content" id="ixunkeChatContent2" ref="ixunkeChatContent2" @scroll="scrollEvent" v-else>
        <template v-for="(item, index) in messageArr">
          <!-- 群提示消息 -->
          <div class="ixunke-chat-item-box 2" :key="item.ID+index" style="text-align: center" v-if="item.type == 'groupTip'">
            <div class="ixunke-notice-time" v-if="item.time">{{ moment(item.time * 1000).format('YYYY/MM/DD HH:mm') }}</div>
            <span class="ixunke-notice">{{ item.payload.text }}</span>
          </div>
          <!-- 撤回的消息 -->
          <!-- :key="item.ID" -->
          <div class="ixunke-chat-item-box 3"  style="text-align: center" v-else-if="item.isRevoked">
            <div class="ixunke-notice-time">{{ moment(item.time * 1000).format('YYYY/MM/DD HH:mm') }}</div>
            <template v-if="item._revoker">
              <span class="ixunke-notice" v-if="item.from == userId">{{ item._revoker }}撤回了你的消息</span>
              <span class="ixunke-notice" v-else>{{ item._revoker }}撤回了{{ item.nick || item.from }}的消息</span>
            </template>
            <template v-else-if="item.from == userId">
              <span class="ixunke-notice">你撤回了一条消息</span>
            </template>
            <template v-else>
              <span class="ixunke-notice">{{ item.nick || item.from }}撤回了一条消息</span>
            </template>
          </div>
          <!-- 群人员发送的消息 -->
          <div class="ixunke-chat-item-box 4" :key="item.ID+index" v-if="item.type!== 'stop_push' &&item.type!== 'start_push'">
            <div class="ixunke-chat-item-title" style="text-align: left" v-if="item.from !== userId">              
              <a-space>
                <a-tooltip overlayClassName="ixunke-tooltip" placement="right" trigger="oncontextmenu" :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body
                  }
                ">
                  <template slot="title">
                    <!-- <div class="tip-action-box" @click="shutupEvent(item, 5 * 60, index)">禁言5分钟</div>
                    <div class="tip-action-box" @click="shutupEvent(item, 60 * 60, index)">禁言1小时</div>
                    <div class="tip-action-zbox" @click="shutupEvent(item, 24 * 60 * 60, index)">禁言24小时</div>
                    <div class="tip-action-box" @click="shutupEvent(item, 0, index)">取消禁言</div> -->
                    <div class="tip-action-box" @click="send101(item, 0, index)">邀请连麦</div>
                  </template>
                  <span class="ixunke-user-avatar">
                   
                    <a-avatar :size="22" icon="user" :src="item.avatar" />
                  </span>
                </a-tooltip>
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{ item.nick }}</span>
                  </template>
                  <span class="ixunke-user-name 2" >{{ item.nick }}</span>
                </a-tooltip>

                <span class="ixunke-user-nameCard" v-if="item.nameCard">{{ item.nameCard }}</span>
              </a-space>
            </div>
            <div class="ixunke-chat-item-title" style="text-align: right"  v-if="item.from == userId ">
              <a-space >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{ item.nick }}</span>
                  </template>
                  <span class="ixunke-user-name 3">{{ item.nick }}</span>
                </a-tooltip>
                <span class="ixunke-user-nameCard" v-if="item.nameCard">{{ item.nameCard }}</span>
           
              </a-space>
            </div>

            <!-- 文本消息 -->
            <messageText v-if="item.type == 'text'" :payload="item.payload"
              :textRight="item.from == userId ? true : false" @messageAction="
                (param) => {
                  item.action = param
                  messageAction(item)
                }
              " />
            <!-- 图片消息 -->
            <messageImage v-else-if="item.type == 'image'" 
            class="11115"
            :key="item.ID+index" :payload="JSON.parse(item.payload.data)"
              :textRight="item.from == userId ? true : false" @messageAction="
                (param) => {
                  item.action = param
                  messageAction(item)
                }
              " />
            <!-- 商品消息 -->
            <messageProduct v-else-if="item.type == 'product'" :key="item?.ID+index" :payload="item.payload"
              :textRight="item.from == userId ? true : false" @messageAction="
                (param) => {
                  item.action = param
                  messageAction(item)
                }
              " />
            <!-- 问卷消息 -->
            <messageQuestionnaire v-else-if="item.type == 'questionnaire'" :key="item?.ID" :payload="item.payload"
              :textRight="item.from == userId ? true : false" type="message" @messageAction="
                (param) => {
                  item.action = param
                  messageAction(item)
                }
              " />
            <!-- 投票消息 -->
            <messageVote v-else-if="item.type == 'vote'" :key="item?.ID+index" :payload="item.payload"
              :textRight="item.from == userId ? true : false" type="message" @messageAction="
                (param) => {
                  item.action = param
                  messageAction(item)
                }
              " />
            <!-- 福袋消息 -->
            <messageFudai v-else-if="item.type == 'fudai'" :key="item?.ID" :payload="item.payload"
              :textRight="item.from == userId ? true : false" type="message" @messageAction="
                (param) => {
                  item.action = param
                  messageAction(item)
                }
              " />
          </div>
        </template>
      </div>
    </template>
    <div v-if="chatTip.type" class="iuxnke-message-tip-box"
      :class="chatTipShow ? 'iuxnke-message-tip-box-fadein' : 'iuxnke-message-tip-box-fadeout'">
      <span class="ixunke-notice">
        <a-icon type="check-circle" theme="filled" style="color: #52c41a"
          :style="{ backgroundColor: chatTip.type ? '#dfdfdf' : '#6a6d75' }" v-if="chatTip.type == 'success'" />
        <a-icon type="close-circle" theme="filled" style="color: #f5222d"
          :style="{ backgroundColor: chatTip.type ? '#dfdfdf' : '#6a6d75' }" v-if="chatTip.type == 'error'" />
        <a-icon type="exclamation-circle" theme="filled" style="color: #faad14"
          :style="{ backgroundColor: chatTip.type ? '#dfdfdf' : '#6a6d75' }" v-if="chatTip.type == 'warning'" />
        &nbsp;{{ chatTip.content }}
      </span>
    </div>
    <div class="bottomTipsCount" v-if="bottomTipsCount>0" @click="tobottom()">
      {{ bottomTipsCount }}条新消息
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import messageText from './message-text.vue'
import messageImage from './message-image.vue'
import messageProduct from './message-product.vue'
import messageQuestionnaire from './message-questionnaire.vue'
import messageVote from './message-vote.vue'
import messageFudai from './message-fudai.vue'
import { ixunkeAxios } from "@/assets/js/axios.js";

export default {
  name: 'messageList',
  inject: ['lianmai'],
  props: ['list', 'type', 'userId', 'isMaster', 'isQuestion','messageArrLength'],
  components: {
    messageText,
    messageImage,
    messageProduct,
    messageQuestionnaire,
    messageVote,
    messageFudai,
  },
  data() {
    return {
      //聊天室提示
      chatTipShow: false,
      chatTip: {
        type: '',
        content: '',
      },
      scrollEventDebounced:null,
      bottomTipsCount:0,
      count:0,

      oldHeight:0,
      newHeight:0,
    }
  },
  computed: {
    tipArr() {
      if (!this.list) {
        return []
      }
      let arr = []
      this.list.forEach((item) => {
        arr.push(false)
      })
      return arr
    },
    messageArr() {
      if (!this.list ||this.list.length==0) {
        return []
      }
      // console.log(this.list);
      // console.log('----------------------------');
      let arr = []
      
      this.list.forEach((item) => {
        // console.log(item);
       
        const JSONData  = JSON.parse(item?.payload?.data||item?.message)
        // console.log(JSONData);
        const MessageType = item?.payload?.type ||JSONData.type
        // 判断是否为商品
        if(JSONData.type=="send-goods"){
     
          return
        }
        if(JSONData.type=="lianmai"){
         
          return
        }
        if (MessageType !== 'openfudai') {
          //  console.log(item.type,'item.type');
          if (item.type == 'TIMTextElem') {
           
            arr.push(
              Object.assign({}, item, {
                type: 'text',
              })
            )
          } else if (item.type == 'TIMImageElem') {
            let url = ''
            try {
               url = item.payload.imageInfoArray[0].imageUrl

            } catch (error) {
               url = JSON.parse(item.payload.data).imageInfoArray[0].imageUrl
            }
            if (typeof url !== 'string') {
              url = ''
            } else if (url.slice(0, 2) === '//') {
              url = `https:${url}`
            }
            arr.push(
              Object.assign({}, item, {
                type: 'image',
                payload: Object.assign(item.payload, {
                  imageUrl: url,
                }),
              })
            )
          } else if (item.type == 'TIMCustomElem') {
            try {
              let content = JSON.parse(item.payload.data||item?.message)
              
              arr.push(
                Object.assign({}, item, {
                  type: content.type,
                  payload: content,
                })
              )
            } catch (e) {
              arr.push({ type: 'unknow' })
            }
          } else if(item.type=='send-goods'){
            // console.log(item.type);
          } 
          else{
            // console.log(item.type);
            arr.push(item)
          }
        }else{
        }
      })
      // console.log(arr);
      // console.log('获取到消息',this.list.length);
      // arr = lodash.uniqBy(arr, 'id')
      // console.log(arr);
      return arr
    },
    images() {
      let arr = []
      this.messageArr.forEach((item) => {
        if (item.type == 'image') {
          arr.push(item.payload.imageUrl)
        }
      })
      return arr
    },
  },
  mounted() {
    // console.log(this.lianmai.state);
    this.bus.$off('chatTipEvent').$on('chatTipEvent', (params) => {
      this.chatTipShow = true
      this.chatTip = params
      setTimeout(() => {
        this.chatTipShow = false
        setTimeout(() => {
          this.chatTip = {
            type: '',
            content: '',
          }
        }, 300)
      }, 2000)
    })

    setTimeout(() => {
      this.bottomTipsCount = 0;

      const chatContent = this.$refs.ixunkeChatContent2;
      chatContent.scrollTop = chatContent.scrollHeight;
      // console.log('加载完成滑动到底部');
      this.count = this.messageArr.length;
    }, 100);

    
  },
  methods: {
    moment,
    tobottom(){
      this.bottomTipsCount=0;
      // this.count = this.list.length;
      // this.messageArrLength = this.list.length;
      // console.log(this.count);
      this.$nextTick(()=>{
        const chatContent = this.$refs.ixunkeChatContent2;
        chatContent.scrollTop = chatContent.scrollHeight;
        // console.log('滑动到底部!!!');
      })
       
      
    },
    async getSign(){
      this.ctime = moment.utc().add(60, "s").unix() + "";
      await ixunkeAxios(
        { room_id: this.roomId, user_id: "3", ctime: this.ctime },
        "post",
        "/api/v1/channel/rtc_sign"
      ).then((res) => {
        this.signature= res?.data?.signature
      });
      
      

    },
    async send101(params, time, index){
      console.log(params);
      this.roomId = store.get('roomId')
      // await this.getSign()
      var teacherId =  store.get('identifier');

      var msg = { type: "lianmai", data: {
        action:101,
        studentId:params.from,
        teacherId:teacherId,
        // signature:this.signature,
        // roomId:this.roomId,
        // ctime:this.ctime,
      }}
      console.log(msg);
      this.$emit("sendMessageEvent", msg);
      this.$notify({
        title: '邀请连麦',
        message: `正在邀请${params.nick||'用户'}连麦`,
        offset: 10,
        duration:3000,
        position: 'bottom-right'
      });
      this.tipArr[index] = false
    }, 
    scrollX(){
      let scrollHeight = this.$refs.ixunkeChatContent2.scrollHeight;
      this.newHeight = scrollHeight;
      // console.log(this.newHeight);
      // if(this.oldHeight==0){
      //   this.tobottom()
      //   return
      // }
      this.$refs.ixunkeChatContent2.scrollTop = (this.newHeight-this.oldHeight);
      this.oldHeight = this.newHeight
    }, 
    chatContentScrollBottomEvent(x) {
        let scrollHeight = this.$refs.ixunkeChatContent2.scrollHeight;
        let clientHeight = this.$refs.ixunkeChatContent2.clientHeight;
        let scrollTop = this.$refs.ixunkeChatContent2.scrollTop;
        this.oldHeight = scrollHeight;
        if(scrollTop+clientHeight >=scrollHeight){
          // console.log('在最底部,无需++');
          this.tobottom()
        }else{
          this.bottomTipsCount+=1;
        }
        
     
      // let box1 = document.getElementById('ixunkeChatContent1')
      // console.log(box1,'----1----');
      // let box2 = document.getElementById('ixunkeChatContent2')
      // console.log(box2);
      // if(box1){
      //   box1.scrollTop = box1.scrollHeight;
      //   return
      // }else{
      //   box1.scrollTop = 0

      // }

      //     box2.scrollTop = box2.scrollHeight
      // setTimeout(() => {
      //   if (this.isQuestion) {
      //     let box1 = document.getElementById('ixunkeChatContent1')
      //     box1.scrollTop = box1.scrollHeight
      //   } else {
      //     let box2 = document.getElementById('ixunkeChatContent2')
      //     box2.scrollTop = box2.scrollHeight
      //   }
      // }, 300)
    },
    scrollEvent() {
      if (this.$refs.ixunkeChatContent2.scrollTop == 0) {
        this.bus.$emit('reshMessageListEvent', 'add')
      }
      // console.log(this.$refs.ixunkeChatContent2.clientHeight );
      // console.log(this.$refs.ixunkeChatContent2.scrollTop);
      // console.log(this.$refs.ixunkeChatContent2.scrollHeight);
    },
    
    shutupEvent(params, time, index) {
      params.action = 'shutup'
      params.muteTime = time
      this.$emit('messageAction', params)
      this.tipArr[index] = false
    },
    messageAction(params) {
      this.$emit('messageAction', params)
    },
  },
  created(){

  },
  beforeDestroy(){
    // console.log('销毁list-------------------------');
    this.$refs.ixunkeChatContent2 = null;
    
  },
}
</script>
<style lang="css" scoped>
.bottomTipsCount{
  color: #2468ff;
  position: absolute;
  /* bottom: 1px; */
  z-index: 9999;
  bottom: 10px;
  left: 100px;

}
.buy-goods-box{
  background: #f13636;
  color: #fff;
  padding: 4px 0;
  border-radius: 7px;
  width: 80%;
  margin: 0 auto;
}
.update-goods-box{
  color: #e9e9e9;
}
.ixunke-chat-box {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.ixunke-chat-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  cursor: pointer;
}

.ixunke-chat-content::-webkit-scrollbar {
  display: none;
}

.ixunke-chat-item-box {
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px;
  /* margin-top: 10px; */
}

.ixunke-chat-item-title {
  position: relative;
  width: 100%;
  height: 22px;
  font-size: 0;
}

.ixunke-user-avatar {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  vertical-align: middle;
  overflow: hidden;
}

.ixunke-user-avatar>img {
  width: 100%;
  height: auto;
}

.ixunke-user-name {
  position: relative;
  display: inline-block;
  max-width: 80px;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #9e9e9e;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.ixunke-user-nameCard {
  position: relative;
  display: inline-block;
  height: 16px;
  font-size: 12px;
  background-color: #1890ff;
  color: #fff;
  border-radius: 10px;
  padding: 0 6px;
  line-height: 16px;
}

.ixunke-user-time {
  position: relative;
  display: inline-block;
  width: 106px;
  height: 17px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #999;
  vertical-align: middle;
}

.ixunke-notice-time {
  position: relative;
  width: 100%;
  height: 17px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #999;
}

.ixunke-user-banned {
  position: absolute;
  display: inline-block;
  width: 35px;
  height: 20px;
  background: #333333;
  border: 1px solid #3d629f;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #d2d2d2;
  top: -20px;
  left: -6px;
  cursor: pointer;
}

.ixunke-notice {
  display: inline-block;
  width: auto;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #9e9e9e;
  padding: 0 10px;
  background: #9e9e9e38;
  border-radius: 13px;
}

.iuxnke-message-tip-box {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 12px;
  z-index: 99999;
  text-align: center;
  opacity: 0;
}

.iuxnke-message-tip-box-fadein {
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  -ms-transition: all 1.5s;
  -o-transition: all 1.5s;
  transition: all 1.5s;
  opacity: 1;
}

.iuxnke-message-tip-box-fadeout {
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  -ms-transition: all 1.5s;
  -o-transition: all 1.5s;
  transition: all 1.5s;
  opacity: 0;
}

.tip-action-box {
  line-height: 32px;
  padding: 0 6px;
}

.tip-action-box:hover {
  background: #1890ff;
  color: #fff;
}
</style>
