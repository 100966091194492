<template>
  <a-modal
    wrapClassName="ixunke-no-header-border-bottom-modal"
    v-model="modalVisible"
    :bodyStyle="{ padding: '16px 40px 37px' }"
    :destroyOnClose="true"
    :footer="null"
    @cancel="() => setModalVisible(false)"
  >
    <a-spin size="large" :tip="loadingText" :spinning="loading">
      <img alt style="width: 100%" :src="previewContent" v-if="previewType=='image'" />
      <span v-else-if="previewType=='text'">
        <span v-for="(item,index) in JSON.parse(previewContent)" :key="index">{{item.text}}</span>
      </span>
    </a-spin>
  </a-modal>
</template>
<script>
export default {
  name: "messagePreview",
  props: {
    previewModal: {
      type: Boolean,
      default: false,
    },
    previewType: {
      type: String,
      default: "",
    },
    previewContent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalVisible: false,
      modalTitle: "",
      loadingText: "加载中...",
      loading: true,
      audioUrl: "",
    };
  },
  watch: {
    previewModal: function (val) {
      if (val) {
        this.modalVisible = val;
        this.loadingText = "加载中...";
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
    modalVisible: function (val) {
      this.$emit("update:previewModal", val);
    },
  },
  mounted() {},
  methods: {
    //关闭modal
    setModalVisible(visible) {
      this.modalVisible = visible;
    },
  },
};
</script>
