import Vue from 'vue'
import { HOST } from './../mutation-types'
const config = {
    state: {
        //域名
        host: '',
        //roomId
        roomId: '',
        //直播状态
        status: 0,  //0: 未开始；1：直播中； 2：禁止开始；
        //直播清晰度
        definition: 540,
        //推流设备状态
        publicType: '',
        voice: false,
        camera: true,
        cameraId: '',
        mic: true,
        screen: true,
        canvas: false,
    },
    mutations: {
        SET_HOST: (state, host) => {
            state.host = host
        },
        SET_ROOM_ID: (state, roomId) => {
            state.roomId = roomId
        },
        SET_STATUS: (state, status) => {
            state.status = status
        },
        SET_DEFINITION: (state, definition) => {
            state.definition = definition
        },
        SET_PUBLIC_TYPE: (state, publicType) => {
            state.publicType = publicType
        },
        SET_VOICE: (state, voice) => {
            state.voice = voice
        },
        SET_CAMERA: (state, camera) => {
            state.camera = camera
        },
        SET_CAMERA_ID: (state, cameraId) => {
            state.cameraId = cameraId
        },
        SET_MIC: (state, mic) => {
            state.mic = mic
        },
        SET_SCREEN: (state, screen) => {
            state.screen = screen
        },
        SET_CANVAS: (state, canvas) => {
            state.canvas = canvas
        },
    },

    actions: {
        //存储host
        setHost({ commit }, data) {
            return new Promise((resolve, reject) => {
                Vue.ls.set(HOST, data)
                commit('SET_HOST', data)
                resolve(data)
            })
        },
        //roomID 
        setRoomId({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_ROOM_ID', data)
                resolve(data)
            })
        },
        //设置直播状态
        setStatus({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_STATUS', data)
                resolve(data)
            })
        },
        //设置直播清晰度
        setDefinition({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_DEFINITION', data)
                resolve(data)
            })
        },
        //设置推流方式
        setPublicType({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_PUBLIC_TYPE', data)
                resolve(data)
            })
        },
        //扬声器使用状态
        setVoice({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_VOICE', data)
                resolve(data)
            })
        },
        //摄像头使用状态
        setCamera({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_CAMERA', data)
                resolve(data)
            })
        },
        //摄像头Id
        setCameraId({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_CAMERA_ID', data)
                resolve(data)
            })
        },
        //麦克风使用状态
        setMic({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_MIC', data)
                resolve(data)
            })
        },
        //屏幕分享使用状态
        setScreen({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_SCREEN', data)
                resolve(data)
            })
        },
        //canvas使用状态
        setCanvas({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_CANVAS', data)
                resolve(data)
            })
        },
        //初始化
        initConfig({ commit }) {
            return new Promise((resolve, reject) => {
                commit('SET_STATUS', 0)
                commit('SET_DEFINITION', 540)
                commit('SET_PUBLIC_TYPE', '')
                commit('SET_VOICE', false)
                commit('SET_CAMERA', true)
                commit('SET_CAMERA_ID', '')
                commit('SET_MIC', true)
                commit('SET_SCREEN', false)
                commit('SET_CANVAS', false)
                resolve()
            })
        },
    }
}

export default config