let gifUrlprefix = 'http://cdn.ixunke.cn/emoji/';
let emojis = [
	{
		text: '赞',
		index: 0,
		gif: gifUrlprefix + 'zan.gif'
	},
	{
		text: '好',
		index: 1,
		gif: gifUrlprefix + 'hao.gif'
	},
	{
		text: 'OK',
		index: 2,
		gif: gifUrlprefix + 'ok.gif'
	},
	{
		text: '666',
		index: 3,
		gif: gifUrlprefix + '666.gif'
	},
	{
		text: '棒',
		index: 4,
		gif: gifUrlprefix + 'bang.gif'
	},
	{
		text: '谢谢',
		index: 5,
		gif: gifUrlprefix + 'xiexie.gif'
	},
	{
		text: '比心',
		index: 6,
		gif: gifUrlprefix + 'bixin.gif'
	},
	{
		text: '拜拜',
		index: 7,
		gif: gifUrlprefix + 'bye.gif'
	},
	{
		text: '辛苦了',
		index: 8,
		gif: gifUrlprefix + 'xinkule.gif'
	},
	{
		text: '老师好',
		index: 9,
		gif: gifUrlprefix + 'laoshihao.gif'
	},
	{
		text: '努力中',
		index: 10,
		gif: gifUrlprefix + 'nulizhong.gif'
	},
	{
		text: '好好学习',
		index: 11,
		gif: gifUrlprefix + 'haohaoxuexi.gif'
	},
	{
		text: 'A',
		index: 12,
		gif: gifUrlprefix + 'A.gif'
	},
	{
		text: 'B',
		index: 13,
		gif: gifUrlprefix + 'B.gif'
	},
	{
		text: 'C',
		index: 14,
		gif: gifUrlprefix + 'C.gif'
	},
	{
		text: 'D',
		index: 15,
		gif: gifUrlprefix + 'D.gif'
	},
	{
		text: 'E',
		index: 16,
		gif: gifUrlprefix + 'E.gif'
	},
	{
		text: '0',
		index: 17,
		gif: gifUrlprefix + '0.gif'
	},
	{
		text: '1',
		index: 18,
		gif: gifUrlprefix + '1.gif'
	},
	{
		text: '2',
		index: 19,
		gif: gifUrlprefix + '2.gif'
	},
	{
		text: '3',
		index: 20,
		gif: gifUrlprefix + '3.gif'
	},
	{
		text: '4',
		index: 21,
		gif: gifUrlprefix + '4.gif'
	},
	{
		text: '5',
		index: 22,
		gif: gifUrlprefix + '5.gif'
	}
]

export default emojis