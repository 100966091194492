<template>
	<div class="coupon-item" :class="item.goodsType">
		<!-- :class="[item.goodsType, {'card':card, 'white':(card||white), 'have':item.isHave}, 'state'+item.useState]" -->
		<div class="coupon-item-wrap">
			<div class="coupon-item-shadow"></div>
			<div class="coupon-item-badge" v-if="item.type">
				<div class="text">{{item.type | typeFormat}}</div>
			</div>
			
			<div class="coupon-item-content">
				<div class="coupon-item-content-left">
					<div class="top">
						<span class="iconfont xk-youhuiquan-fill"></span>
						<span v-if="item.getType" class="coupon-source">来源:{{getType(item.getType)}}</span>
						<span class="line-1">{{item.name}}</span>
					</div>
					<div class="middle">
						<span class="unit">{{'￥'}}</span>
						<span class="num">{{item.amount}}</span>
						<span class="text">{{item.note}}</span>
					</div>
					<div class="bottom">
						<template v-if="item.thresholdAmount">满{{item.thresholdAmount}}减{{item.amount}}</template>
						<template v-else><span>无门槛 下单立减</span></template>
					</div>
				</div>
				<div class="coupon-item-content-right">
					<template v-if="item.isHave && item.deadlineTime">
						<div class="time-text">有效期至</div>
						<div class="time">{{item.deadlineTime | dateFormat('yyyy-MM-dd')}}</div>
					</template>
					<template v-else-if="item.deadlineType == 'time'">
						<div class="time-text">有效期至</div>
						<div class="time">{{item.deadlineTime | dateFormat('yyyy-MM-dd')}}</div>
					</template>
					<template v-else-if="item.deadlineType == 'expiryDay'">
						<div class="time-text">自领取后</div>
						<div class="time">{{item.expiryDay}}天内有效</div>
					</template>
					<div class="button" hover-class="hover" @click="selectCoupon(item)">
						<!-- {{buttonText}} -->
						选择
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dateFormat
	} from '../unijs/format.js'
	import {
		currentPage
	} from '../unijs/public.js'
	export default {
		props: ['item', 'card', 'white', 'fromIm', 'fromShare'],
		computed: {
			buttonText() {
				if (this.fromIm) {
					return '选择';
				}
				if (this.fromShare) {
					return '立即分享';
				}
				if (this.item.isHave) {
					switch (this.item.useState) {
						case 1:
							return '立即使用';
							break;
						case 2:
							return '暂时锁定';
							break;
						case 3:
							return '已使用';
							break;
						case 4:
							return '已过期';
							break;
						case 5:
							return '已作废';
							break;
						default:
							return '立即使用';
							break;
					}
				} else {
					if (this.item.point) {
						return this.item.point + '积分兑换'
					}
					if (this.item.buyPrice) {
						return this.BI + this.item.buyPrice + '购买'
					}
					return '立即领取';
				}
			}
		},
		filters: {
			dateFormat(time, format) {
				return dateFormat(time, format);
			},
			typeFormat(type) {
				switch (type) {
					case 'goods':
						return '专属';
						break;
					case 'common':
						return '通用';
						break;
					default:
						return '';
						break;
				}
			}
		},
		methods: {
			getType(type){
				if(!type){
					return '';
				}
				switch (type){
					case 1:
						return '系统发放'
						break;
					case 2:
						return '免费领取'
						break;
					case 3:
						return '积分兑换'
						break;
					case 4:
						return '购买获得'
						break;
					case 5:
						return '会员赠送'
						break;
					case 6:
						return '福袋中奖'
						break;
					case 7:
						return '支付有礼'
						break;
					default:
						return '其他渠道'
						break;
				}
			},
			selectCoupon(item) {
			
				this.$emit('sendCouponEvent',item)
				return
				if (this.fromIm) {
					let data = {
						productType: 'coupon',
						id: item.id,
						amount: item.amount,
						thresholdAmount: item.thresholdAmount,
						name: item.name,
						note: item.note
					}
					this.$api.sendEvent({
						name: 'selectProductForIM',
						extra: data
					})
					uni.navigateBack();
					return;
				}
				
				if(this.fromShare){
					uni.setStorageSync('resellerShareProInfo', {
						title: '优惠券：' + item.name
					})
					uni.navigateTo({
						url: `/pages/reseller/card?type=coupon&goodsId=${item.flakeId}`
					})
					return;
				}
				
				if (item.isHave) {
					this.useCoupon(item);
				} else {
					if (item.buyPrice) {
						let params = {
							type: 'coupon',
							id: item.id
						}
						this.$api.openWin({
							url: '/pages/buy/index',
							params: params
						})
						return
					}
					this.takeCoupon(item);
				}
			},
			takeCoupon(item) {
				// _http({
				// 	method: 'POST',
				// 	path: '/api/coupon/take_coupon',
				// 	params: {
				// 		couponId: item.id
				// 	},
				// 	checkToken: 1
				// }).then((res) => {
				// 	if (res.errno === 0) {
				// 		item.isHave = 1;
				// 		this.$emit("takeCoupon")
				// 		this.$api.showModal({
				// 			title: '领取成功',
				// 			content: '已成功领取优惠券，是否前往使用？',
				// 			success: (res) => {
				// 				if (res.confirm) {
				// 					this.useCoupon(item);
				// 				}
				// 			}
				// 		})
				// 	} else if (res.errno === 100143) {
				// 		let cPage = currentPage();
				// 		if (cPage) {
				// 			if (cPage.login) {
				// 				cPage.login()
				// 			} else if (cPage.goLogin) {
				// 				cPage.goLogin()
				// 			}
				// 		}
				// 	} else if (res.errmsg === '代金券已领取') {
				// 		item.isHave = 1;
				// 		this.$api.showModal({
				// 			title: '领取成功',
				// 			content: '您已领取过该优惠券，是否前往使用？',
				// 			success: (res) => {
				// 				if (res.confirm) {
				// 					this.useCoupon(item);
				// 				}
				// 			}
				// 		})
				// 	} else {
				// 		this.$api.showModal({
				// 			title: '出错啦！',
				// 			content: `错误码:${res.errno}，${res.errmsg}`,
				// 			showCancel: false,
				// 			confirmText: '知道了',
				// 			confirmColor: '#333'
				// 		})
				// 	}
				// })
			},
			useCoupon(item) {
				// useState 1或者不存在都放行
				if (this.item.useState == 2) {
					this.$api.showModal({
						title: '锁定原因',
						content: '您有未完成的订单使用了该优惠券。如订单关闭或取消，优惠券可恢复使用',
						cancelText: '我知道了',
						confirmText: '查看订单',
						success: (res) => {
							if (res.confirm) {
								// this.$api.openWin({
								// 	url: '/pages/user/order/index',
								// 	params: {
								// 		userCouponId: this.item.id
								// 	}
								// })
								
								// 没有订单id，改为跳到全部订单页
								
								this.$api.openWin({
									url: '/pages/user/order',
									params: {
										state: 0
									}
								})
							}
						}
					})
					return;
				} else if (this.item.useState == 3 || this.item.useState == 4) {
					return;
				}

				let params = {};
				if (item.type == 'common') {
					return this.$api.toast('通用优惠券，您在购买任何资源时都可使用');
				} else if (item.type == 'goods') {
					this.$api.openWin({
						url: '/pages/sale/coupon',
						params: {
							id: item.gId || item.id,
							product: 1
						}
					})
					return;
				} else if (item.type == 'forgoods') {
					switch (item.goodsType) {
						case 'questions':
						case 'course':
						case 'resource':
							params.type = item.goodsType;
							params.id = item.goodsId;
							break;
						default:
							break;
					}
					if (!params.type) {
						return this.$api.toast('优惠券关联商品类型无效');
					}
					this.$api.openWin({
						url: '/pages/buy/index',
						params: params
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.coupon-item {
		position: relative;
		margin-bottom: 14px;
		width: 100%;

		&:last-child {
			/* #ifndef MP */
			margin-bottom: 0;
			/* #endif */
		}
		
		.coupon-item-wrap{
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 30%;
			background-image: url(https://cdn.ixunke.cn/v3/app/imgcdn/coupon/coupon.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-color: #50AED4;
		}

		.coupon-item-shadow {
			position: absolute;
			top: 0;
			right: 90px;
			width: 124px;
			height: 1px;
			transform: translateX(100%) rotate(45deg);
			transform-origin: 0 0;
			z-index: 1;
			box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .16);

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 1px;
				width: 90px;
				transform: rotate(-45deg);
				transform-origin: 0 0;
				box-shadow: 0 -1px 20px 1px rgba(0, 0, 0, .16);
			}
		}

		.coupon-item-badge {
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border: 30px solid rgba(255, 255, 255, 1);
			border-left-color: transparent;
			border-bottom-color: transparent;
			z-index: 10;

			.text {
				position: absolute;
				left: 0;
				top: 0;
				text-align: center;
				font-size: 14px;
				color: #50AED4;
				white-space: nowrap;
				transform: rotate(45deg) translate(-50%, -100%);
				transform-origin: 0 0;
				line-height: 1;
				padding-bottom: 3px;
				font-weight: bold;
			}
		}

		.coupon-item-ball {
			position: absolute;
			top: 8px;
			bottom: 8px;
			width: 14px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			z-index: 20;

			&.before {
				left: 0;
				transform: translateX(-50%);
			}

			&.after {
				right: 0;
				transform: translateX(50%);
			}
		}

		.coupon-item-line {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 1px;
			right: 30%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			z-index: 20;

			.item {
				width: 100%;
				height: 4%;
				background-color: rgba(255, 255, 255, .35);

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.coupon-item-content {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 50;
			display: flex;

			.coupon-item-content-left {
				padding: 5px 14px 7px;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.top {
					font-size: 12px;
					color: #fff;
					height: 33px;
					display: flex;
					align-items: center;
					line-height: 1;
					
					.coupon-source{
						flex-shrink: 0;
						margin-right: 4px;
					}

					.iconfont {
						width: 20px;
						font-size: 12px;
					}
				}

				.middle {
					padding-left: 10px;
					color: #fff;
					font-family: PingFang SC;

					text {
						vertical-align: middle;
					}

					.unit {
						width: 43px;
						font-size: 22px;
						font-weight: bold;
					}

					.num {
						font-size: 32px;
						font-weight: bold;
					}

					.text {
						margin-left: 11px;
						font-size: 14px;
						font-weight: bold;
					}
				}

				.bottom {
					font-size: 14px;
					font-weight: bold;
					color: #fff;
				}
			}

			.coupon-item-content-right {
				flex-shrink: 0;
				width: 40%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				padding-bottom: 8px;
				.time-text {
					font-size: 12px;
					color: #fff;
					// line-height: 33px;
				}

				.time {
					font-size: 12px;
					color: #fff;
					// line-height: 33px;
				}

				.button {
					margin-top: 8px;
					max-width: 75px;
					min-width: 68px;
					height: 24px;
					border-radius: 3px;
					background-color: #fff;
					color: #666;
					font-size: 12px;
					display: flex;
					justify-content: center;
					align-items: center;
					line-height: 1;
					padding: 0 3px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					&.hover {
						opacity: .9;
					}
				}
			}
		}

		&.goods {
			.coupon-item-wrap{
				background-color: #D95D79;
			}
			.coupon-item-badge .text {
				color: #D95D79;
			}
		}

		&.have {
			// 待使用
			.coupon-item-content-right .button {
				font-weight: bold;
			}

			&.state1 {}

			&.state2 {
				opacity: .8;
			}

			&.state3 {
				opacity: .7;
			}

			&.state4 {
				opacity: .7;
			}
		}

	}

	
		.coupon-item {
			width: calc(33.33% - 7px);
			
			&:nth-last-child(3){
				/* #ifndef MP */
				margin-bottom: 0;
				/* #endif */
			}

			&:nth-child(2n+1) {
				margin-right: 0;
			}

			&:nth-child(3n+1) {
				margin-right: 10px;
			}

			&:nth-child(3n+2) {
				margin-right: 10px;
			}
		}
	// }
</style>
