import Vue from 'vue'
import { axios } from './../../utils/request'
// import store from './../../store'
import { TOKEN } from './../../store/mutation-types'
import { base64Encode } from './../../utils/base64'
import { AES } from './../../utils/aes.js'
import router from './../../router'

const Store = window.require('electron-store');
const store = new Store();
// console.log(store)
var HOST = store.get('host')

//登录
export async function loginEvent(subData, url) {
    let result = { errno: 0, errmsg: '' }
    subData.app = true
    await axios({
        url: url,
        method: 'post',
        data: subData
    }).then(response => {
        result = response
    }).catch(error => {
        result = { errno: 1008, errmsg: error }
    })
    return result
}


//分装好的请求函数               form表单提交 form=true  subData= new FormData()
export async function ixunkeAxios(subData, type, url, form) {
   
    let result = { errno: 0, errmsg: '' }
    var token = store.get('token')
    // console.log(token,'----token-----');
    HOST = store.get('host')
    // console.log(HOST,'---------HOST-------');
    //form表单提交
    if (form) {
        if (JSON.stringify(token) == 'null') {
            router.push({ path: '/login' })
            return { errno: 100143, errmsg: '没有登录' }
        }
        let key = 'ixunke';
        let baseToken = base64Encode(AES.encrypt(token + '#' + new Date().getTime(), key).toString() + '#' + new Date().getTime())
        subData.append("app", true)
        // subData.append("token", baseToken)
        subData.append("token", baseToken)
        url = HOST + url
    }
    //非form表单请求
    if (!form) {
        if (JSON.stringify(token) == 'null') {
            router.push({ path: '/login' })
            return { errno: 100143, errmsg: '没有登录' }
        }
        url = HOST + url
        let key = 'ixunke';
        let baseToken = base64Encode(AES.encrypt(token + '#' + new Date().getTime(), key).toString() + '#' + new Date().getTime())
        if (type == "get" || type == "GET" || type == "delete" || type == "DELETE") {
            if (url.indexOf("?") == -1) {
                url = url + "?app=true&token=" + baseToken
            } else {
                url = url + "&app=true&token=" + baseToken
            }
        } else {
            subData.app = true
            subData.token = baseToken
        }
    }
    if (type == "get" || type == "GET" || type == "delete" || type == "DELETE") {
        Object.keys(subData).map(function (v) {
            if (url.indexOf("?") == -1) {
                url = url + "?" + v + "=" + subData[v]
            } else {
                url = url + "&" + v + "=" + subData[v]
            }
        })
        await axios({
            url: url,
            method: type
        }).then(response => {
            result = response
        }).catch(error => {
            result = { errno: 1008, errmsg: error }
        })
    } else if (type == "post" || type == "POST" || type == "put" || type == "PUT" || type == "patch" || type == "PATCH") {
        await axios({
            url: url,
            method: type,
            data: subData
        }).then(response => {
            result = response
        }).catch(error => {
            result = { errno: 1008, errmsg: error }
        })
    }
    if (result.errno == '100143' || result.errno == '100142' || result.errno == '100144') {
        store.delete('token')
        store.delete('userInfo')
        router.replace({
            name:'Login'
        })
    }
    return result
}