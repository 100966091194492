<template>
    <a-tooltip overlayClassName="ixunke-tooltip" :placement="textRight ? 'left' : 'right'" trigger="oncontextmenu"
        v-model="tipShow" :getPopupContainer="
            (triggerNode) => {
                return triggerNode.parentNode || document.body
            }
        ">
        <template slot="title">
            <div class="ixunke-tooltip-live-action" @click="messageEvent('revoke')">撤回</div>
        </template>
        <div class="ixunke-message-vote" :style="{ textAlign: textRight ? 'right' : '' }">
            <div class="ixunke-message-vote-title">
                {{ voteObj.title }}
            </div>
            <div class="ixunke-message-vote-dc">
                <a-radio-group>
                   
                    <a-radio :style="radioStyle"  v-for="(item, index) of voteObj.component[0].list" :key="index" :value="item">
                        {{item}}
                    </a-radio>
                </a-radio-group>
                
                <a-divider style="margin: 8px 0;" />
                <div class="ixunke-message-vote-subtitle">
                    <ixunkeIcon type="ixunke-qbank-toupiao" />&nbsp;投票
                </div>
            </div>
        </div>
    </a-tooltip>
</template>
  
<script>
export default {
    name: 'messageVote',
    props: ['payload', 'type', 'textRight'],
    data() {
        return {
            tipShow: false,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },
        }
    },
    computed: {
        voteObj() {
            if (Object.keys(this.payload).length) {
                if (this.type == "message") {
                    try {
                        this.payload.data.component = JSON.parse(this.payload.data.component)
                    } catch (e) {
                        //TODO handle the exception
                    }
                    return this.payload.data
                } else if (this.type == "modal") {
                    try {
                        this.payload.component = JSON.parse(this.payload.component)
                    } catch (e) {
                        //TODO handle the exception
                    }

                    let component = []
                    let total = 0
                    if (this.payload.options_state) {
                        if (this.payload.options_state.length) {
                            this.payload.component[0].list.forEach((item, index) => {
                                let numobj = this.payload.options_state.find(item1 => {
                                    return item1.value == index
                                })
                                component.push({
                                    content: item,
                                    num: numobj ? numobj.num : 0
                                })

                            })
                            this.payload.component[0].list = component
                            this.payload.options_state.forEach((item, index) => {
                                total = total + item.num
                            })
                            this.payload.component[0].total = total
                        } else {
                            this.payload.component[0].list.forEach((item, index) => {
                                component.push({
                                    content: item,
                                    num: 0
                                })
                            })
                            this.payload.component[0].list = component
                            this.payload.component[0].total = 0
                        }
                    }

                    return this.payload
                } else if (this.type == "list") {
                    return this.payload
                }
            } else {
                return {}
            }
        }
    },
    filters: {
        endTimeFilter(time) {
            if (time == 1) {
                return "永久有效"
            } else {
                return dateFormat(time)
            }
        }
    },
    mounted() { },
    methods: {
        messageEvent(action) {
            this.$emit('messageAction', action)
            this.tipShow = false
        },
    },
}
</script>
<style lang="css" scoped>
.ixunke-message-vote {
    position: relative;
    width: 220px;
    height: auto;
    padding: 0;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 8px;
    overflow: hidden;
}

.ixunke-message-vote-title {
    width: auto;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: #fff;
    background-color: #1890ff;
    padding: 0 8px;
}

.ixunke-message-vote-dc {
    font-size: 12px;
    color: #9e9e9e;
    padding: 0 8px 8px;
    line-height: 20px;
}

.ixunke-message-vote-subtitle {
    font-size: 12px;
    color: #9e9e9e;
}
</style>
  
  