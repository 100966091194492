<template>
  <div class="ixunke-page ixunke-login-page" style="flex-wrap: wrap;">
    <NAV @closeLive="closeLive" style="height: 26px;"></NAV>

    <a-spin
      :spinning="loginLoading"
      :tip="loginLoadText"
      size="large"
      class="ixunke-loading"
    ></a-spin>
    <div class="ixunke-login-box">
      <div class="ixunke-login-box-title" @click="test()">迅课直播平台登录</div>
      <template v-if="showHostForm">
        <a-form style="margin-top: 24px" :form="hostForm">
          <a-form-item class="ixunke-login-form-item">
            <a-input
              size="large"
              style="width: 100%"
              v-decorator="[
                'host',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入直播域名',
                      whitespace: true,
                    },
                  ],
                },
              ]"
              placeholder="请输入直播域名"
            >
              <span slot="prefix">
                <ixunkeIcon type="ixunke-live-host" />
              </span>
            </a-input>
          </a-form-item>
          <a-form-item class="ixunke-login-form-item">
            <a-button
              type="primary"
              class="ixunke-login-btn"
              @click="nextStepEvent"
              @keypress.enter.stop="nextStepEvent"
              >下一步</a-button
            >
          </a-form-item>
        </a-form>
      </template>
      <template v-else>
        <a-form v-if="loginChannel==0" style="margin-top: 24px" :form="loginForm">
          <a-form-item class="ixunke-login-form-item">
            <ixunkeIcon type="ixunke-live-back" @click="previousStepEvent" />
            &nbsp;&nbsp;
            {{ title }}
          </a-form-item>
          <a-form-item class="ixunke-login-form-item" v-if="isLoginError">
            <a-alert
              type="error"
              showIcon
              :message="loginError"
              style="width: 100%"            
            />
          </a-form-item>
          <a-form-item
            class="ixunke-login-form-item"
            style="margin: 0; height: 65px"
          >
            <a-input
              size="large"
              style="width: 100%;float: left;"
              v-decorator="[
                'user',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入用户名',
                      whitespace: true,
                    },
                  ],
                },
              ]"
              placeholder="请输入用户名"
            >
              <span slot="prefix">
                <ixunkeIcon type="ixunke-live-user" />
              </span>
            </a-input>
          </a-form-item>
          <a-form-item
            class="ixunke-login-form-item"
            style="margin: 0; height: 65px;padding-right: 0;"
          >
            <a-input-password
              size="large"
              style="width: 105%; padding-right: 0;"
            
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: '请输入密码', whitespace: true },
                  ],
                },
              ]"
              placeholder="请输入密码"
            >
              <span slot="prefix">
                <ixunkeIcon type="ixunke-live-password" />
              </span>
            </a-input-password>
          </a-form-item>
          <a-form-item class="ixunke-login-form-item">
            <a-button
              type="primary"
              class="ixunke-login-btn"
              @click="loginEvent"
              >登录</a-button
            >
          </a-form-item>
          
        </a-form>
        <a-form v-if="loginChannel==1" style="margin-top: 24px">
          <a-form-item class="ixunke-login-form-item">
            <ixunkeIcon type="ixunke-live-back" @click="previousStepEvent" />
            &nbsp;&nbsp;
            {{ title }}
          </a-form-item>
          <a-form-item class="ixunke-login-form-item" v-if="isLoginError">
            <a-alert
              type="error"
              showIcon
              :message="loginError"
              style="width: 100%"            
            />
          </a-form-item>
          <a-form-item
            class="ixunke-login-form-item"
            style="margin: 0; height: 65px"
          >
            <a-input
              size="large"
              style="width: 100%;float: left;"
              v-model="loginForm1.phone"
              :maxLength="11"
              placeholder="请输入手机号"
            >
              <span slot="prefix">
                <ixunkeIcon type="ixunke-live-user" />
              </span>
            </a-input>
          </a-form-item>
          <a-form-item
            class="ixunke-login-form-item"
            style="margin: 0; height: 65px"
          >
            <a-input
              size="large"
              class="ixunke-login-captcha-input"
              placeholder="图片验证码"
              v-model="loginForm1.captcha"
            >
              <span slot="prefix">
                <ixunkeIcon type="ixunke-live-captcha" />
              </span>
            </a-input>
            <img
              class="ixunke-login-captcha-img"
              :src="captchaUrl"
              alt
              @click="changeCaptchaEvent"
            />
          </a-form-item>
          <a-form-item
            class="ixunke-login-form-item  w60"
            style="margin: 0; height: 65px;padding-right: 0;"
          >
            <a-input
              size="large"
              style="width: 105%; padding-right: 0;"
              placeholder="手机验证码"
              v-model="loginForm1.verifyCode"
            >
              <span slot="prefix">
                <ixunkeIcon type="ixunke-live-password" />
              </span>
            </a-input>
            <a-button
              class="btnCode"
              :disabled="getCodeBtnDisable"
              @click="getCodeCheck()"
              >
              {{ codeBtnWord }}
            </a-button>
          </a-form-item>
          
          <a-form-item class="ixunke-login-form-item">
            <a-button
              type="primary"
              class="ixunke-login-btn"
              @click="loginEvent"
              >登录</a-button
            >
          </a-form-item>
        </a-form>
        <el-divider content-position="left" class="ixunke-login-divider">其他登录方式</el-divider>
        <div class="ixunke-login-other-box">
            <div class="ixunke-login-other-login" v-if="loginChannel!==0"
              @click="changeLoginChannel(0)">
              <img class="ixunke-login-other-img" src="../../assets/img/zhanghao.svg" alt="">
              <span class="ixunke-login-other-text">账号</span>
            </div>
            <div class="ixunke-login-other-login" v-if="loginChannel!==1"
              @click="changeLoginChannel(1)">
              <img class="ixunke-login-other-img" src="../../assets/img/phone.svg" alt="">
              <span class="ixunke-login-other-text">手机号</span>
            </div>
            <div class="ixunke-login-other-login" v-if="loginChannel!==2"
              @click="changeLoginChannel(2)">
              <img class="ixunke-login-other-img" src="../../assets/img/huiyishi.svg" alt="">
              <span class="ixunke-login-other-text">会议号</span>
            </div>
          </div>
      </template>
    </div>
    <img
      src="./../../assets/img/Illustrine.png"
      class="ixunke-login-Illustrine"
      alt="迅课学堂"
    />


   
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import NAV from '../../assets/common/nav.vue';

const {ipcRenderer } = window.require("electron");
const Store = window.require("electron-store");
const store = new Store();
import axios from 'axios'

//   const { ipcRenderer } = window.require("electron");
export default {
  components: { NAV },

  name: "login",
  data() {
    return {
      loginChannel:0, //0 账号密码登录 1 手机号登录 2 会议号登录
      //加载动画
      loginLoading: true,
      loginLoadText: "请稍等...",
      //页面内容
      showHostForm: true,
      hostForm: this.$form.createForm(this),
      loginForm: this.$form.createForm(this),
      loginForm1:{
        phone:"",
        captcha:"",
        code:""
      },
      captchaUrl: "",
      host: "",
      isLoginError: false,
      loginError: "",
      QuitVisible:false,
      title:"",

      getCodeBtnDisable:false,
      waitTime:61,
      codeBtnWord:"获取短信验证码"
    };
  },
  watch: {
    host(value) {
      if (value) {
        this.showHostForm = false;
        this.changeCaptchaEvent();
      } else {
        this.showHostForm = true;
      }
    },
  },
  created() {
    document.title="​迅课直播助手"
    console.log(12312312123);
    store.set("GUIRouter", "login");
    let that = this;
    var token = store.get("token");
    console.log(store.get('host'));
    var host = store.get('host')

    if(host){
      this.host = host
      this.FindName(this.host)
    }
    if (token) {
      this.$router.push({ name: "course" });
    }
    // document.onkeydown = (e) => {
    //   let key = window.event.keyCode;
    //   if (key == 13) {
    //     if (that.showHostForm) {
    //       that.nextStepEvent();
    //     } else {
    //       that.loginEvent();
    //     }
    //   }
    // };
  },
  mounted() {
    console.log(this.loginForm)
    document.onkeydown = showkey;
    const _this = this;
    function showkey(event) {
      var key = event.keyCode;
        console.log(key);
        if(key==13){
          _this.loginEvent()
        }
    }

    if (this.host) {
      this.showHostForm = false;
      this.changeCaptchaEvent();
    } else {
      this.showHostForm = true;
    }
    setTimeout(() => {
      this.loginLoading = false;
    }, 300);
  },
  methods: {
    ...mapActions(["setHost", "login"]),
    changeLoginChannel(channel){
      if(this.loginChannel==channel)return
      if(channel==2){
        alert('会议号登录暂未开放')
        return
      }
      this.loginChannel = channel
    },
    getCode(){
      this.isLoginError = false;
      this.loginError = "";
      this.waitTime--;
      this.getCodeBtnDisable = true;
      this.codeBtnWord = `${this.waitTime}s 后获取`;
      
      let timer = setInterval(() => {
        if (this.waitTime > 1) {
          this.waitTime--;
          this.codeBtnWord = `${this.waitTime}s 后获取`;
        } else {
          clearInterval(timer);
          this.codeBtnWord = "获取验证码";
          this.getCodeBtnDisable = false;
          this.waitTime = 61;
        }
      }, 1000);
    },
    getCodeCheck() {
      console.log(this.loginForm1);
      
      if(!this.loginForm1.phone){
        this.isLoginError = true;
        this.loginError = "请输入手机号";
        return
      }
      const phoneReg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(!phoneReg.test( this.loginForm1.phone)){
        this.isLoginError = true;
        this.loginError = "请输入正确的手机号";
        return
      }

      if(!this.loginForm1.captcha){
        this.isLoginError = true;
        this.loginError = "请输入图片验证码";
        return
      }
      axios.get(`${this.host}`+'/api/member?dayuMessage=true&phone='+this.loginForm1.phone+'&captcha='+
      this.loginForm1.captcha,{
        withCredentials: true
      }).then(data=>{
       console.log(data);
       const errno = data?.data?.errno
       if(errno!==0){
        this.isLoginError = true;
        this.loginError = data?.data?.errmsg;
        return
       }else{
        this.sessionKey = data?.data?.data?.sessionId
        this.getCode()
       }
      }).catch(err=>{
        console.log(err);
      })
      
    },
    closeLive(){
      ipcRenderer.send("destroyAllWin");

    },
    FindName(host){
    
      axios.get(`${host}/`+'/api/config/system').then(data=>{
       this.title  = data.data?.data?.config?.site_title
       console.log(this.title);
      }).catch(err=>{
        console.log(err);
      })
    
    },
    //刷新图片验证码
    changeCaptchaEvent() {
      this.captchaUrl =
        this.host + "/api/captcha/index" + "?" + new Date().getTime();
    },
    transformToHttps(url) {
      if(!url)return
      const regex = /^http(s)?:\/\//i;
      if (regex.test(url)) {
        url = url.replace(regex, "https://");
      } else {
        url = `https://${url}`;
      }
      return url;
    },
    //下一步
    nextStepEvent(e) {
      if (e) {
        e.preventDefault();
      }
      store.set("host", this.host);
      
      this.hostForm.validateFields((err, values) => {
        if (err) {
          return false;
        }
        this.setHost(values.host.trim()).then((response) => {
          
          console.log(this.transformToHttps(response));
          this.host = this.transformToHttps(response);
          console.log(this.host);
          axios.get(`${this.host}/api/config/aboutapp`).then(data=>{
            console.log('域名正确');
            this.FindName( this.host )
          store.set('host',this.host)
          this.showHostForm = false;
          }).catch(err=>{
            console.log('请求失败，请确认域名是否正确');
            this.$message.error({
              message: "请求失败，请确认域名是否正确",
            });
            return false
          })
          
        });
      });
    },
    //上一步
    previousStepEvent() {
      this.showHostForm = true;
      setTimeout(() => {
        this.hostForm.setFieldsValue({
          host: this.host,
        });
      }, 300);
    },
    async loginEvent1(){
      axios.post(`${this.host}`+'/api/login/login',{
        sms:true,
        verifyCode:this.loginForm1.verifyCode,
        sessionKey:this.sessionKey,
        app:true,
        username:this.loginForm1.phone
      }).then(async (data)=>{
        console.log(data);
        const errno = data?.data?.errno;
        const resData = data?.data?.data
        if (errno === 0) {
          // console.log(res);
          store.set("userInfo", resData);
          store.set("host", this.host);
          await store.set("token", resData?.token);
          console.log(store.get("token"));
          this.$router.push({ name: "course" });
        } else {
          this.isLoginError = true;
          this.loginError = data?.data?.errmsg;
        }
      })
    },
    //登录
    async loginEvent(e) {
      if (e) {
        e.preventDefault();
      }
      this.isLoginError = false;
      this.loginError = "";


      if(this.loginChannel==1){
        this.loginEvent1();
        return
      }
      // this.$router.push({
      //     name:"course"
      // })
      // return
      this.loginForm.validateFields((err, values) => {
        if (err) {
          return false;
        }
        let allData = {
          subData: {
            username: values.user.trim(),
            password: values.password.trim(),
            // captcha: values.captcha.trim(),
          },
          axiosUrl: this.host + "/home/user/login",
        };
        this.loginLoading = true;
        this.loginLoadText = "登录中...";
        this.login(allData)
          .then(async (res) => {
            if (res.errno === 0) {
              // console.log(res);
              store.set("userInfo", res.data);
              store.set("host", this.host);
              await store.set("token", res.data.token);
              console.log(store.get("token"));
              this.$router.push({ name: "course" });
            } else {
              this.isLoginError = true;
              this.loginError = res.errmsg;
            }
            setTimeout(() => {
              this.loginLoading = false;
              this.loginLoadText = "请稍等...";
            }, 300);
          })
          .catch((err) => {
            this.isLoginError = true;
            this.loginError = err;
            setTimeout(() => {
              this.loginLoading = false;
              this.loginLoadText = "请稍等...";
            }, 300);
          });
      });
    },
    test() {
      ipcRenderer.send("test", "");
    },
  },
  beforeDestroy(){
    document.onkeydown = null;
  }
};
</script>
<style scoped>
.btnCode{
  position: absolute;
  right: -140px;
  top: -6px;
}
.w60{
  width: 120px !important;
}
.divider__text {
  color: #7e7e7e;
}
.ixunke-login-other-text{
  margin-top: 6px;
}
.ixunke-login-other-box{
  width: 130px;
  display: flex;
  justify-content: space-between;
}
.ixunke-login-other-img{
  width: 30px;
}
.ixunke-login-other-login{
  display: flex;
  width: 50px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child){
  padding-right: 0px;
}</style>

  