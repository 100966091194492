<template>
  <div>
    <a-tabs v-model="activeKey">
      <a-tab-pane key="1" tab="带货商品">
        <div class="goods-list">
          <div
            class="goods-item bottom-1px"
            v-for="(item, index) in goods"
            :key="item.id"
            
          >
          
            <div class="image-wrap">
               
              <!-- <image
                class="thumb"
                :src="
                  formatImageUrl(
                    item.goods.smallPicture ||
                      item.goods.originPicture ||
                      item.goods.mainPicture
                  )
                "
                mode="aspectFill"
              ></image> -->
              <img :src="item.goods.mainPicture" class="thumb" alt="">
              <div class="goods-index">
                <span class="goods-index-text">{{ index + 1 }}</span>
              </div>
              <div class="badge" v-if="item.introduceState == 1">
                <div class="audio-progress">
                  <image
                    class="audio-progress-image"
                    src="https://cdn.ixunke.cn/v3/app/imgcdn/live/goods-live.gif"
                    mode=""
                  ></image>
                </div>
                
                <!-- <div class="audio-progress">
                  <template v-for="(item, index) in progressItems">
                    <span
                      class="audio-progress-item active"
                      :key="index"
                      :style="{
                        height: item.height,
                        'animation-delay': (index % 5) * 0.2 + 's',
                      }"
                    >
                    </span>
                  </template>
                </div> -->
                <span class="badge-text">讲解中</span>
              </div>
            </div>
            <div class="main">
              <div class="top line-1">
                <div class="type">
                  <span class="type-text">{{
                    formatGoodsType(item.goodsType) 
                  }}</span>
                </div>
                <div class="title">
                  <span class="title-text">{{ item.goods.title }}</span>
                </div>
              </div>
              <div class="desc line-2">
                <span class="desc-text">{{ item.goods.description }}</span>
              </div>
              <div class="bottom" @tap.stop>
                <div class="price">         
                  <div>{{ item.goods.price }}</div>        
                </div>
                  <div class="button" v-if="item.introduceState == 1" hover-class="hover">
                    <span class="button-text" @click.stop="declineGoods(item,index)">结束讲解</span>
                  </div>
                  <div class="button" v-else >
                    <span class="button-text" @click.stop="sendGoods(item,index)">开始讲解</span>
                  </div>
            
              </div>
            </div>
          </div>
          
        </div>
        <a-empty v-if="goods.length==0"  description="暂无商品"/>
      </a-tab-pane>

      <!-- <a-tab-pane key="2" tab="优惠券" force-render>

      </a-tab-pane> -->
    </a-tabs>
  </div>
</template>
<script>
import { ixunkeAxios } from "@/assets/js/axios.js";

import parse from "./parse.js";
export default {
  props: ["goods", "roomId"],
  data() {
    return {
      activeKey: "1",
    };
  },
  methods: {
    parse,

    ergodic(){
        this.goods.forEach(e => {
            e.introduceState=2
        });

        console.log(this.goods);
    },
    declineGoods(item){
        ixunkeAxios(
        {
          goodsId: Number(item.goodsId),
          goodsType: item.goodsType,
          roomId: this.roomId,
        },
        "post",
        "/api/v1/room/finish_goods"
      ).then((res) => {
        if (res.errno == 0) {
          this.ergodic()
        }
      });
    },
    sendGoods(item,index) {
      ixunkeAxios(
        {
          goodsId: Number(item.goodsId),
          goodsType: item.goodsType,
          roomId: this.roomId,
        },
        "post",
        "/api/v1/room/send_goods"
      ).then((res) => {
        if (res.errno == 0) {
          this.$message.success("已上架成功");
        }
      });
      this.goods[index].introduceState=1
    },
    formatGoodsType(type) {
      switch (type) {
        case "resource":
          return "班级";
          break;
        case "q_bank":
        case "qBank":
          return "题库";
          break;
        case "course":
          return "课程";
          break;
        case "filepacket":
          return "资料";
          break;
        case "goods":
          return "商品";
          break;
        default:
          return "";
          break;
      }
    },
   
  },
  mounted() {},
  filters: {
    

    toFixed(price, count = 2) {
      return price.toFixed(count);
    },
    dateFormat(time, format) {
      return dateFormat(time, format || "MM-dd hh:mm");
    },
  },
};
</script>
<style lang="scss" scoped>
.goods-list {
  padding: 0 15px 40px;
  display: flex;
  flex-wrap: wrap;
  //   flex-direction: column;

  .goods-item {
    position: relative;
    width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    overflow: hidden;
    border: 1px solid #eee;
    margin: 0 10px 10px 0;

    &:first-child {
      padding-top: 2px;
    }

    .image-wrap {
      width: 108px;
      height: 108px;
      flex-shrink: 0;
      position: relative;
      border-radius: 6px;
      overflow: hidden;

      .thumb {
        width: 108px;
        height: 108px;
        background-color: #eee;
        border-radius: 6px;
      }

      .goods-index {
        position: absolute;
        top: 0;
        left: 0;
        width: 31px;
        height: 17px;
        line-height: 17px;
        background: #999999;
        opacity: 0.8;
        border-radius: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .goods-index-text {
          font-size: 12px;
          color: #ffffff;
        }
      }

      .badge {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 20px;
        background: #ffe6e6;
        border-radius: 0px 6px 0px 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 5px;

        .iconfont {
          font-size: 12px;
          margin: 0 2px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #ff0000;
        }

        .badge-text {
          color: #ff0000;
          font-size: 11px;
        }
      }

      .badge-introduceState {
        width: 77px;
        background: #f7f7f7;
        display: flex;
        flex-direction: row;

        .iconfont {
          color: rgba(0, 0, 0, 0.3);
          font-size: 11px;
        }

        .badge-introduceState-text {
          color: rgba(0, 0, 0, 0.3);
          font-size: 11px;
        }
      }

      /* #ifdef APP-NVUE */
      .audio-progress {
        width: 15px;
        height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 2px;
      }
      .audio-progress-image {
        width: 15px;
        height: 20px;
      }
      /* #endif */

      /* #ifndef APP-NVUE */
      .audio-progress {
        width: 12px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 2px;
      }
      .audio-progress-item {
        position: relative;
        width: 2px;
        height: 4px;
        border-radius: 10px;
        opacity: 0.4;
      }
      .audio-progress-item:before {
        content: "";
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -2px;
        right: -2px;
      }
      .audio-progress-item.active {
        background-color: #ff0000;
        opacity: 1;
      }
      .audio-progress-item:hover {
        opacity: 1;
      }
      .audio-progress-item:nth-child(odd) {
        animation: audioPlay 1.2s infinite;
      }
      .audio-progress-item:nth-child(even) {
        animation: audioPlay2 2s infinite;
      }
      @keyframes audioPlay {
        0% {
          height: 4px;
        }
        50% {
          height: 14px;
        }
        100% {
          height: 4px;
        }
      }
      @keyframes audioPlay2 {
        0% {
          height: 12px;
        }
        50% {
          height: 4px;
        }
        100% {
          height: 12px;
        }
      }
      /* #endif */
    }

    .main {
      height: 108px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      margin-left: 10px;
      width: 0;

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;

        .type {
          margin-right: 5px;
          width: 34px;
          height: 18px;
          background: #ffe6e6;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .type-text {
            color: #ff0000;
            font-size: 12px;
          }
        }

        .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          height: 20px;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .title-text {
            flex: 1;
            font-size: 14px;
            color: #333;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            lines: 1;
          }
        }
      }

      .desc {
        margin-top: 5px;
        line-height: 17px;
        opacity: 1;

        .desc-text {
          font-size: 12px;
          color: #666666;
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .price {
          height: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #ff5000;
          font-size: 14px;
        }

        .button {
          width: 73px;
          height: 31px;
          background: #ff2a4b;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          .button-text {
            font-size: 13px;
            color: #fff;
          }

          &.hover {
            opacity: 0.8;
          }
        }

        .disabled {
          background: #f7f7f7;
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
</style>